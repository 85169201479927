export const

  getUserState = (state) => {
    return state.get('user');
  },

  getUserStateJS = (state) => {
    const userState = getUserState(state)
    return userState ? userState.toJS() : undefined;
  },

  getUser = (state) => {
    const userState = getUserState(state);
    return userState ? userState.get('user') : undefined;
  },

  getUserJS = (state) => {
    const user = getUser(state);
    return user ? user.toJS() : undefined;
  },

  getUserAuthStateJS = (state) => {
    const user = getUserJS(state);
    return user ? {
      token: user.token,
      authorized: user.token && user.profile
    } : undefined;
  },

  getUserLoginStateJS = (state) => {
    const userState = getUserState(state)
    return userState ? {  
      loggingIn: userState.get('loggingIn'),
      loginError: userState.get('loginError'),
    } : undefined;
  }

