/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    //"aws_cognito_identity_pool_id": "eu-west-1:9d33b6ad-b132-4ba4-aa93-29a9257d9189",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_pQmfNe13n",
    "aws_user_pools_web_client_id": "5l164aakhdilkmqh8addula0i9",
    "oauth": {}
};


export default awsmobile;
