import * as React from 'react';
import classnames from 'classnames';

import {
  bindActionCreators,
  //Types
  Dispatch,
  AnyAction
} from 'redux'

import {
  connect,
  Provider
} from 'react-redux';

import {
  Redirect,
  Switch,
  Route,
  withRouter
} from 'react-router'

import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

import SceneContainer from '../SceneContainer';
import LoginForm from '../LoginForm';
import PasswordResetForm from '../PasswordResetForm';

import {
  loginUser,
  getUserLoginStateJS,
  getUserJS
} from '../../redux';

const styles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

interface LoginSceneProps {
  classes: any,
  onLogin: Function,
  loggingIn: boolean,
  loginError: {
    message: string
  },
  user: {
    authorized: boolean
  },
  location: {
    state: {
      referrer: string
    }
  }
}

class LoginScene extends React.Component<LoginSceneProps> {


  render() {

    const { classes } = this.props;

    return this.props.user.authorized ?
      <Redirect to={(this.props.location.state && this.props.location.state.referrer) || '/'}/> :
      <SceneContainer secure={false}>
        <div className={classes.container}>
          <Switch>
            <Route
                exact={true}
                path='/login'
                render={(routeProps) => <LoginForm {...routeProps} onSubmit={this.props.onLogin} loggingIn={this.props.loggingIn} loginError={this.props.loginError} />} />
             <Route
                exact={true}
                path='/login/reset'
                render={(routeProps) => <PasswordResetForm {...routeProps}/>} />
          </Switch>
        </div>
      </SceneContainer>
  }
}

function mapStateToProps(state: object) {
  return {
    user: getUserJS(state),
    ...getUserLoginStateJS(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({
    onLogin: loginUser
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginScene)))


