import {
  PROJECTS_FETCH_REQUEST,
  PROJECTS_FETCH_SUCCESS,
  PROJECTS_FETCH_FAILED,

  PROJECT_CREATE_REQUEST,
  PROJECT_CREATE_SUCCESS,
  PROJECT_CREATE_FAILED,

  PROJECT_INTEGRATION_CREATE_REQUEST,
  PROJECT_INTEGRATION_CREATE_SUCCESS,
  PROJECT_INTEGRATION_CREATE_FAILED,

  PROJECT_INTEGRATION_UPDATE_REQUEST,
  PROJECT_INTEGRATION_UPDATE_SUCCESS,
  PROJECT_INTEGRATION_UPDATE_FAILED,

  PROJECT_INTEGRATION_DELETE_REQUEST,
  PROJECT_INTEGRATION_DELETE_SUCCESS,
  PROJECT_INTEGRATION_DELETE_FAILED,

  PROJECT_INTEGRATION_POLL_REQUEST,
  PROJECT_INTEGRATION_POLL_SUCCESS,
  PROJECT_INTEGRATION_POLL_FAILED,

  PROJECT_INTEGRATION_ACTION_REQUEST,
  PROJECT_INTEGRATION_ACTION_SUCCESS,
  PROJECT_INTEGRATION_ACTION_FAILED,

  PROJECT_INTEGRATION_STATUS_REQUEST

} from './const';

export const

  fetchProjects = (payload) => ({type: PROJECTS_FETCH_REQUEST, payload}),

  fetchProjectsSuccess = (payload) => ({type: PROJECTS_FETCH_SUCCESS, payload}),

  fetchProjectsFailed = (payload) => ({type: PROJECTS_FETCH_FAILED, payload}),

  createProject = (payload) => ({type: PROJECT_CREATE_REQUEST, payload}),

  createProjectSuccess = (payload) => ({type: PROJECT_CREATE_SUCCESS, payload}),

  createProjectFailed = (payload) => ({type: PROJECT_CREATE_FAILED, payload}),
  
  createProjectIntegration = (payload, meta) => ({type: PROJECT_INTEGRATION_CREATE_REQUEST, payload: {...payload, status: "starting"}, meta}),

  createProjectIntegrationSuccess = (payload) => ({type: PROJECT_INTEGRATION_CREATE_SUCCESS, payload}),

  createProjectIntegrationFailed = (payload) => ({type: PROJECT_INTEGRATION_CREATE_FAILED, payload}),

  deleteProjectIntegration = (payload, meta) => ({type: PROJECT_INTEGRATION_DELETE_REQUEST, payload, meta}),

  deleteProjectIntegrationSuccess = (payload) => ({type: PROJECT_INTEGRATION_DELETE_SUCCESS, payload}),

  deleteProjectIntegrationFailed = (payload) => ({type: PROJECT_INTEGRATION_DELETE_FAILED, payload}),

  updateProjectIntegration = (payload, meta) => ({type: PROJECT_INTEGRATION_UPDATE_REQUEST, payload, meta}),

  updateProjectIntegrationSuccess = (payload) => ({type: PROJECT_INTEGRATION_UPDATE_SUCCESS, payload}),

  updateProjectIntegrationFailed = (payload) => ({type: PROJECT_INTEGRATION_UPDATE_FAILED, payload}),

  applyProjectIntregrationAction = (payload) => ({type: PROJECT_INTEGRATION_ACTION_REQUEST, payload}),

  applyProjectIntregrationActionSuccess = (payload) => ({type: PROJECT_INTEGRATION_ACTION_SUCCESS, payload}),
  
  applyProjectIntregrationActionFailed = (payload) => ({type: PROJECT_INTEGRATION_ACTION_FAILED, payload}),

  /*The payload here will be the object defining the prequisires for completion of polling
  ie [{
    status: started // how do we check for multple possiblitirews ie started || error
  }]*/
  pollProjectIntegration = (payload) => ({type: PROJECT_INTEGRATION_POLL_REQUEST, payload}),
  

  //The payload here will be the object defining the prequisires for completion of polling
  pollProjectIntegrationSuccess = (payload) => ({type: PROJECT_INTEGRATION_POLL_SUCCESS, payload}),

  pollProjectIntegrationFailed = (payload) => ({type: PROJECT_INTEGRATION_POLL_FAILED, payload}),


  checkProjectIntegrationStatus = (payload) => ({type: PROJECT_INTEGRATION_STATUS_REQUEST, payload})


