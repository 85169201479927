///https://github.com/gregnb/mui-datatables
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

import ErrorIcon from '@material-ui/icons/Error';
import RunningIcon from '@material-ui/icons/CheckCircleOutline';
import ProcessingIcon from '@material-ui/icons/HourglassEmpty';
import StoppedIcon from '@material-ui/icons/HighlightOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LaunchIcon from '@material-ui/icons/Launch';

import { Theme, } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';

import MUIDataTable from "mui-datatables";

import { useHistory } from "react-router-dom";

import config from '../../config';

import {
  bindActionCreators,
  //Types
  Dispatch,
  AnyAction
} from "redux";

import { connect, Provider } from "react-redux";

import {
  applyProjectIntregrationAction,
  deleteProjectIntegration,
  checkProjectIntegrationStatus
} from '../../redux';

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

/*const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.color.red,
  },
}));*/

const useModalStyles = makeStyles((theme:Theme) => createStyles({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    //border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4),
    outline: 'none'
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'center',
    marginTop: theme.spacing(4)
  }
}));

const styles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  icon: {
    fontSize: 24,
    marginRight: spacing()
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  actionContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  progressContainer: {
    padding: spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  progressSmall: {
    size: 10,
    margin: spacing(2),
  }
})

interface ProjectErrorProps {
  error: any
}

function ProjectError(props) {

  const getActionLabel = (action) => {
    return action.message;
  }

  const renderAction = (action, index) => {
    if(action.type === 'support') {
      return <Typography key={index} style={{marginTop: 10}} variant="body1" gutterBottom>'Contact Support at support@bimlauncher.com'</Typography>
    }
    return <Button key={index} color="primary" variant="contained">{getActionLabel(action)}</Button>
  }

  const renderActions = (actions) => {

    return (
      <div className={modalClasses.actionsContainer}>
        {actions.map((action, index) => {
          return renderAction(action, index);
        })}
      </div>
    )
  }

  const modalClasses = useModalStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const { error } = props

  return (
    <div style={modalStyle} className={modalClasses.paper}>
      <Typography variant="h5" gutterBottom>Project Error</Typography>
      <Typography style={{marginTop: 10}} variant="body1" gutterBottom>{error.message}</Typography>
      {error.actions && renderActions(error.actions)}
    </div>
  )
}

const StyledProjectError = withStyles({})(ProjectError);

interface ProjectListState {
  showModal: boolean,
  errorData: object
}

interface ProjectListProps {
  /*projects: {
    data: [{
      name: string,
      integrations: [any],
      data: any
    }],
    fetchingProjects: boolean,
    fetchProjectsError: object
  },*/
  projects: any,
  fetchProjects: Function,
  onIntegrationAction: Function,
  onIntegrationDelete: Function,
  onIntegrationStatusCheck: Function,
  history: any,
  classes: {
    icon: string,
    statusContainer: string,
    actionContainer: string,
    modifyContainer: string,
    progressContainer: string,
    progressSmall: string
  }
}

interface LaunchRenderProps {
  value: any,
  meta: any,
  handleAction: () => any,
  classes
}

function LaunchRender(props: LaunchRenderProps) {

  const history = useHistory();

  const launchReport = (path) => {
    history.push(path);
  }

  const launchIngress = (path) => {
    window.open(path, '_blank');
  }

  const { classes } = props;

  return (
    <div className={classes.actionContainer}>
      {(() => {

        const { integrationId, hasReport, reportPath, hasIngress, ingressURL, ingressLabel } = props.value;

        const reportActive = (hasReport && reportPath);
        const ingressActive =  (hasIngress && ingressURL);
        const enabled = reportActive || ingressActive;

        function launchFunction() {
          if(reportActive) {
             launchReport(reportPath)
             return
          }
          if(hasIngress) {
            launchIngress(ingressURL)
            return
          }
        }

        return (
          <Fragment>
            <Button disabled={!enabled} onClick={() => launchFunction()} color="primary">{hasReport ? "Report" : (ingressLabel ? ingressLabel : <LaunchIcon />)}</Button>
          </Fragment>
        )
      })()}
    </div>
  )

}

class ProjectList extends React.Component<ProjectListProps, ProjectListState> {

  state: Readonly<ProjectListState> = {
    showModal: false,
    errorData: {}
  };

  constructor(props) {
    super(props);
    props.fetchProjects();
  }

  editIntegration = (id) => {
    this.props.history.push(`integration/${id}`)
  }

  deleteIntegration = (action) => {
     this.props.onIntegrationDelete(action);
  }

  applyActionToIntegration = (action) => {
    this.props.onIntegrationAction(action);
  }

  checkIntegrationStatus = (payload) => {
    this.props.onIntegrationStatusCheck(payload);
  }

  showIntegrationError = (action) => {
  }

  statusRenderer = (value, meta) => {
    
    value = value.status || (typeof value === 'string' ? value : 'unknown');

    const getIcon = () => {

      if(value === 'starting' || value === 'stopping' || value === 'provisioning') {
        return <ProcessingIcon className={this.props.classes.icon} style={{color: 'orange'}}/>
      }
      if(value === 'started') {
        return <RunningIcon className={this.props.classes.icon} style={{color: 'green'}}/>
      }
      if(value === 'stopped') {
        return <StoppedIcon className={this.props.classes.icon} style={{color: 'red'}}/>
      }
      if(value === 'error') {
        return <ErrorIcon className={this.props.classes.icon} color="error" />  
      }
      if(value === 'configured') {
        return <SettingsIcon className={this.props.classes.icon} style={{color: 'grey'}} />  
      }

      return <ErrorIcon className={this.props.classes.icon} color="error" />  

    }
    
    return (
      <div className={this.props.classes.statusContainer}>
        {getIcon()}
        <Typography variant="body1" gutterBottom>{value}</Typography>
      </div>
    )
  }

  statusActionRenderer = (value, meta, handleAction) => {
    
    const { classes } = this.props;

    return (
      <div className={classes.actionContainer}>
        {(() => {

          const { integrationId, projectId, ast, hasK8 } = value
          const working = (!integrationId || !projectId || !ast) || ast.working;
          const disabled = working;
          const payload = { integrationId, projectId}

          const callAction = (action) => {
            handleAction(action, {integrationId, projectId})
          }

          if(value.status === 'test') {
            return <Typography variant="body1" gutterBottom>X</Typography>
          }
          if(value.status === 'configured') {
            return <Button disabled={disabled} onClick={() => callAction('start')} color="primary">Start</Button>
          }
          if(value.status === 'starting') {
            return <Button disabled={disabled} onClick={() => callAction('stop')} color="primary">Cancel</Button>
          }
          if(value.status === 'started') {
            return <Button disabled={disabled} onClick={() => callAction('stop')} color="primary">Stop</Button>
          }
          if(value.status === 'stopped') {
            return <Button disabled={disabled} onClick={() => callAction('resume')} color="primary">Resume</Button>
          }
          if(value.status === 'error') {
            return <Button disabled={disabled} onClick={() => handleAction('showError', value.error)} color="primary">Show Error</Button>
          }

          
        })()}
      </div>
    )
  }

  actionRenderer = (value, meta, handleAction) => {

    const { classes } = this.props;

    return (
      <div className={classes.actionContainer}>
        {(() => {

          const { integrationId, projectId, ast, status } = value
          const working = (!integrationId || !projectId || !ast) || ast.working;
          const disabled = working;
          const payload = { integrationId, projectId}

          const canEdit = false;

          return (
            <Fragment>
              {canEdit ? <Button disabled={disabled} onClick={() => this.editIntegration(integrationId)} color="primary"><EditIcon /></Button> : "" }
              <Button disabled={disabled} onClick={() => this.deleteIntegration(payload)} color="primary"><DeleteIcon /></Button>
              {working ? 
                <CircularProgress style={{marginLeft: 20, marginTop: 5}} size={15}/> :
                <Button disabled={disabled} onClick={() => this.checkIntegrationStatus(payload)} color="primary"><RefreshIcon /></Button>
              }
            </Fragment>
          )
        })()}
      </div>
    )
  }

  launchRenderer = (value, meta, handleAction) => {

    return <LaunchRender {...{value, meta, handleAction}} classes={this.props.classes} />

  }

  textRenderer = (value, meta) => {
    return <Typography variant="body1" gutterBottom> {value}</Typography>
  }

  columns = (props:any) => [
    {
      name: "Project",
      options: {
        customBodyRender: this.textRenderer
      }
    },
    {
      name: "Integration",
      options: {
        customBodyRender: this.textRenderer
      }
    },
    {
      name: "Status",
      options: {
        customBodyRender: (value, meta) => {
          return this.statusRenderer(value, meta)
        }
      }
    },
    {
      name: "Launch Timestamp",
      options: {
        customBodyRender: (value, meta) => moment(value).format()
      }
    },
    {
      name: "Action",
      filter: false,
      options: {
        customBodyRender: (value, meta) => {
          return this.statusActionRenderer(value, meta, props.handleAction)
        }
      }
    },
    {
      name: "",
      label: "",
      filter: false,
      options: {
        customBodyRender: (value, meta) => {
          return this.actionRenderer(value, meta, props.handleAction)
        }
      }
    },
    {
      name: "",
      label: "",
      filter: false,
      options: {
        customBodyRender: (value, meta) => {
          return this.launchRenderer(value, meta, props.handleAction)
        }
      }
    },
    {
      name: "integrationId",
      label: null,
      filter: false,
      options: {
        customBodyRender: this.textRenderer,
        display: /DEV|STAGE/.test(config.ENV)
      }
    },
    {
      name: "projectId",
      label: null,
      filter: false,
      options: {
        display: false,
        customBodyRender: this.textRenderer
      }
    },
    {
      name: "hasK8",
      label: null,
      filter: false,
      options: {
        display: false,
        customBodyRender: this.textRenderer
      }
    }];

  options = {
    print: false,
    download: false,
    filter: false,
    search: false,
    sort: false,
    selectableRows: 'none',
    viewColumns: false,
    responsive: 'scroll'
    //filterType: 'checkbox',
  };



  renderModal(open, handleClose, error) {

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <StyledProjectError error={error}/>
      </Modal>
    )
  }

  render() {

    const handleErrorOpen = (error) => {
      this.setState({errorData: error, showModal: true});
    };

    const handleClose = () => {
      this.setState({errorData: {}, showModal: false});
    };

    const handleAction = (type, payload) => {
      if(type === 'showError') {
        return handleErrorOpen(payload);
      }
      this.applyActionToIntegration({
        action: type,
        ...payload
      })
    }

    const getIngressURL = (integrationId, ingress) => {

        if(ingress && ingress.url) {
          return ingress.url;
        }
        if(ingress && ingress.host) {
           let path = `https://bl-${integrationId.toLowerCase()}.${ingress.host}`;
           if(ingress.uiLink) {
             path += `/${ingress.uiLink}`
           }
           return path;
          // return ingress.path ? `${path}/${ingress.path.replace(/^\//, "")}` : path;
        }
        return undefined;
       
    }

    const
      { projects, classes } = this.props,
      //rawProjects = projects && projects.data ? [...projects.data.map(p => p.data)] : [],
      rawProjects = projects,
      mappedProjects = projects ?
        rawProjects.reduce((agg, p) => {
          if(p.integrations)
          for (const i of p.integrations) {

            const ast = i.ast ? i.ast() : {working: false, error: null}
            const base = {
              integrationId: i._id,
              projectId: p._id,
              ast,
              hasK8: i.hasK8 !== false,
              status: i.status,
              statusMessage: i.statusMessage,
              error: ast.error || i.error || (i.status === 'error' ? {
                message: i.statusMessage
              } : undefined),
              hasReport: (i.status && (i.status === 'started' || i.status === 'stopped')) ? true : false,
              reportPath: `/integration/${i._id}/reports`
              /*hasIngress: i.k8 && !!i.k8.ingress,
              ingressURL: i.k8 && i.k8.ingress && getIngressURL(i._id, i.k8.ingress),
              ingressLabel: i.k8 && i.k8.ingress && i.k8.ingress.uiLabel*/
            }

            agg.push([
              p.name,
              i.name,
              {
                status: i.status,
                statusMessage: i.statusMessage
              },
              i.createdAt,
              base,
              base,
              base,
              i._id,
              p._id
              
            ])
          }
          return agg
        }, []) : [];


    if(projects.ast && projects.ast().working) {
      return (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progressSmall} />
        </div>
      )
    }

    return (
      <Fragment>
        <MUIDataTable
          //title={"Dashboard"}
          data={mappedProjects}
          columns={this.columns({handleAction})}
          options={this.options}
        />
        {this.renderModal(this.state.showModal, handleClose, this.state.errorData)}
      </Fragment>
    )
  }
}

function mapStateToProps(state: object, ownProps) {
  return {
    working: ownProps.projects.ast && ownProps.projects.ast().working
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators(
    {
      onIntegrationAction: applyProjectIntregrationAction,
      onIntegrationDelete: deleteProjectIntegration,
      onIntegrationStatusCheck: checkProjectIntegrationStatus
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
  )(withStyles(styles)(ProjectList));