function resolveEnv () {
  const env = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BIM_ENV : process.env.NODE_ENV;
  console.log('csascascascasc env : ', env, process.env.REACT_APP_BIM_ENV);
  return env
}

// We use moudule exports here as this is used by node in webpack and scripts
// as well as the app modules
module.exports = {
  development: {
    APP_URL: 'http://bimlauncher-dev.com:3101',
    API_URL: 'http://localhost:8000',
    ENV: 'DEV'
  },
  staging: {
    APP_URL: 'https://app-staging.bimlauncher.com',
    API_URL: 'https://0efd5ggt27.execute-api.eu-west-1.amazonaws.com/alpha1/', // TODO: those are callbacks for integration providers
    ENV: 'STAGE'
  },
  production: {
    APP_URL: 'https://app.bimlauncher.com', // Will be app.bimlauncher.com or account.bimlauncher.com
    API_URL: 'https://pnzav752o7.execute-api.eu-west-1.amazonaws.com/alpha1',
    ENV: 'PROD'
  }
}[resolveEnv()]
