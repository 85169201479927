/**
 * Logs all actions and states after they are dispatched.
 */
const logger = store => next => action => {
  /*console.group(action.type)*/

  //console.log('logger action : ', action)
  let result = next(action)


  //console.log('logger store.network " ', store.getState().toJS())

  /*if (process && process.env && process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
    //console.log('api state', store.getState().toJS())
    //console.log('api state', JSON.stringify(store.getState().get('api')))
    //console.log('job progress state', JSON.stringify(store.getState().get('job').get('job')))
  }
  console.groupEnd(action.type)*/
  return result
}

export default logger
