export const

  getIntegrationsState = (state) => {
    return state.get('integrations');
  },

  getIntegrationsStateJS = (state) => {
    const integrationsState = getIntegrationsState(state)
    return integrationsState ? integrationsState.toJS() : undefined;
  },

  getIntegrations = (state) => {
    const integrationsState = getIntegrationsState(state);
    return integrationsState ? integrationsState.get('integrations') : undefined;
  },

  getIntegrationsJS = (state) => {
    const
      integrations = getIntegrations(state),
      jsintegrations = integrations ? integrations.toJS() : [];
    if(jsintegrations) {
      jsintegrations.ast = integrations.ast
    }
    return jsintegrations
  },

  getIntegrationAuths= (state) => {
    const integrationsState = getIntegrationsState(state);
    return integrationsState ? integrationsState.get('integrationAuths') : undefined;
  },

  getIntegrationAuthsJS = (state) => {
    const
      integrationAuths = getIntegrationAuths(state),
      jsintegrationAuths = integrationAuths ? integrationAuths.toJS() : [];
    if(jsintegrationAuths) {
      jsintegrationAuths.ast = integrationAuths.ast
    }
    return jsintegrationAuths
  },

  getIntegrationAuthsForIntegrationIdJS = (state, integrationTid) => {
    //console.log('getIntegrationAuthsForIntegrationIdJS integrationId : ', integrationTId);
    const
      jsintegrationAuths = getIntegrationAuthsJS(state);
    /*if(jsintegrationAuths) {
      const selectedAuths = jsintegrationAuths.filter(ia => ia.integrations.includes === integrationTId)
      selectedAuths.ast = jsintegrationAuths.ast
    }*/
    return jsintegrationAuths
  }


