export const

  INTEGRATIONS_FETCH_REQUEST = 'INTEGRATIONS/FETCH_REQUEST',
  INTEGRATIONS_FETCH_SUCCESS = 'INTEGRATIONS/FETCH_SUCCESS',  
  INTEGRATIONS_FETCH_FAILED = 'INTEGRATIONS/FETCH_FAILED',

  INTEGRATION_CREATE_REQUEST = 'INTEGRATIONS/CREATE_REQUEST',
  INTEGRATION_CREATE_SUCCESS = 'INTEGRATIONS/CREATE_SUCCESS',
  INTEGRATION_CREATE_FAILED = 'INTEGRATIONS/CREATE_FAILED',

  INTEGRATION_FETCH_REQUEST = 'INTEGRATIONS/FETCH_REQUEST',
  INTEGRATION_FETCH_SUCCESS = 'INTEGRATIONS/FETCH_SUCCESS',
  INTEGRATION_FETCH_FAILED = 'INTEGRATIONS/FETCH_FAILED',

  INTEGRATION_PROVIDER_SERVICE_REQUEST = 'INTEGRATIONS/PROVIDER_SERVICE_REQUEST',

  INTEGRATION_AUTH_FETCH_REQUEST = 'INTEGRATIONS/AUTH_FETCH_REQUEST',
  INTEGRATION_AUTH_FETCH_SUCCESS = 'INTEGRATIONS/AUTH_FETCH_SUCCESS',
  INTEGRATION_AUTH_FETCH_FAILED = 'INTEGRATIONS/AUTH_FETCH_FAILED';