
import {
  USER_COOKIE_RETRIEVE,
  USER_COOKIE_SUCCESS,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  USER_TOKEN_REFRESH_REQUEST,
  USER_TOKEN_REFRESH_SUCCESS,
  USER_TOKEN_REFRESH_FAIL,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILED,
  USER_AUTH_UPDATE
} from './const';

export const

  loginUser = (payload) => {
    return {type: USER_LOGIN_REQUEST, payload}
  },

  userLoginSuccess = (payload) => {
    return {type: USER_LOGIN_SUCCESS, payload}
  },

  userLoginFailed = (payload) => {
    return {type: USER_LOGIN_FAILED, payload}
  },

  refreshToken = (payload) => {
    return {type: USER_TOKEN_REFRESH_REQUEST, payload}
  },

  userRefreshTokenSuccess = (payload) => {
    return {type: USER_TOKEN_REFRESH_SUCCESS, payload}
  },

  userRefreshTokenFailed = (payload) => {
    return {type: USER_TOKEN_REFRESH_FAIL, payload}
  },

  userAuthUpdate = (payload) => {
    return {type: USER_AUTH_UPDATE, payload}
  },

  logoutUser = () => {
    return {type: USER_LOGOUT_REQUEST}
  },

  userLogoutSuccess = () => {
    return {type: USER_LOGOUT_SUCCESS}
  },

  userLogoutFailed = () => {
    return {type: USER_LOGOUT_FAILED}
  },

  retreiveUserCookie = (payload) => {
    return {type: USER_COOKIE_RETRIEVE, payload}
  },

  userCookieSuccess = (payload) => {
    return {type: USER_COOKIE_SUCCESS, payload}
  };