export const

  APPLICATION_PREPARE = 'APPLICATION/PREPARE',
  APPLICATION_START = 'APPLICATION/START',
  APPLICATION_FATAL_ERROR = 'APPLICATION/FATAL_ERROR',
  APPLICATION_PREPARING = 'APPLICATION/PREPARING',
  APPLICATION_PREPARING_ERROR = 'APPLICATION/PREPARING_ERROR',
  APPLICATION_PREPARED = 'APPLICATION/PREPARED',
  APPLICATION_STARTING = 'APPLICATION/STARTING',
  APPLICATION_STARTING_ERROR = 'APPLICATION/STARTING_ERROR',
  APPLICATION_STARTED = 'APPLICATION/STARTED'