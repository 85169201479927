import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';


const styles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  textField: {
    //padding: 10,
    display: 'flex',
    width: '100%',
    //marginLeft: spacing(1),
    //marginRight: spacing(2),
  }
});

const TextInput = ({
  input,
  label,
  type,
  inputProps,
  className,
  defaultValue,
  meta: { touched, error, warning },
  inputClassName,
  placeholder,
  helpText,
  disabled,
  classes
}) => (
  <div>
    <TextField
      {...input}
      label={label}
      inputProps={inputProps}
      defaultValue={defaultValue}
      className={classnames(classes.textField, className, classes.input)}
      helperText={helpText}
      type={type || 'text'}
      margin="normal"
      variant="outlined"
      placeholder={placeholder}
      disabled={disabled}
    />
    { touched && error && typeof error === 'string' &&
      /*<label className="error" htmlFor={input.name}>{error}</label>*/
      <Typography style={{color:'#f00', marginTop: 10}} variant="body1" gutterBottom>{error}</Typography>
    }
  </div>
);    

export default withStyles(styles)(TextInput);