import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';
import BimLogo from '../../images/bim_logo_blk_400.png';

import {
  request, //Axios instance
} from '../../remote';

import config from '../../config';

const styles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: spacing(3),
    marginRight: spacing(3),
    [breakpoints.up(400 + spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${spacing(2)}px ${spacing(3)}px ${spacing(3)}px`,
  },
  head: {
    textAlign: 'center',
    borderBottom: 1,
  },
  textField: {
    marginTop: 0,
    marginBottom: 0,
  },
  progress: {
    margin: spacing(2),
    marginTop: spacing(4)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    marginTop: spacing(3),
  },
  error: {
    marginBottom: spacing(3),
    color: '#f00'
  },
  instruction: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
    textAlign: 'center'
  },
  logo: {
    width: '75%',
    margin: 'auto',
    marginBottom: spacing(3),
    marginTop: spacing(3),
  },
  center: {
    textAlign: 'center'
  }
});

interface PasswordResetSceneProps {
  classes: {
    main: string,
    paper: string,
    head: string,
    form: string,
    submit: string,
    progress: string,
    error: string,
    logo: string,
    instruction: string,
    center: string
  }
  history: any
}

interface PasswordResetState {
  auth: {
    email: string,
  },
  submitting: boolean,
  submitSuccess: boolean,
  submitError?: string
}

class PasswordResetForm extends React.PureComponent<PasswordResetSceneProps, PasswordResetState> {

  static defaultProps: Partial<PasswordResetSceneProps> = {
  }

  state = {
    auth: {
      email: ''
    },
    submitting: false,
    submitSuccess: false,
    submitError: undefined
  };

  submitPasswordReset = (e) => {

    e.preventDefault();

    this.setState({
      submitting: true,
      submitSuccess: false,
      submitError: undefined
    })

    request.post(`${config.API_URL}/password/reset`, this.state.auth)
      .then(response => {
        this.setState({
          submitting: false,
          submitSuccess: true,
          submitError: undefined
        })
      })
      .catch(error => {
        this.setState({
          submitting: false,
          submitSuccess: false,
          submitError: (error.response &&  error.response.data && error.response.data.message) || error.statusText
        })
      })
  }

  handleChange = (event) => {
    const { auth } = this.state;
    auth[event.target.name] = event.target.value;
    this.setState({ auth });
  }

  renderSuccess() {

    const { classes, history } = this.props;

    return (
      <div className={classes.center}>
        <Typography variant="body1" gutterBottom>The reset password email has been sent, please follow the instructions in the email.</Typography>
        <Button color="primary" className={classes.submit} onClick={() => history.push('/login')} ><Typography component="p" variant="body1">Login</Typography></Button>
      </div>
     )
    
  }
  
  renderError() {
    const { classes } = this.props;
    const { submitError } = this.state;
    const error: any = submitError !== undefined ? submitError : null
    if(submitError !== undefined) {
      return (
        <div>
          <Typography className={classes.error} variant="body1" gutterBottom>{error}</Typography>
        </div>
      )
    }
    return undefined;
  }

  renderForm() {

    const { classes, history } = this.props;
    const { submitting } = this.state;

    return (
      <Fragment>
        <div className={classes.instruction}>
          <Typography variant="body1" gutterBottom>Please enter the email you use for login. We will send a reset password instruction to that email address for you to follow.</Typography>
        </div>
        <form className={classes.form} onSubmit={this.submitPasswordReset}>
          <FormControl margin="none" required fullWidth>
            <TextField
              id="email"
              name="email"
              variant="outlined"
              label="Email Address"
              margin="dense"
              autoComplete="email"
              onChange={this.handleChange}
              required autoFocus />
          </FormControl>
          <div className={classes.center}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={submitting}
            >
              Send Email
            </Button>
            <Button color="primary" className={classes.submit} onClick={() => history.push('/login')} ><Typography component="p" variant="body1">Cancel</Typography></Button>
           </div>
        </form>
      </Fragment>
    )
  }

  render() {

    const { classes } = this.props;
    const { submitting, submitSuccess, submitError } = this.state;

    //console.log('submitting, submitSuccess, submitError : ', submitting, submitSuccess, submitError);

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <div className={classes.head}>
            <img src={BimLogo} className={classes.logo} alt="BIMLauncher"/>
          </div>
          {submitting ?
            <Fragment>
              <Typography component="h1" variant="h5">Please wait...</Typography>
              <CircularProgress className={classes.progress} />
            </Fragment> :
            <Fragment>
              {submitSuccess && this.renderSuccess()}
              {!submitting && submitError && this.renderError()}
              {!submitting && !submitSuccess && this.renderForm()}
            </Fragment>
          }
        </Paper>
      </main>
    );
  }
}

export default withStyles(styles)(PasswordResetForm);