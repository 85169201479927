import {
  createStore as reduxCreateStore,
  applyMiddleware
} from 'redux'

import {
  combineReducers
} from 'redux-immutable'

import {
  combineEpics
} from 'redux-observable';

/*import {
  reducer as formReducer
} from 'redux-form'*/

import { reducer as formReducer } from 'redux-form/immutable' // <--- immutable import

import { createEpicMiddleware } from 'redux-observable';

//import { composeWithDevTools } from 'redux-devtools-extension';
//
// /https://github.com/zalmoxisus/remote-redux-devtools
/*import {
  composeWithDevTools
} from 'remote-redux-devtools';*/

import logger from './middleware/logger'

import {
  request //Axios instance
} from '../remote';

import {
  epic as applicationEpic, reducer as applicationReducer
} from './application';

import {
  epic as userEpic, reducer as userReducer
} from './user';

import {
  epic as projectsEpic, reducer as projectsReducer
} from './project';

import {
  epic as integrationsEpic, reducer as integrationsReducer
} from './integration';

/*import {
  reducer as astReducer
} from './ast';*/

//const composeEnhancers = composeWithDevTools({ realtime: true, port: 8000 });

function createRootEpic() {
  return combineEpics(applicationEpic, userEpic, projectsEpic, integrationsEpic);
}

function getModuleReducers() {
  return {application: applicationReducer, user: userReducer, projects: projectsReducer, integrations: integrationsReducer};
}

/*const wrappedRequest = connectRequest(
    bindActionCreators({
      refreshToken
    })
  )*/

function createStoreWithAllReducersAndMiddleware(reducers, middleware = []) {

  const

    //We need to wrap the network middleware to translate from immutable js
    /*originalNetworkMiddleware = createNetworkMiddleware({
      regexActionType: /FETCH.*REQUEST/,
      actionTypes: [] //Add action types
    }),
    networkMiddleware = (store, dispatch) => {
      return originalNetworkMiddleware({ getState: () => store.getState().toJS(), dispatch })
    },*/

    epicMiddleware = createEpicMiddleware({
      dependencies: {
        request, //Axios instance
        //request: wrappedRequest, //Axios instance
      }
    }),

    allMiddleware = [logger, epicMiddleware],
    //store = reduxCreateStore(combineReducers({ast: astReducer, ...getModuleReducers(), ...reducers}), applyMiddleware(...allMiddleware))
    store = reduxCreateStore(combineReducers({...getModuleReducers(), ...reducers}), applyMiddleware(...allMiddleware))


    //request.interceptors.response.use(response => response, requestResponseFailInterceptor);


  epicMiddleware.run(createRootEpic())

  return store
}

export function configureStore() {
  return createStoreWithAllReducersAndMiddleware({form: formReducer})
}

export {
  prepareApplication,
  startApplication,
  getApplicationStateJS
} from './application';

export {
  loginUser,
  logoutUser,
  getUserStateJS,
  getUserJS,
  getUserLoginStateJS
} from './user';

export {
  fetchProjects,
  getProjectsJS,
  createProject,
  createProjectIntegration,
  updateProjectIntegration,
  deleteProjectIntegration,
  applyProjectIntregrationAction,
  checkProjectIntegrationStatus,

} from './project';


export {
  fetchIntegrations,
  fetchIntegrationAuth,
  getIntegrationsJS,
  getIntegrationAuthsForIntegrationIdJS,
  createIntegration
} from './integration';

/*export {
  createWorkingSelector,
  createErrorSelector
} from './ast';*/

