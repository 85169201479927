import { combineEpics, ofType } from 'redux-observable';
import { from as from$, of as of$ } from 'rxjs';
import { map, switchMap, catchError} from 'rxjs/operators';

import {
  APPLICATION_PREPARE,
  APPLICATION_PREPARED,
  APPLICATION_PREPARING_ERROR,
  APPLICATION_START,
  APPLICATION_STARTED,
  APPLICATION_FATAL_ERROR
} from './const'

import {
  retreiveUserCookie
} from '../user'

export const

  applicationPreparation = (action$, state$, { fetch }) => {
    return action$.pipe(
      ofType(APPLICATION_PREPARE),
      switchMap(action => {

        //Insert here any application preparation
        //this can be a number of promised procedures
        //THe promise resolution of this swicth must resolve with
        //a list of other actions or empty array

        //return from$(Promise.resolve([]))

        return from$(Promise.resolve(true))
          .pipe(
            map(() => (retreiveUserCookie({delegate: { type: APPLICATION_PREPARED }})))
          )

        //You may wish to do a connection verificat
        /*return action$.ofType(NETWORK_CONNECTION_CHANGE).pipe(
          take(1),
          switchMap(({ connectivity }) => {
            //Here you can insert multiple application prepartion procedures after connection verified
            //This is more useful for mobil
            //This might be locale preperation or restoration from local storage for example
            return forkJoin(
            );
          }),
          flatMap(actions => [...actions, { type: APPLICATION_PREPARED }]),
        );*/
      }),
      //flatMap(actions => [...actions, { type: APPLICATION_PREPARED }]),
      catchError(error => {
        //console.log('applicationPreparation error : ', error);
        return of$({
          type: APPLICATION_PREPARING_ERROR,
          applicationPreparationError: error
        });
      })
    );
  },

  applicationStart = (action$, state$) => {
    return action$.pipe(
      ofType(APPLICATION_START),
      switchMap(action => {

        //If token found for user in cookies update user
        //If no token found redirect to login using navigator
        //adding current route as paremeter to be redeemed after login
        //return from$(activeAccountRequiresLogin(activeAccount))
        return from$(Promise.resolve(true))
          .pipe(
            map(() => ({ type: APPLICATION_STARTED }))
          )
      }));
  },

  applicationFatalError = (action$, state$) => {
    return action$.pipe(
      ofType(APPLICATION_FATAL_ERROR),
      map(action => ({ type: 'CLOSE_APP' })) /// Do close / restart  app actions
    );
  };

export default combineEpics(applicationPreparation, applicationStart, applicationFatalError);