import * as React from "react";
import { Theme } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ChevronUpIcon from '@material-ui/icons/ExpandLess';
import ChevronDownIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from "@material-ui/core/Button";
import config from '../../../config';
import {request} from '../../../remote';
import { Field, FieldArray, reduxForm, getFormValues, formValueSelector } from 'redux-form/immutable';
import { renderField, renderSchema } from "../../Form";

import {
	getFromData,
	loadOptionsForIntegrationSchema
} from '../../../utils';

const mapperStyles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
	actionContainer: {
		marginTop: spacing(2)
	}
})

const mapperFieldStyles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
	rowContainer: {
		marginBottom: spacing(),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%'
	},
	rowNumber: {
		marginRight: spacing()
	},
	rowSelect: {
		display: 'flex',
		flex: '1'
	},
	rowSelectInner: {
		width: '100%'
	},
	rowError: {
		display: 'block'
	},
	fieldOrderActions: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center'
	},
	fieldOrderAction: {
		paddingTop: spacing() / 4,
		paddingBottom: spacing() / 4,
	}
})

interface RowSelectFieldSelectProps {
	field: object;
	loading: boolean;
	options: any[];
	error: boolean;
	classes: any;
	orderNumber: number;
	index: number;
	items: any[];
	disabled?: boolean;
	onItemMove: (from:number, to:number) => void;
	onItemDelete: (index:number) => void;
}

class RowSelectFieldSelectBase extends React.Component<RowSelectFieldSelectProps, {}> {

	moveItem = (from, to) => {
		const { onItemMove } = this.props;
		//console.log('from, to : ', from, to);
		onItemMove(from, to);
	}

	deleteItem = (index) => {
		const { onItemDelete } = this.props;
		//console.log('from, to : ', from, to);
		onItemDelete(index);
	}
	
	render() {

		const { field, options, loading, error, classes, orderNumber, onItemMove, index, items, disabled } = this.props;

		//console.log('RowSelectFieldSelectBase render index : ', index);
		//console.log('RowSelectFieldSelectBase render items.length : ', items.length);

		return (
			<div className={classes.rowContainer}>
				<Typography className={classes.rowNumber} variant="body1" gutterBottom>{orderNumber}</Typography>
				<Field
					containerClassName={classes.rowSelect}
					className={classes.rowSelectInner}
					name={`${field}`}
					type="select"
					component={renderField}
					options={options}
					placeholder={
						loading
							? "Loading"
							: (error ? "Error" : "Select...")
					}
					create={false}
					isLoading={loading}
					//validate={(value) => this.validate(value)}
					//disabled={integrationsLoading || integrationOptions.length < 2}
					disabled={disabled || loading || error}
					format = {value => {

						//It seems there is a bug in redux-form in that for a field array when values are set in inital values
						//they are not converted to js.
						const actualValue = (value.toJS && typeof value.toJS === 'function') ? value.toJS() : value;

						//console.log('FieldMapper format value : ', actualValue);
						return (actualValue && actualValue !== '' && (actualValue.value && actualValue.label)) ? actualValue : null;
					}}
					//format={value => this.formatIntegration(value, rawIntegrations)}
					parse={value => {
						console.log('FieldMapper parse value : ', value);
						////console.log('pasre : ', value, value === '');
						//For some reason thais has to return undefined if empty string
						return (value === null || value === undefined || value === '') ? undefined : value;
					}}
				/>
				<div className={classes.fieldOrderActions}>
					{!disabled && items.length > 1 && !loading && <Button onClick={() => this.deleteItem(index)} className={classes.fieldOrderAction} ><DeleteIcon /></Button>}
				</div>
				<div className={classes.fieldOrderActions}>
					{!disabled && index !== 0 && !loading && <Button onClick={() => this.moveItem(index, index-1)} className={classes.fieldOrderAction} ><ChevronUpIcon /></Button>}
					{!disabled && index < items.length-1 && !loading && <Button onClick={() => this.moveItem(index, index+1)} className={classes.fieldOrderAction}><ChevronDownIcon /></Button>}
				</div>
			</div>
		)
	}
}

const RowSelectFieldSelect = withStyles(mapperFieldStyles)(RowSelectFieldSelectBase);

interface RowSelectProps {
	classes: any;
	name: string;
	integration: any;
	schema: any;
	formValues: any;
	isEditing: boolean;
	onDataError: Function
}

interface RowSelectPropsState {
	loading: boolean;
	fieldOptions: any[];
	sourceError?: string;
}

class RowSelect extends React.Component<RowSelectProps, RowSelectPropsState> {

	state: Readonly<RowSelectPropsState> = {
		loading: false,
		fieldOptions: [],
		sourceError: undefined
  };

	componentDidMount() {
		this.loadOptions();
	}

	loadOptions = () => {

		const { integration, schema, formValues, onDataError } = this.props;

		if(schema.source) {
			this.setState({
				loading: true,
				fieldOptions: [],
				sourceError: undefined
			})

			//console.log('loadOptions loadOptions');
			//const intId = formValues._id || formValues.tid;
			const intId = formValues.tid;
			return loadOptionsForIntegrationSchema(intId, integration, schema, formValues.configuration)
			.then(response => {
				//console.log('flatmapper response : ', response);
				this.setState({
					loading: false,
					fieldOptions: response.map(ro => ({value: ro.id, label: ro.name}))
				})
			})
			.catch(e => {
				////console.log('load options error : ', e);
				this.setState({
					loading: false,
					fieldOptions: [],
					sourceError: ((typeof e.message === 'string') && e.message ) || "Unknown Error"
				})

				onDataError && onDataError(e);
			})

			return
		}

		if(schema.options) {
			
			this.setState({
				fieldOptions: schema.options
			})

			return
		}
		
	}

	handleItemMove = fields => (from, to) => {
		//console.log('handleItemMove from, to : ', from, to);
		fields.move(from, to);
	}

	handleItemDelete = fields => (index) => {
		//console.log('handleItemMove from, to : ', from, to);
		fields.remove(index);
	}

	renderFieldSelections = ({ fields, meta: { error, submitFailed, dirty } }) => {

		//console.log('renderFieldSelections fields : ', fields);

		if(fields.length < 1) {
			fields.push({})
			//fields.push('')
		}

		const { formValues, schema, classes, name, isEditing } = this.props;
		const { sourceError, loading, fieldOptions } = this.state;
		const fieldFormData = getFromData(name, formValues);
		const fieldValues = fieldFormData ? fieldFormData.reduce((agg, fd) => {
			if(fd && fd !== '' && fd.value) {
				agg.push(fd.value)
			}
			return agg;
		}, []) : [];

		const disabled = (isEditing && !schema.enableEdit)

		const canAdd = !disabled && !loading && fieldFormData && !fieldFormData.some(f => Object.keys(f).length === 0) && (fieldOptions.length > fieldValues.length) ;

		//console.log('renderFieldSelections fieldValues : ', fieldValues);
		//console.log('renderFieldSelections fieldFormData : ', fieldFormData);

		return (

			<div>
				{fields.map((field, index) => {

					const thisValue = fieldValues && fieldValues[index];

					const filteredFieldOptions = fieldOptions.filter(fo => {
						return !fieldValues.includes(fo.value);
					}).filter(fo => fo.value !== thisValue)

					return <RowSelectFieldSelect orderNumber={index + 1} key={index} index={index} items={fieldFormData} field={field} options={filteredFieldOptions} loading={loading} error={!!sourceError} onItemMove={(from, to) => this.handleItemMove(fields)(from, to)} onItemDelete={(index) => this.handleItemDelete(fields)(index)} disabled={disabled} />

				})}
				{canAdd && <div className={classes.actionContainer}><Button onClick={() => fields.push({})} variant="contained" color="primary" >{schema.addLabel || 'Add'}</Button></div>}
				{ sourceError && typeof error === 'string' &&
        	<div className={classes.errorClassName} ><Typography style={{color:'#f00', marginTop: 10}} variant="body1" gutterBottom>{error}</Typography></div>
        }
				{ dirty && error && typeof error === 'string' &&
        	<div className={classes.errorClassName} ><Typography style={{color:'#f00', marginTop: 10}} variant="body1" gutterBottom>{error}</Typography></div>
        }
			</div>
		)
		
	}

	render(){

		const { name } = this.props;
		const { sourceError, loading } = this.state;

		return (
			<div>
				<FieldArray name={name} component={this.renderFieldSelections} loading={loading}/>
			</div>
		)
		

	}
}

export default withStyles(mapperStyles)(RowSelect);