export const

  getProjectsState = (state) => {
    return state.get('projects');
  },

  getProjectsStateJS = (state) => {
    const projectsState = getProjectsState(state)
    return projectsState ? projectsState.toJS() : undefined;
  },

  getProjects = (state) => {
    const projectsState = getProjectsState(state);
    return projectsState ? projectsState.get('projects') : undefined;
  },

  getProjectsJS = (state) => {
    const projects = getProjects(state);
    //console.log('getProjectsJS projects.ast : ', projects.ast);
    const jsprojects = projects ? projects.toJS() : [];
    if(jsprojects) {
      jsprojects.ast = projects.ast
    }
    return jsprojects
  }

