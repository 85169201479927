import * as React from "react";
import classnames from "classnames";
import camelcase from "lodash.camelcase";
import Typography from '@material-ui/core/Typography';

import { Field, FieldArray, reduxForm, getFormValues, formValueSelector, clearFields } from 'redux-form/immutable';
import { connect } from "react-redux";

import {
  bindActionCreators,
  //Types
  Dispatch,
  AnyAction
} from "redux";

import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

import OAuth from './OAuth';
import FieldMapper from './FieldMapper';
import RowSelect from './RowSelect'
import OptionsSelect from './OptionsSelect';
import BasicAuth from './BasicAuth';
import ConfigAuth from './ConfigAuth';

import { renderField, renderSchema } from "../Form";
import { Redirect } from 'react-router';

import config from '../../config';
import { request } from '../../remote';

import CircularProgress from '@material-ui/core/CircularProgress';

import {
  getIntegrationAuthsForIntegrationIdJS
} from "../../redux";

import {
  parseIntegrationForPersistence,
  findProviderService,
  getServiceIdentifierFromServicePath,
  findIntegrationProviderServiceFromPath,
  loadDataFromProviderServiceFromPath,
  loadOptionsForIntegrationSchema,
  findProviderAuthValues,
  validateSchemaStageRequires,
  getFromData,
  getAllConfigKeys,
  findDependentFields,
  validateConfigSchemaValues,
  compareSchemaRequires,
  getSchemaInitialValues
} from '../../utils';

const formName = 'projectIntegrationUpsert';

const styles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  button: {
    margin: spacing()
  },
  select: {
    marginBottom: spacing(2)
  },
  progress: {
    margin: spacing(2),
  },
  progressContainer: {
    padding: spacing(2),
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  progressSmall: {
    size: 10,
    margin: spacing(2),
  },
  container: {
    display: 'flex'
  },
  form: {
    flexGrow: 0,
    flexShrink: 1,
    [breakpoints.down('sm')]: {
      flexBasis: '100%'
    },
    [breakpoints.up('md')]: {
      flexBasis: '75%'
    },
    [breakpoints.up('lg')]: {
      flexBasis: '60%'
    }

  },
  integrationForm: {
    position: 'relative'
  },
  input: {
    width: '100%'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: spacing(2),
    marginBottom: spacing(2)
  },
  statusError: {
    color: '#f00'
  },
  statusOk: {
    color: '#0f0'
  },
  statusDoneIcon: {
    size: 100,
    color: '#0f0'
  },
  stageSchemaCard: {
    marginTop: spacing(2),
    overflow: 'visible'
  },
  schemaTypeBox: {
    marginBottom: spacing(2)
  },
  schemaTypeDivider: {
    marginBottom: spacing(2)
  },
  formSectionHeadDivider: {
    marginBottom: spacing(3)
  },
  formStageMessage: {
    marginBottom: spacing(2)
  }
});

const validateIntegration = (values, props) => {
  const errors: any = {};

  const valuesJS: any = values && values.toJS ? values.toJS() : undefined;

  if (!valuesJS) {
    return errors;
  }

  if (valuesJS.projectId === undefined) {
    errors.projectId = "You must select a project";
  }

  if (valuesJS.integrationId === undefined) {
    errors.integrationId = "You must select an integration";
  }

  const integration = props.integrations ?
    props.integrations.find(i => i._id === valuesJS.integrationId) : undefined;

  integration && validateConfigSchemaValues(integration, valuesJS, errors);

  //console.log('validateIntegration : allErrors ', errors)

  return errors;

};

const findIntegrationById = (integrationId, integrations) => integrations.find(i => i._id === integrationId);

interface DataSelectSchemaProps {
  integration: any;
  schema: any;
  formValues: any;
  isEditing ? : boolean;
  clearFields: Function;
  onDataError: Function;
}

interface DataSelectSchemaState {
  loading: boolean;
  options: [any ? ];
  sourceError ? : string;
}

class DataSelectSchema extends React.Component < DataSelectSchemaProps, DataSelectSchemaState > {

  componentDidMount() {
    this.loadOptions();
  }

  state: Readonly < DataSelectSchemaState > = {
    loading: false,
    options: [],
    sourceError: undefined
  };

  componentDidUpdate(prevProps, prevState, snapshot) {

    const { schema, formValues } = this.props;

    const result = compareSchemaRequires(schema, prevProps.formValues && prevProps.formValues.configuration, formValues && formValues.configuration);

    if (!result.match) {
      this.loadOptions()
    }
  }

  loadOptions() {
    const { integration, schema, formValues, onDataError } = this.props;

    if (integration && schema && schema.source !== undefined) {

      this.setState({
        loading: true,
        options: [],
        sourceError: undefined
      })

      const intId = formValues._id || formValues.tid;
      return loadOptionsForIntegrationSchema(intId, integration, schema, formValues.configuration)
        .then(response => {
          const responseOptions = response.map(ro => ({ value: ro.id, label: ro.name }))
          const options = schema.options ? [
            ...responseOptions,
            ...schema.options
          ] : responseOptions

          this.setState({
            loading: false,
            options
          })
          ////console.log('loadOptions response : ', response);
        })
        .catch(e => {
          this.setState({
            loading: false,
            options: [],
            sourceError: ((typeof e.message === 'string') && e.message) || "Unknown Error"
          })
          onDataError && onDataError(e);
        })


    } else {

      this.setState({
        loading: false,
        options: [],
        sourceError: "Invalid source target"
      })
    }
  }

  render() {

    const { schema, integration, isEditing, clearFields } = this.props;

    const disabled = (isEditing && !schema.enableEdit)

    const onFieldChange = () => {

      const dependentFields = findDependentFields(schema.configKey, integration);
      //console.log('DataSelect dependentFields : ', dependentFields);
      integration && clearFields(formName, false, false, ...dependentFields);
    }

    return (
      <div>
        <Field
          //name={`configuration.${schema.configKey}`}
          name={`configuration.${schema.configKey}`}
          type="select"
          onChange={onFieldChange}
          component={renderField}
          options={this.state.options}
          placeholder={
            this.state.loading
              ? "Loading"
              : (this.state.sourceError ? "Error" : "Select...")
          }
          create={false}
          isLoading={this.state.loading}
          //disabled={integrationsLoading || integrationOptions.length < 2}
          disabled={disabled || this.state.loading || this.state.sourceError}
          required={true}
          format = {value => {
            return value === '' || value === undefined ? '' : this.state.options.find(o => o.value === value);
          }}
          parse={value => {
            return value && value !== '' ? value.value : undefined
          }}
        />
        {/**this.state.sourceError && <Typography className={classes.statusError} variant="body1" gutterBottom>Sorry an unknown error occured</Typography>**/}
        {this.state.sourceError && <Typography style={{color:'#f00', marginTop: 10}} variant="body1" gutterBottom>{this.state.sourceError}</Typography>}
      </div>

    )
  }
}

interface TextSchemaProps {
  integration: any;
  schema: any;
  formValues: any;
  isEditing: boolean;
}

class TextSchema extends React.Component < TextSchemaProps > {
  render() {

    const { schema, isEditing } = this.props;

    return (
      <div>
        <Field
          //name={`configuration.${schema.configKey}`}
          name={`configuration.${schema.configKey}`}
          type={schema.type}
          inputProps={schema.inputProps}
          component={renderField}
          placeholder=""
          required={schema.required || true}
          disabled={isEditing && schema.enableEdit}
          parse={(value) => {
            if(schema.options && schema.options.allowed) {
              const reg = `[^${schema.options.allowed}]`
              return value ? value.replace(new RegExp(reg, 'g'), '') : value;
            }
            return value;
          }}
        />
      </div>
    )
  }
}

interface ScheduleSchemaProps {
  integration: any;
  schema: any;
  classes: any
  formValues: any;
  isEditing: boolean;
}

class ScheduleSchema extends React.Component < ScheduleSchemaProps > {

  render() {

    const { schema, isEditing, formValues, classes } = this.props;

    const periodArray = getFromData(schema.configKey, formValues.configuration);
    const hours = periodArray ? parseInt(periodArray[0]) : undefined;

    return (
      <div className={classes.scheduleFormContainer}>
        <Field
            name={`configuration.${schema.configKey}`}
            type='schedule'
            component={renderField}
            required={schema.required || true}
            disabled={isEditing && schema.enableEdit}
            /*format={(value, name) => {
              return value
              //return value ? (value[0] === NaN ? '' : value[0]) : 0;
            }}*/
            /*normalize={(value, previousValue) => {
              const previousMins = previousValue ? previousValue[1] : 15;
              const hoursValue = parseInt(value !== '0' ? value.replace(/^0+/, '') : '0');
              const storeValue = [hoursValue, hoursValue < 1 && previousMins < 15 ? 15 : previousMins];
              return storeValue;
            }}*/
          />

      </div>
    )
  }
}

interface CheckboxSchemaProps {
  integration: any;
  schema: any;
  formValues: any;
  isEditing: boolean;
}

class CheckboxSchema extends React.Component<CheckboxSchemaProps> {
  render() {

    const { schema, isEditing } = this.props;

    return (
      <div>
        <Field
          //name={`configuration.${schema.configKey}`}
          name={`configuration.${schema.configKey}`}
          type={schema.type}
          component={renderField}
          required={schema.required || true}
          label={schema.label}
          disabled={isEditing && schema.enableEdit}
          parse={(value) => {
            return value === undefined ? false : value;
          }}
          format={(value) => {
            return value === undefined ? false : value;
          }}
        />
      </div>
    )
  }

}

interface List {
  fetching: boolean;
  error: object;
  data: [{
    fetching: boolean;
    error: object;
    data: any;
  }];
}

interface FormSectionProps {
  classes: any;
  title: any;
}

class FormSection extends React.Component < FormSectionProps > {
  render() {

    const { classes, title, children } = this.props;

    return (
      <Card className={classes.stageSchemaCard}>
        <CardContent>
          <Typography variant="h5" gutterBottom>{title}</Typography>  
          <Divider className={classes.formSectionHeadDivider} light />
          <div>
            {children}
          </div>
        </CardContent>
      </Card>
    )
  }
}

interface FormSectionStageFieldProps {
  classes: any;
  message: any;
}

class FormSectionStageField extends React.Component < FormSectionStageFieldProps > {

  renderMessage(message, classes) {
    return <Typography className={classes.formStageMessage} variant="body1" gutterBottom>{message}</Typography>
  }

  render() {

    const { classes, message, children } = this.props;

    return (
      <div>
        {message && this.renderMessage(message, classes)}
        {children}
      </div>
    )
  }
}


interface IntegrationFormProps {
  classes: any; //Get type for MAterial UI classes
  initialValues: {
    _id: string;
  };
  formValueSelector: any;
  integrations: [any] & { ast: Function }; //Change to Integration Type
  integrationId: string;
  onFetchIntegrationAuth: Function;
  projects: [any] & { ast: Function }; //Change to Project Type
  onSubmit: Function;
  //redux form props
  selectedProjectId: string;
  selectedIntegrationId: string;
  integrationEndpoints: [any];
  handleSubmit: Function;
  onCreateProject: Function;
  submitting: boolean;
  submitSucceeded: boolean;
  invalid: boolean;
  clearFields: Function;
  onCancel: Function;
  dirty: boolean;
  formValues: any;
}

interface IntegrationFormState {
  updating: boolean;
}

class IntegrationForm extends React.Component < IntegrationFormProps, IntegrationFormState > {

  state = {
    updating: false,
    stageStates: []
  };

  static getDerivedStateFromProps(props, state) {
    return {
      updating: props.initialValues && props.initialValues._id,
      stateStages: [] //GEt the state of stages comparing the initi
    };
  }

  onSubmit = values => {
    if (this.state.updating) {
      return this.props.onSubmit(this.props.initialValues._id, values.toJS());
    } else {
      return this.props.onSubmit(values.toJS());
    }
  };

  findValue = (obj, path) => {
    for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
      obj = obj[path[i]];
    };
    return obj;
  };

  projectChange = (...args) => {};

  //mapListToOptions = (list, modifier) => {
  mapListToOptions = (list, modifier ? ) => {
    return list ?
      list.map(li => {
        const option = {
          value: li._id,
          label: li.name
        }
        return modifier ? modifier(option, li) : option;
      }) : [];
  };

  /**
  Parse the project options selected to the id to be stored in form state,
  The projectId field of the form will be just the id of the project selected.
  **/
  parseProject = projectOption => {
    return (projectOption === null || projectOption === undefined || projectOption === '') ? undefined : projectOption.value;
  }

  /**
  Formats the value stored in form state to that required by select,
  The projectId field of the form will be just the id of the project and so
  we need to find that in the list of projects to resolve the name.
  **/
  formatProject = (projectIdState, projects) => {

    return (projectIdState && projectIdState !== '' && projects) ?
      (() => {

        const project = projects.find(p => p._id === projectIdState);
        const value = project ? {
            value: project._id,
            label: project.name
          } :
          null;

        return value


      })() : null;

  };

  addTagToIntegrationOption = (option, integration) => {
    return {
      ...option,
      label: integration.tag ? `${option.label} (${integration.tag})` : option.label
    }
  }

  /**
  Parse the project options selected to the id to be stored in form state,
  The configuration.integration_id field of the form will be just the id of the integration
  //At the miniute the field in the DB is 'project'
  **/
  parseIntegration = integrationOption => {
    return (integrationOption === null || integrationOption === undefined || integrationOption === '') ? undefined : integrationOption.value;
  }

  /**
  Formats the value stored in form state to that required by select,
  The configuration.integration_id field of the form will be just the id of the integration selected and so
  we need to find that in the list of integrations to resolve the name.
  **/
  formatIntegration = (integrationIdState, integrations) => {
    if (!integrations) return;

    return (integrationIdState && integrationIdState !== '' && integrations) ?
      (() => {

        const integration = findIntegrationById(integrationIdState, integrations);
        return integration ?
          (() => {
            const option = {
              value: integration._id,
              label: integration.name
            }
            return this.addTagToIntegrationOption(option, integration);
          })() :
          null;

      })() : null;

  };

  handleSchemaComponentDataError = (error) => {
    //this.props.onFetchIntegrationAuth()
  }

  renderSelectSchema = (schema, integration, index, isEditing) => {

    const { formValues } = this.props;

    return (
      <div key={index}>
        <h4>{schema.label}</h4>
        <OptionsSelect name={`configuration.${schema.configKey}`} key={index} schema={schema} isEditing={isEditing} />
      </div>
    )
  }

  renderDataSelectSchema = (schema, integration, index, isEditing) => {

    const { formValues } = this.props;

    return (
      <div key={index}>
        <h4>{schema.label}</h4>
        <DataSelectSchema key={index} clearFields={this.props.clearFields} {...{schema, integration, formValues, isEditing}} onDataError={(error) => this.handleSchemaComponentDataError(error)}/>
      </div>
    )
  }

  renderFieldMapperSchema = (schema, integration, index, isEditing) => {

    const { formValues } = this.props;

    return (
      <div key={index}>
        <h4>{schema.label}</h4>
        <FieldMapper name={`configuration.${schema.configKey}`} {...{schema, integration, formValues, isEditing}} onDataError={(error) => this.handleSchemaComponentDataError(error)}/>
      </div>
    )
  }

  renderRowSelectSchema = (schema, integration, index, isEditing) => {

    const { formValues } = this.props;

    return (
      <div key={index}>
        <h4>{schema.label}</h4>
        <RowSelect name={`configuration.${schema.configKey}`} {...{schema, integration, formValues, isEditing}} onDataError={(error) => this.handleSchemaComponentDataError(error)}/>
      </div>
    )
  }

  renderOAuthSchema = (schema, integration, index, isEditing) => {

    const { initialValues, integrationId, classes, formValues } = this.props;

    const oauthProps = { classes, schema, integration, initialValues, integrationId, formValues, isEditing };

    return (
      <OAuth key={index} {...oauthProps} />
    )

  }

  renderBasicAuthSchema = (schema, integration, index, isEditing) => {

    const { initialValues, integrationId, classes, formValues, onFetchIntegrationAuth } = this.props;

    const basicAuthProps = { classes, schema, integration, initialValues, integrationId, formValues, onFetchIntegrationAuth, isEditing };

    return (
      <BasicAuth key={index} {...basicAuthProps} />
    )

  }

  renderConfigAuthSchema = (schema, integration, index, isEditing) => {

    const { initialValues, integrationId, classes, formValues, onFetchIntegrationAuth } = this.props;

    const configAuthProps = { classes, schema, integration, initialValues, integrationId, formValues, isEditing };

    return (
      <ConfigAuth {...configAuthProps} />
    )
  }

  renderTextSchema = (schema, integration, index, isEditing) => {

    const { initialValues, integrationId, classes, formValues } = this.props;

    const textProps = { classes, schema, integration, initialValues, integrationId, formValues, isEditing };

    return (
      <TextSchema key={index} {...textProps}/>
    )
  }


  renderScheduleSchema = (schema, integration, index, isEditing) => {

    const { initialValues, integrationId, classes, formValues } = this.props;

    const schemaProps = { classes, schema, integration, initialValues, integrationId, formValues, isEditing };

    return (
      <ScheduleSchema key={index} {...schemaProps}/>
    )
  }

  renderCheckboxSchema = (schema, integration, index, isEditing) => {

    const { initialValues, integrationId, classes, formValues } = this.props;

    const textProps = { classes, schema, integration, initialValues, integrationId, formValues, isEditing };

    return (
      <CheckboxSchema key={index} {...textProps}/>
    )
  }

  renderStageSchemaType = (schema, integration, index, isEditing) => {

    const { classes } = this.props;

    const renderType = () => {

      if (schema.schema) {
        return this.renderStageSchemaType(schema.schema, integration, index, isEditing);
      }

      const resolvedSchema = {

        ...schema,
        type: ((type) => {
          if (/([\w]+\.)/.test(type) && /authType/.test(type)) {
            const pathSegs = type.split(".");
            const provider = integration.providers.find(p => p.keyname === pathSegs[0]);
            if (provider && provider.services) {
              const authService = provider.services.find(s => s.name === 'auth');
              //console.log('authService : ', authService.authType)

              switch (authService.authType) {
                case "oauth2":
                case "oauth":
                  return "oauth";
                case "config":
                  return "configauth";
                case "basic":
                  return "basicauth";
                default:
                  return authService.authType
              }
              if (authService) {
                return `auth-${authService.config.authType}`;
              }
            }

          }
          return type;

        })(schema.type)
      }

      switch (resolvedSchema.type) {
        case "oauth":
          return this.renderOAuthSchema(resolvedSchema, integration, index, isEditing)
        case "basicauth":
          return this.renderBasicAuthSchema(resolvedSchema, integration, index, isEditing)
        case "configauth":
          return this.renderConfigAuthSchema(resolvedSchema, integration, index, isEditing)
        case "text":
        case "password":
          return this.renderTextSchema(resolvedSchema, integration, index, isEditing)
        case "schedule":
          return this.renderScheduleSchema(resolvedSchema, integration, index, isEditing)
        case "checkbox":
          return this.renderCheckboxSchema(resolvedSchema, integration, index, isEditing)
        case "select":
          return this.renderSelectSchema(resolvedSchema, integration, index, isEditing)
        case "dataselect":
          return this.renderDataSelectSchema(resolvedSchema, integration, index, isEditing)
        case "fieldmapper":
          return this.renderFieldMapperSchema(resolvedSchema, integration, index, isEditing)
        case "rowselect":
          return this.renderRowSelectSchema(resolvedSchema, integration, index, isEditing)
        case "flatmapper":
          return this.renderRowSelectSchema({
            ...resolvedSchema,
            ordered: true,
            addLabel: 'Add Field'
          }, integration, index, isEditing)
        default:
          return null
      }

    }

    const render = () => {
      return (
        <FormSectionStageField classes={classes} message={schema.message}>
          {renderType()}
        </FormSectionStageField>
      )
    }

    return Array.isArray(schema) ? schema.map((schema, index) => this.renderStageSchemaType(schema, integration, index, isEditing)) : (() => {
      return (
        <div className={classes.schemaTypeBox}>
          {index > 0 && <Divider className={classes.schemaTypeDivider} light />} 
          {render()}
        </div>
      )
    })()
  }

  renderStageSchema = (schema, integration, index, isEditing) => {
    return (
      <div>{this.renderStageSchemaType(schema, integration, index, isEditing)}</div>
    )
  }

  renderSchemaStage = (schemaStage, integration, index, isEditing) => {

    const { classes } = this.props;

    const validRequires = validateSchemaStageRequires(schemaStage, this.props.formValues)

    if (isEditing || validRequires) {
      return (
        <FormSection classes={classes} title={schemaStage.title}>
          {this.renderStageSchema(schemaStage.schema, integration, index, isEditing)}
        </FormSection>
      )
    }
    return null
  }

  renderConfigSchema = (integration, isEditing) => {
    return (integration && integration.configSchema) ?
      <div>{integration.configSchema.map((schema, index) => this.renderSchemaStage(schema, integration, index, isEditing))}</div> : <h3>{`Invalid Schema`}</h3>
  }

  render() {



    const {
      submitting,
      submitSucceeded,
      classes,
      projects,
      integrations,
      integrationId,
      handleSubmit,
      integrationEndpoints,
      formValues,
      onCreateProject,
      invalid,
      dirty
    } = this.props,


      rawProjects = projects || [], //projects && projects.data ? [...projects.data.map(p => p.data)] : [],
      projectOptions = this.mapListToOptions(rawProjects),
      //projectsLoading = (projects.ast && projects.ast().working) || !projects,
      projectsLoading = (projects.ast && projects.ast().working),

      intgerationFilter = integrationId ?
      (projectIntegrationIds, i) => {
        const integrationParentId = formValues && formValues.integrationId
        return projectIntegrationIds.includes(i._id) && i._id === integrationParentId
      } :
      (projectIntegrationIds, i) => !projectIntegrationIds.includes(i._id),

      rawIntegrations =
      (formValues && formValues.projectId && integrations) ? integrations.filter(i => {
        const project = projects ? projects.find(p => p._id === formValues.projectId) : undefined;

        //console.log('project : ', project);
        //console.log('project : ', project && project.integrations && project.integrations.length);

        if (project && project.integrations) {
          const projectIntegrationIds = project.integrations.map(i => i.integrationId);
          return intgerationFilter(projectIntegrationIds, i);
        } else {
          return true
        }

      }) : [],

      //rawIntegrations = integrations || [],
      integrationOptions = this.mapListToOptions(rawIntegrations, (option, integration) => {
        return this.addTagToIntegrationOption(option, integration);
      }),
      //integrationsLoading = (integrations.ast && integrations.ast().working) || !integrations,
      integrationsLoading = (integrations.ast && integrations.ast().working),
      selectedIntegration = (formValues && formValues.integrationId) ?
      findIntegrationById(formValues.integrationId, rawIntegrations) : undefined,

      isEditing = !!integrationId,
      isDisabled = isEditing,
      canSubmit = isEditing ?
      (!invalid && dirty) :
      (!invalid && formValues && formValues.projectId && formValues.projectId !== '') && selectedIntegration;

    //console.log('form projectId : ', formValues && formValues.projectId);
    //console.log('form integrations : ', integrations);
    //console.log('form rawIntegrations : ', rawIntegrations);

    if (submitting) {
      return (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progressSmall} />
          <Typography style={{marginTop: 10}} variant="body1" gutterBottom>Please wait, this could take a few minutes.</Typography>
        </div>
      )
    }

    if (submitSucceeded) {
      return (
        <Redirect to='/' />
      )
    }

    const integrationSelectChange = (event, newValue) => {
      selectedIntegration && this.props.clearFields(formName, false, false, ...(getAllConfigKeys(selectedIntegration)));
      //clearFields(formName, keepTouched: false, persistentSubmitErrors: false, ...keys);
    };

    return (
      <div className={classes.form}>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          {(formValues && !formValues.dev) && <div>
            <FormSection classes={classes} title="Step 1 - Choose your project">
              <FormSectionStageField classes={classes} message="Select a project from the list or enter new name to create a new project.">
                <Field
                  className={classes.select}
                  name="projectId"
                  type="select"
                  onChange={this.projectChange}
                  component={renderField}
                  label="Project"
                  options={projectOptions}
                  placeholder={
                    projectsLoading
                      ? "Loading projects..."
                      : "Select or create project..."
                  }
                  isClearable={true}
                  create={true}
                  onCreateOption={(value) => onCreateProject({name: value})}
                  isLoading={projectsLoading}
                  disabled={isDisabled || projectsLoading}
                  format={value => this.formatProject(value, rawProjects)}
                  parse={value => this.parseProject(value)}
                  required={true}
                />
              </FormSectionStageField>
            </FormSection>
            <FormSection classes={classes} title="Step 2 - Choose your integration">
              <FormSectionStageField classes={classes} message="Select an integration from the list of integrations available to you.">
                <Field
                  name="integrationId"
                  type="select"
                  onChange={integrationSelectChange}
                  component={renderField}
                  label="Integration"
                  options={integrationOptions}
                  placeholder={
                    integrationsLoading
                      ? "Loading Integrations"
                      : "Select an integration..."
                  }
                  isClearable={true}
                  create={false}
                  isLoading={integrationsLoading}
                  //disabled={integrationsLoading || integrationOptions.length < 2}
                  disabled={isDisabled || projectsLoading}
                  format={value => this.formatIntegration(value, rawIntegrations)}
                  parse={value => this.parseIntegration(value)}
                  required={true}
                />
              </FormSectionStageField>
            </FormSection>
          </div>}
          <div>
          {selectedIntegration && this.renderConfigSchema(selectedIntegration, isEditing)}
          </div>
          <div className={classes.actions}>
            {canSubmit && <Button
              type='submit'
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {isEditing ? 'Modify' : 'Launch'}
            </Button>}
            <Button
              type='button'
              variant="contained"
              color="default"
              className={classes.button}
              onClick={() => this.props.onCancel()}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const IntegrationReduxForm = reduxForm({
  form: formName,
  validate: validateIntegration,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(withStyles(styles)(IntegrationForm));

const mapStateToProps = (state, ownProps) => {
  return {};
};

const ConnectedIntegrationReduxForm = connect(mapStateToProps)(
  IntegrationReduxForm
);

interface ProjectIntegrationUpsertProps {
  initialValues: any;
  formValues: any;
  baseIntegration: any;
  integrationId: string; //Replace with Integration Interface
  integrations: any; //Do array type tih ast
  onCreateIntegration;
  onUpdateIntegration;
  onFetchIntegrationAuth;
  projects: any; //Do array type tih ast
  onCreateProject;
  classes: any;
  onCancel: Function;
}

class ProjectIntegrationUpsert extends React.Component < ProjectIntegrationUpsertProps > {

  state = {
    initialAuthFetched: false
  }

  componentDidUpdate() {

    if (this.props.formValues && !this.state.initialAuthFetched) {
      this.props.onFetchIntegrationAuth(this.props.formValues.tid);
      this.setState({ initialAuthFetched: true });
    }
  }

  submitIntegration = values => {

    const { baseIntegration } = this.props;

    if (!baseIntegration) {
      return Promise.reject('Casn not submit integration with unknown base integration');
    }

    //return this.props.onCreateIntegration(parseIntegrationForPersistence(values, baseIntegration));
    return values._id ? this.props.onUpdateIntegration(values) : this.props.onCreateIntegration(values);
  };

  render() {

    const { initialValues, formValues, classes, integrationId, integrations, projects, onCreateProject, onFetchIntegrationAuth } = this.props;

    //console.log('formValues : ', formValues);
    //console.log('integrationId : ', integrationId);
    //console.log('projects : ', projects);
    //console.log('integrations : ', integrations);

    if (formValues && !formValues.dev) {

      if (integrationId) {
        if (projects.ast && projects.ast().working) {
          return (
            <div className={classes.progressContainer}>
            <CircularProgress className={classes.progressSmall} />
          </div>
          )
        }
      }
    }


    return (
      <div className={classes.container}>
        <IntegrationReduxForm
          {...{initialValues, formValues, integrations, integrationId, projects, onCreateProject, onFetchIntegrationAuth}}
          onSubmit={(values) => this.submitIntegration(values)} onCancel={this.props.onCancel}
        />
      </div>
    );
  }
}

const getDevValues = (state, ownProps) => {

  const formValues = getFormValues(formName)(state.toJS());
  const { projects, integrations } = ownProps;

  const initialValues = {
    dev: true,
    tid: "1598324497888",
    projectId: "KyQfcMxrzvIatzH5woFp",
    integrationId: "hSOow5JwKkLx6jIWiK4M",
    configuration: {
      "aconex_v1": {
        auth: {
          status: "authorized"
        },
        projectId: "1879048428" //API Demo
      },
      "procore_v1": {
        auth: {
          status: "authorized",
          tokenId: "dS6Vav3m7vwPSeliytB8"
        },
        companyId: 13901, //Sandbox test
        projectId: 8411 // Test App
      }
    }
  }
  const baseIntegration = integrations.find(i => i._id === initialValues.integrationId);

  return {
    initialValues,
    formValues,
    baseIntegration
  };

}

const mapStateToUpsertProps = (state, ownProps) => {

  const urlParams = new URLSearchParams(ownProps.location.search);

  if (urlParams.has('dev') && urlParams.get('dev')) {
    return getDevValues(state, ownProps);
  }

  //console.log('mapStateToUpsertProps : ', state.toJS(), ownProps);

  const getAuthStateForIntegration = (liveIntegrationId, integration, integrationAuths, configuration) => {

    //console.log('getAuthStateForIntegration integration : ', integration);
    //console.log('getAuthStateForIntegration integrationAuths : ', integrationAuths);

    return integration.providers.reduce((c, ip) => {

      const ipAuthService = ip.services.find(ips => ips.keyname === 'auth');

      c[ip.keyname] = c[ip.keyname] || {};
      c[ip.keyname].auth = {};

      if (ipAuthService && ipAuthService.authType === 'config') {
        c[ip.keyname].auth.status = 'authorized';
        return c;
      }

      if (integrationAuths.ast().working) {
        c[ip.keyname].auth = {
          status: 'loading'
        };
      } else {

        const ia = integrationAuths.find(ia => ia.providerName === ip.keyname);

        //console.log('getAuthStateForIntegration ia : ', ia)

        if (ia) {

          c[ip.keyname].auth = {};
          c[ip.keyname].auth.tokenId = ia._id;
          c[ip.keyname].auth.status = ia.status || (() => {

            if (ia.config && ia.authType === 'config') {
              return 'authorized'
            }
            //For testing only
            //return 'authorized'
            if (ia.profile) {
              return 'authorized'
            }
            if (ia.error) {
              return 'error'
            }

          })();
          c[ip.keyname].auth.error = ia.error;

        }
      }

      return c;

    }, configuration)
  }

  const { projects, integrations } = ownProps,
  formValues = getFormValues(formName)(state.toJS()),

    queryProjectId = urlParams.has('project') && urlParams.get('project'),
    queryIntegrationId = urlParams.has('integration') && urlParams.get('integration'),
    queryTId = urlParams.has('tid') && urlParams.get('tid'),

    selectedProjectId = (formValues && (formValues.projectId || formValues.projectId === undefined || formValues.projectId === null)) ? formValues.projectId : undefined,
    selectedIntegrationId = (formValues && (formValues.integrationId || formValues.integrationId === undefined || formValues.integrationId === null)) ? formValues.integrationId : undefined,

    tid = (formValues && (formValues.tid || formValues.tid !== undefined || formValues.tid !== null)) ? formValues.tid : (queryTId || Date.now()),

    //baseIntegration = integrations.find(i => i._id === initialValues.integrationId) : undefined;
    baseIntegration = selectedIntegrationId ? integrations.find(i => i._id === selectedIntegrationId) : undefined,

    initialValues = ownProps.integrationId ?
    (() => {

      let integration: any = null;

      for (var p of projects) {
        if (!p.integrations) {
          break;
        }
        integration = p.integrations && p.integrations.find(i => i._id === ownProps.integrationId);
        if (integration) {
          break;
        }
      };

      return integration ? (() => {

        const
          integrationAuths = getIntegrationAuthsForIntegrationIdJS(state, tid),
          baseIntegration = integrations.find(i => i._id === integration.integrationId);

        return getSchemaInitialValues(baseIntegration, {
          ...integration,
          configuration: baseIntegration ? getAuthStateForIntegration(ownProps.integrationId, baseIntegration, integrationAuths, integration.configuration) : integration.configuration
        });

      })() : {}

    })() :

    (() => {

      const setProjectId = selectedProjectId || queryProjectId || undefined;
      const setIntegrationId = selectedIntegrationId || queryIntegrationId || undefined;

      const
        integrationAuths = setIntegrationId ? getIntegrationAuthsForIntegrationIdJS(state, tid) : [],
        integration = integrations.find(i => i._id === setIntegrationId);

      //console.log('integrationAuths : ', integrationAuths);

      if (!integration) {
        return {
          tid
        }
      }

      if (setProjectId && setIntegrationId && !integrationAuths.ast().working) {

        const configuration = getAuthStateForIntegration(tid, integration, integrationAuths, {});

        const values: any = {
          tid,
          containerImage: integration.containerImage,
          configuration
        }

        if (queryProjectId) {
          values.integrationId = queryIntegrationId,
            values.projectId = queryProjectId
        }

        return getSchemaInitialValues(baseIntegration, values);
      }
    })();

  return {
    initialValues,
    formValues,
    baseIntegration
  };
};

const mapDispatchToProps = (dispatch: Dispatch < AnyAction > ) => {
  return bindActionCreators({
      clearFields
    },
    dispatch
  );
}

export default connect(mapStateToUpsertProps, mapDispatchToProps)(withStyles(styles)(ProjectIntegrationUpsert));
