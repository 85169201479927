import React, { Fragment } from 'react';
import { Field } from 'redux-form/immutable';
import TextInput from './TextInput';
//import Checkbox from './Checkbox';
//import Radio from './Radio';
//import Textarea from './Textarea';
import Select from './Select';
import Checkbox from './Checkbox';
import Schedule from './Schedule';
//import MarkdownInput from './MarkdownInput';

export const renderField = (props) => { 

  switch (props.type) {
    case 'email':
    case 'password':
    case 'text':
    case 'number':
    case 'date':
      return <TextInput {...props} />;
    case 'checkbox':
      return <Checkbox {...props} />
    case 'schedule':
      return <Schedule {...props} />
    /*case 'radio':
      return <Radio {...props} />
    case 'textarea':
      return <Textarea {...props} />
    case 'markdown':
      return <MarkdownInput {...props} />*/
    case 'select':
      return <Select {...props} />
    default:
      return <div />
  }
};

const getFieldTypeForSchemaType = st => {
  switch (st) {
    case "string":
      return 'text';
    default:
      return 'text';
  }
}

export const renderSchema = (schema, namespace, classes, fieldProps = si => undefined) => {

  return (schema && Array.isArray(schema)) ? <Fragment>
    {schema.map((si, i) => {
      return (
        <Field
          name={`${namespace}.${si._id}`}
          classes={classes}
          type={getFieldTypeForSchemaType(si.type)}
          component={renderField}
          placeholder={si.title}
          label={si.title}
          {...((fieldProps && typeof fieldProps === 'function') && fieldProps(si))}
        />
      )
    })}
  </Fragment> : <div />

}