import axios from 'axios';
import config from '../config';

/*axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.common['Authorization'] = Cookies.get('loyalpilot__token');
axios.defaults.headers.post['Content-Type'] = 'application/json';*/

const axiosInstance = axios.create({
  baseURL: config.API_URL,
  timeout: 240000,
  headers: {
    'Content-Type': 'application/json',
    //'x-bim-user-mimic-id': 'bf70255c-0cde-4251-9ca0-5931ee991243'
  }
});

const createRequest = axios.create;

const connectRequest = (actions, instance) => {

	const request = instance || axiosInstance;

	function wrappedRequest(...args) {
	  return request(...args)
	}

	wrappedRequest.action = function(action, executor) {
	  return executor(request, action.payload)
	  	.catch(error => {
	  		console.log('wrappedRequest.action error : ', error.response);
	  		Promise.reject(error);
	  	})
	}

	wrappedRequest.get = function(...args) {
	  return request.get(...args);
	}

	wrappedRequest.post = function(...args) {
	  return request.post(...args);
	}

	Object.defineProperty(wrappedRequest, 'defaults', {
	  get() { return request.defaults; }
	})

	return wrappedRequest;

}

export {
	createRequest,
	connectRequest
}

export default axiosInstance;

