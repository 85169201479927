import * as React from 'react';

import {
  connect,
  Provider
} from 'react-redux';

import {
  ReactNode
} from 'react';

import {
  Router,
  Switch,
  Route,
  Redirect,
  withRouter
} from 'react-router'

import classnames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';
import HomeScene from '../HomeScene';
import ProjectIntegrationScene from '../ProjectIntegrationScene';
import LoginScene from '../LoginScene';
import Brand from '../Brand'

const getDeviceClasses = (device: any) => { // Get devive type
    return {
      desktop: !device.isMobileDevice,
      mobile: device.isMobileDevice,
      android: device.isAndroid,
      aos3: device.isAndroid3orGreater,
      ios: device.isIOS,
      ios8: device.isIOS8OrGreater,
      minimal: device.isMinimal,
      standalone: device.isStandalone,
      landscape: device.orientation === 'landscape',
      portrait: device.orientation === 'portrait'
    }
  }

interface AppProps {
  device: {
    isMobileDevice: boolean,
    viewportHeight: number,
    viewportWidth: number
  },  
}

const errorStyles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: spacing(3),
    marginRight: spacing(3),
    [breakpoints.up(700 + spacing(3) * 2)]: {
      maxWidth: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${spacing(2)}px ${spacing(3)}px ${spacing(3)}px`,
  },
  head: {
    textAlign: 'center',
    borderBottom: 1,
  },
  mainError: {
    marginBottom: spacing(2),
  },
  logo: {
    width: '75%',
    margin: 'auto',
    marginBottom: spacing(3),
    marginTop: spacing(3),
  }
})

interface ErrorProps {
  classes: {
    container: string,
    main: string,
    paper: string,
    head: string,
    mainError: string,
    logo: string
  }
}

const Error = withStyles(errorStyles)(function(props:ErrorProps) {

  const { classes } = props
  return (
    <div className={classnames('app-container')}>
      <div className={classnames('content-container')}>
        <div className={classes.container}>
          <main className={classes.main}>
            <Paper className={classes.paper}>
              <div className={classes.head}>
                <Brand />
              </div>
              <div style={{textAlign: 'center'}}>
                 <Typography className={classes.mainError} component="h1" variant="h5" gutterBottom>Sorry something has gone wrong!</Typography>
                 <Typography variant="body1" gutterBottom>Please contact support at</Typography>
                 <Typography variant="body1" gutterBottom>support@bimlauncher.com.</Typography>
              </div>
            </Paper>
          </main>
        </div>
      </div>
    </div>
  )
    
})

class App extends React.Component<AppProps> {

  appContainer: ReactNode

  render() {

    const
      deviceClasses = getDeviceClasses(this.props.device),
      style = this.props.device.isMobileDevice ? {
        height: `${this.props.device.viewportHeight}px`,
        width: `${this.props.device.viewportWidth}px`
      } :
        undefined

    return (
      <div ref={container => this.appContainer = container} id='app-container' className={classnames('app-container', { ...deviceClasses })} style={style}>
        <div className='app-background'>
        </div>
        <div className={classnames('content-container', { ...deviceClasses })}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

const ConnectedApp = withRouter(connect((state: object) => {
  return {
    device: {
      mobile: false
    }
  }
}, null)(App))

interface AppContainerProps { }

export default class AppContainer extends React.Component<{}, { hasError: boolean }> {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    //error is an error that has been thrown.
    //info is an object with componentStack key. The property has information about component stack during thrown error.
    // Display fallback UI
    this.setState({
      hasError: true
    });
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Error />;
    }

    return (
      <ConnectedApp>
        <Switch>
          <Route
            exact={true}
            path='/'
            render={(routeProps) => <HomeScene />} />
          <Route
            exact={true}
            path='/dashboard'
            render={(routeProps) => <HomeScene />} />
          <Route
            exact={false}
            path='/integration'
            render={(routeProps) => <ProjectIntegrationScene />} />
          <Route
            exact={false}
            path='/login'
            render={(routeProps) => <LoginScene />} />
        </Switch>
      </ConnectedApp>
    );
  }
}
