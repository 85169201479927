/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = (theme: Theme) => createStyles({
  root: {
  },
  input: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing() / 2}px ${theme.spacing() / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 'inherit',
  },
  placeholder: {
    position: 'absolute',
    left: theme.spacing(2),
    fontSize: 'inherit',
  },
  paper: {
    position: 'absolute',
    zIndex: 999,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classnames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

interface SelectProps {
  classes: any,
  className: any,
  classNames: any,
  theme: any,
  create: boolean,

  value: {
    value: string,
    label: string
  },
	options: [{
		value: string,
		label: string
	}?];
  label: string,
  placeholder: string,

  onChange: (any) => void,
  onFocus: (any) => void,
  onBlur: (any) => void,
  onBlurValues: (any) => void,
  
  
  onCreateOption: (any) => void,

  maxMenuHeight: number,
  isLoading: boolean,
  isDisabled: boolean, 
	isClearable: boolean,
	isMulti: boolean,
  

}

class IntegrationReactSelect extends React.Component<SelectProps> {

  static defaultProps: Partial<SelectProps> = {
    options: [],
    create: false
  }

  selectRef;
  
  ref = ref => {
    this.selectRef = ref;
  }

  render() {
    
    const
    	{ classes, className, classNames, theme, create, value, options, label, placeholder, onChange, onFocus, onCreateOption, maxMenuHeight, isLoading, isDisabled, isClearable, isMulti  } = this.props,

      onBlur = (event) => {


        this.props.onBlur && this.props.onBlur(event)

        /*this.props.onBlur && this.props.onBlur({
          ...event,
          target: {
            ...event.target,
            value: this.selectRef.state.value 
          }
        });*/
      },
			
			selectStyles = {
	      input: base => ({
	        ...base,
	        color: theme.palette.text.primary,
	        '& input': {
	          font: 'inherit',
	        },
	      }),
	    },

	    props = {

        ref: this.ref,

        classes,
        styles: selectStyles,
        textFieldProps: {
          label,
          variant:"outlined",
          InputLabelProps: {
            shrink: true,
          },
        },

        value,
        options,
        label,
        placeholder,

        onChange,
        onFocus,
        onBlur,
        onCreateOption,

        maxMenuHeight,
	    	isLoading,
        isDisabled,
        isClearable,
        isMulti,

        components
        
	    };

    return (
      <div className={classnames(classes.root, this.props.className)}>
        <NoSsr>
        	{
        		create ? (
							<Creatable {...props} />
						) : ( <Select {...props} /> )
        	}
        </NoSsr>
      </div>
    );
  }
}


export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);