import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';
import Brand from '../Brand'

const styles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: spacing(3),
    marginRight: spacing(3),
    [breakpoints.up(400 + spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${spacing(2)}px ${spacing(3)}px ${spacing(3)}px`,
  },
  head: {
    textAlign: 'center',
    borderBottom: 1,
    marginTop: spacing(1),
    marginBottom: spacing(3)
  },
  textField: {
    marginTop: 0,
    marginBottom: 0,
  },
  progress: {
    margin: spacing(2),
    marginTop: spacing(4)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    marginTop: spacing(3),
  },
  error: {
    marginBottom: spacing(3),
    color: '#f00'
  },
  logo: {
    width: '75%',
    margin: 'auto',
    marginBottom: spacing(3),
    marginTop: spacing(3),
  }
});

interface LoginSceneProps {
  classes: {
    main: string,
    paper: string,
    head: string,
    form: string,
    submit: string,
    progress: string,
    error: string,
    logo: string
  }
  onSubmit: Function,
  loggingIn: boolean,
  loginError: {
    message: string
  },
  history: any
}

interface LoginState {
  auth: {
    email: string,
    password: string
  }
}

class LoginForm extends React.PureComponent<LoginSceneProps, LoginState> {

  static defaultProps: Partial<LoginSceneProps> = {
  }

  state = {
    auth: {
      email: '',
      password: '',
    },
  };

  submitLogin = (e) => {
    e.preventDefault();
    this.props.onSubmit(this.state.auth)
  }

  handleChange = (event) => {
    const { auth } = this.state;
    auth[event.target.name] = event.target.value;
    this.setState({ auth });
  }
  
  renderError() {
    const { classes, loginError } = this.props;
    return (
      <div>
        <Typography className={classes.error} variant="body1" gutterBottom>{loginError.message}</Typography>
      </div>
    )
  }

  render() {

    const { classes, loggingIn, loginError, history } = this.props;

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>

          <div className={classes.head}>
            <Brand />
          </div>
          
          {loggingIn ?
            <Fragment>
              <Typography component="h1" variant="h5">Logging in...</Typography>
              <CircularProgress className={classes.progress} />
            </Fragment> :
            <Fragment>
              {loginError && this.renderError()}
              <form className={classes.form} onSubmit={this.submitLogin}>
                <FormControl margin="none" required fullWidth>
                  <TextField
                    id="email"
                    name="email"
                    variant="outlined"
                    label="Email Address"
                    margin="dense"
                    autoComplete="email"
                    onChange={this.handleChange}
                    required autoFocus />
                </FormControl>
                <FormControl margin="none" required fullWidth>
                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    variant="outlined"
                    label='Password'
                    margin="dense"
                    onChange={this.handleChange}
                    required
                    autoComplete="current-password"
                   />
                </FormControl>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign in
              </Button>
              </form>
              <Button color="primary" onClick={() => history.push('/login/reset')} ><Typography className={classes.submit} component="p" variant="body1">Forgotten Password?</Typography></Button>
            </Fragment>
          }
        </Paper>
      </main>
    );
  }
}

export default withStyles(styles)(LoginForm);
