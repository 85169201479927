import * as React from "react";
import { Theme } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

const styles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
	statusError: {
    color: '#f00'
  },
  statusOk: {
    color: '#0f0'
  },
  statusAuthorisedBlock: {
  	display: 'flex',
  	flexDirection: 'row',
  	justifyContent: 'flex-start',
  	alignItems: 'center'
  },
  statusDoneIcon: {
    color: '#0f0',
    marginTop: spacing(),
    marginBottom: spacing(),
    marginRight: spacing(),
    display: 'inline'
  },
  progressSmall: {
    marginTop: spacing(),
    marginBottom: spacing()
  }
})

//These need proper interfaces
interface ConfigAuthProps {
	classes: any;
	schema: any;
	integration: any;
	initialValues: any;
	integrationId: string;
	formValues: any;
}

class ConfigAuth extends React.Component<ConfigAuthProps> {

	render() {

		const {classes, schema, integration, initialValues, integrationId, formValues} = this.props;

		return <div className={classes.statusAuthorisedBlock}>
			<CheckCircleOutlinedIcon style={{ fontSize: 30 }} className={classes.statusDoneIcon} />
			<Typography variant="body1" gutterBottom>This service is has been succesfully authorised with configuration.</Typography>
		</div>

	}
}


export default withStyles(styles)(ConfigAuth);