import {
  fromJS
}
from 'immutable'

import {
  USER_COOKIE_SUCCESS,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
  USER_SIGNUP_FAILED,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILED,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILED
} from './const';

const initialState = fromJS({
  loggingIn: false,
  loginError: undefined,
  loggingOut: false,
  logOutError: undefined,
  signingUp: false,
  signUpError: undefined,
  updatingUser: false,
  updateUserError: undefined,
  user: {
    //auth: {
      //token: Cookies.get('bl_user_token'),
    //}
    auth: undefined,
    profile: undefined
  }
});

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return state.merge({
        loggingIn: true,
        loginError: null,
        user: fromJS({
          auth: undefined,
          profile: undefined,
          authorized: false
        })
      })
    case USER_LOGIN_SUCCESS:
    case USER_COOKIE_SUCCESS:
      return state.merge({
        user: fromJS({
          ...action.payload,
          authorized: true
        }),
        loggingIn: false,
        loginError: null,
      });
    case USER_LOGIN_FAILED:
      return state.merge({
        user: fromJS({
          auth: undefined,
          profile: undefined,
          authorized: false
        }),
        loggingIn: false,
        loginError: action.payload.error,
      });
    case USER_LOGOUT_REQUEST:
      return state.merge({
        loggingOut: true,
        logOutError: null
      })
    case USER_LOGOUT_SUCCESS:
      return state.merge({
        user: fromJS({
          auth: undefined,
          profile: undefined,
          authorized: false
        }),
        loggingOut: true,
        logOutError: null
      });
    case USER_LOGOUT_FAILED:
      return state.merge({
        loggingOut: false,
        logOutError: action.payload.error,
      });
    case USER_SIGNUP_REQUEST:
      return state.merge({
        signingUp: true,
        signUpError: null,
        user: fromJS({
          auth: undefined,
          profile: undefined,
          authorized: false
        })
      })
    case USER_SIGNUP_SUCCESS:
      return state.merge({
        user: fromJS({
          ...action.payload,
          authorized: true
        }),
        signingUp: false,
        signUpError: null,
      });
    case USER_SIGNUP_FAILED:
      return state.merge({
        user: fromJS({
          auth: undefined,
          profile: undefined,
          authorized: false
        }),
        signingUp: false,
        signUpError: action.payload.error,
      });
    case USER_UPDATE_REQUEST:
      return state.merge({
        user: fromJS({
          profile: undefined,
          authorized: false
        }),
        updatingUser: true,
        updateUserError: undefined
      })
    case USER_UPDATE_SUCCESS:
      return state.merge({
        user: fromJS({
          ...action.payload,
          authorized: true
        }),
        updatingUser: false,
        updateUserError: undefined,
      });
    case USER_UPDATE_FAILED:
      return state.merge({
        user: fromJS({
          auth: undefined,
          profile: undefined,
          authorized: false
        }),
        updatingUser: false,
        updateUserError: action.payload.error,
      });
    default:
      return state;
  }
}