import React from 'react';
import classnames from 'classnames';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';
//import BimLogo from '../../images/bim_logo_blk_400.png';
import Brand from '../Brand'

const styles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  button: {
    margin: spacing(1)
  },
  grow: {
    flexGrow: 1
  },
  logo: {
    height: 20,
    marginBottom: spacing(2),
    marginTop: spacing(2),
    marginRight: spacing(2),
    marginLeft: spacing(),

  }
})

interface HeadBarProps {
  onSignOut: Function
  classes: {
    button: string,
    grow: string,
    logo:string
  }
}

class HeadBar extends React.PureComponent<HeadBarProps>{

  render() {

    const { classes, onSignOut } = this.props

    return (
      <AppBar position="static" color="default">
        <Toolbar >
           <div className={classes.grow}>
             <Brand />
           </div>
           <Button color="primary" className={classes.button} onClick={() => onSignOut()}>Signout</Button>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(HeadBar);