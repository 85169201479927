import * as React from 'react';
import classnames from 'classnames';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';


import {
  bindActionCreators,
  //Types
  Dispatch,
  AnyAction
} from 'redux'

import {
  connect,
  Provider
} from 'react-redux';

import {
  Redirect,
  withRouter
} from 'react-router'

import HeadBar from '../HeadBar';

import {
  prepareApplication,
  startApplication,
  logoutUser,
  getApplicationStateJS,
  getUserJS
} from '../../redux';


const classes = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  progress: {
    margin: spacing(2),
  }
});


interface SceneContainerProps {
  showHead: boolean;
  headerText: string;
  className: string;
  secure: boolean;
  classes: {
    progress: string
  },
  //Application state
  appState: {
    applicationPreparing: boolean;
    applicationPrepared: boolean;
    applicationStarting: boolean;
    applicationStarted: boolean;
  };
  user: {
    authorized: boolean,
    token: string,
    profile: object
  };
  onLogoutUser: Function,
  onPrepareApplication: Function;
  onStartApplication: Function;
  onStartApplicationComplete: Function;
  match: {
    path: string
  };
  location: {
    path: string,
    state: any
  };
}

class SceneContainer extends React.Component<SceneContainerProps> {

  static defaultProps: Partial<SceneContainerProps> = {
    showHead: false,
    secure: true
  }

  state = {};

  static getDerivedStateFromProps(props, state) {

    if (!props.appState.applicationPreparing && !props.appState.applicationPrepared) {
      props.onPrepareApplication();
      return {}
    }

    if (props.appState.applicationPrepared && !props.appState.applicationStarted) {
      props.onStartApplication();
      return {}
    }

    if (props.appState.applicationPrepared && props.appState.applicationStarted) {
      props.onStartApplicationComplete && props.onStartApplicationComplete();
    }

    return {}
  }

  renderHead() {

    if (this.props.showHead) {
      return (
        <HeadBar onSignOut={this.props.onLogoutUser}/>
      )
    }
  }

  redirectToLogin() {

    const hashPoint = window.location.href.indexOf("#");
    const referrer = hashPoint > -1 ? window.location.href.slice(hashPoint + 1) : '/';

    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { referrer }
        }}
      />
    )
  }

  renderScene() {

    if (this.props.appState.applicationStarted) {
      return (this.props.secure && !this.props.user.authorized ?
        this.redirectToLogin() :
        <main className={classnames('bl-scene-container', this.props.className)}>
          {this.renderHead()}
          {this.props.children}
        </main>
       )
    }
    return (
      <div className={classnames('bl-scene-container', this.props.className)}>
        <div className='bl-scene-container__content__loader'>
          <CircularProgress className={this.props.classes.progress} />
        </div>
      </div>
    )
  }

  render() {
    return this.renderScene();
  }
}

function mapStateToProps(state: object) {
  const appState = getApplicationStateJS(state)
  const userState = getUserJS(state)
  return {
    appState,
    user: userState
  }
  /*return {
    applicationPrepared: true,
    applicationStarted: true
  }*/
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({
    onPrepareApplication: prepareApplication, //() => ({type:'None'}),
    onStartApplication: startApplication, //() => ({type:'None'})
    onLogoutUser: logoutUser
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(classes)(SceneContainer)))
