import set from 'lodash.set';

import config from '../config';
import {request} from '../remote';


function formatIntegrationForForm(integration) {
}

function parseIntegrationForPersistence(formIntegration, baseIntegration) {
	const { configuration } = formIntegration;

	if(!baseIntegration) {
		throw new Error('Can not submit integration with unknown base integration.');
	}

	////console.log('parseIntegrationForPersistence formIntegration : ', formIntegration);

	const mapIntegrationProvider = key => {

		const baseIntergrationProvider = baseIntegration.providers.find(bip => bip.keyname === key);

		if(!baseIntegration) {
			throw new Error('Can not submit integration with unknown base integration provider.');
		}

		return {
			name: key,
			baseURI: baseIntergrationProvider.baseURI,
			...configuration[key]
		}
	}

	const persistableIntegration =  {
		...formIntegration,

		configuration: {
			integration: configuration.integration,
			providers: [
				...Object.keys(configuration).reduce((agg: any[], key) => {
					if(key !== 'integration') {
						agg.push(mapIntegrationProvider(key))
					}
					return agg;
				}, [])
			]	
		}
	}

	////console.log('parseIntegrationForPersistence persistableIntegration : ', persistableIntegration);

	return persistableIntegration;
}


function findProvider (integration, providerName ) {
	return integration.providers.find(p => p.keyname === providerName);
}

function findProviderServiceFromIntegration (integration, providerName, serviceName) {
	const provider = findProvider(integration, providerName);
	//////console.log('findProviderService integration ', integration);
	//////console.log('findProviderService %s %s ', providerName, serviceName);
	//////console.log('findProviderService provider.services ', provider.services);
	return (provider && provider.services) ? provider.services.find(s => s.name === serviceName) : undefined;
}

function findProviderServiceFromProvider (provider, serviceName) {
	return (provider && provider.services) ? provider.services.find(s => s.name === serviceName) : undefined;
}

function getServiceIdentifierFromServicePath (servicePath) {
	const serviceIden = servicePath.split('.');
	return {
		providerName: serviceIden[0],
		serviceName: serviceIden[1],
	}
}

function findIntegrationProviderServiceFromPath (servicePath, integration) {
	const { providerName, serviceName } = getServiceIdentifierFromServicePath(servicePath);
	return findProviderServiceFromIntegration(integration, providerName, serviceName);
}

function normaliseRequires(requires) {
	return requires ? (Array.isArray(requires) ? requires : [requires]) : [];
}

function getFromData(path, data) {
	////console.log('getFromData path, data : ', path, data);
	const get = p => o => p.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, o);
	return get(path.split('.'))(data);
}

function loadDataFromProviderServiceFromPath (integrationTid, servicePath: object, integration, params) {

	if(!servicePath) {
		return Promise.reject({message: 'Inavlids service path'})
	}

	const { providerName, serviceName } = getServiceIdentifierFromServicePath(servicePath);
	
	const provider = findProvider(integration, providerName);
	const service = findProviderServiceFromProvider(provider, serviceName);
	const providerAuthService = provider && provider.services.find(s => s.name === 'auth');

	if(provider && service) {
		const { baseURI } = provider;
		const serviceConfig = {...service.config}|| {}

		serviceConfig.requests = serviceConfig.requests || []; 

		serviceConfig.auth = providerAuthService

		if(service.requests) {
			serviceConfig.requests.push(...service.requests);
		}

		if(service.URI) {
			serviceConfig.requests.push({URI: service.URI})
		}

		//////console.log('loadDataFromProviderServiceFromPath serviceConfig : ', serviceConfig);
		////console.log('loadDataFromProviderServiceFromPath URI : ', service.URI);

		return request.post(`${config.API_URL}/integrations/provider/service`, {
			integrationTid,
			providerName,
			serviceName,
			serviceConfig: {
				baseURI,
				...serviceConfig,
				params
			}
		})
		.then(response => {
			////console.log('loadDataFromProviderServiceFromPath response : ', response);
			return response.data
		})

	}

	return Promise.reject("Provider and service must be defined");
}

function getParametersForRequires(requires, values) {

	const setParam = (param, prop, object) => {
		const value = getFromData(prop, values)
		object[param] = value;
		return object
	}

	return requires ?  normaliseRequires(requires).reduce((o, r) => {
		return setParam(r.param, r.prop, o);
	}, {}) : {}

}

function compareSchemaRequires(schema, values, newValues) {

	function getParams(schema, values) {

		const
			schemaParamRequires = normaliseRequires(schema.requires)
				.filter(r => {
					return !(typeof r === 'string') && r.param
				}),
			schemaRequiresParams =  getParametersForRequires(schemaParamRequires, values),
			sourceRequiresParams = schema.source && !(typeof schema.source === 'string') ? getParametersForRequires(schema.source.requires, values) : {},

			sourcesRequiresParams = schema.sources ? schema.sources.reduce((params, s) => {
				return !(typeof s === 'string') ? {...params, ...(getParametersForRequires(s.requires, values))} : params
			}) : {};

		return {...schemaRequiresParams, ...sourceRequiresParams, ...sourcesRequiresParams}

	}

	const
		params = getParams(schema, values),
		newParams = getParams(schema, newValues);

	//console.log('compareSchemaRequires params : ', params);
	//console.log('compareSchemaRequires newParams : ', newParams);

	for(var pk in params) {
		if(params[pk] !== newParams[pk]) {
			return {
				match: false,
				params,
				newParams
			};
		}
	}

	return {
		match: true,
		params,
		newParams
	};
}

function loadOptionsForIntegrationSchema(integrationId, integration, schema, values) {
	const schemaParamRequires = schema.requires ?
		normaliseRequires(schema.requires).filter(r => !(typeof r === 'string') && r.param)
		: []

	const schemaRequiresParams = getParametersForRequires(schemaParamRequires, values);
	return loadOptionsForIntegrationSchemaSource(integrationId, integration, schema.source, values, schemaRequiresParams);
}

function loadOptionsForIntegrationSchemaSource(integrationId, integration, source, values, params?) {
	let startParams = source.params ? {
		...params,
		...source.params
	} : params

	const args = (typeof source === 'string') ?
		[source, integration, {...startParams}] :
		[source.service, integration, {...startParams, ...getParametersForRequires(source.requires, values)}]

	//args = (servicePath: object, integration, params)
	return loadDataFromProviderServiceFromPath(integrationId, args[0], args[1], args[2])
	.then(response => {
		////console.log('loadOptionsForIntegrationSchemaSource response : ', response);
		return response;
	})
	.catch(e => {
		////console.log('loadOptionsForIntegrationSchemaSource error : ', e);
		return Promise.reject({
			message: (e.response && e.response.data && e.response.data.message) || ((typeof e === 'string') && e ) || ((typeof e.message === 'string') && e.message ) || "Unknown Error"
		})
	}) 
}

function callProviderAuth(integration, providerName, service, schema, formValues) {

	const provider = findProvider(integration, providerName);

	const { baseURI } = provider;

	const authFormValues = getFromData(`configuration.${schema.configKey}`, formValues);

	console.log('authFormValues ": ', authFormValues);

	////console.log('schema.configPath : ', schema);
	////console.log('authFormValues : ', formValues);
	////console.log('authFormValues : ', authFormValues);

	const authConfig = {
		...service.config,
		...authFormValues
	}

	return request.post(`${config.API_URL}/integrations/provider/service`, {
			integrationId: integration._id,
			providerName,
			serviceName: service.keyname,
			serviceConfig: {
				baseURI,
				...authConfig,
			}
		})
		.then(response => {
			return response.data
		})
		.catch(error => {
			////console.log('error : ', error);
			/*return {
				...error
			}*/
		})
}

function findProviderAuthValues(initialValues, providerName, serviceName) {

	//console.log('findProviderAuthValues : ', initialValues, providerName, serviceName);

	//Here inital values comes from the reduxform store after connecting the form.
	//Because the redux store is immutablejs the props comes through as a Map and so we need to do to JS.
	//const serviceInitialValues = {};
	 return initialValues ? ((values) => {

	 	//console.log('findProviderAuthValues values " ', values)

	 	return (values.configuration && values.configuration[providerName]) ?
	 		values.configuration[providerName][serviceName] : undefined

	 })(initialValues.toJS ? initialValues.toJS() : initialValues) : undefined

}

function requireHasValue(data) {
	if(Array.isArray(data)) {
		return data.filter(o => {
			return (typeof o === 'object') ? Object.keys(o).length > 0 : true;
		}).length > 0;
	}
	return data !== null;

}

function validateRequire(requires, data) {
	return (typeof requires === 'string') ?
		requireHasValue(getFromData(requires, data)) :
		(requires.prop ? ( requires.value ? getFromData(requires.prop, data) === requires.value : requireHasValue(getFromData(requires.prop, data))) : false)
}

function validateSchemaStageRequires(schemaStage, form) {

	const validateSchemaRequires = (schema) => {

		//testing only
		//return true;
		/*if(schema.stagename === "integration_mapper") {
			return true
		}*/
		//

		const { requires, source, sources } = schema;

		let allSchemaRequires = schema.requires ? normaliseRequires(schema.requires) : [];

		if(schema.source && schema.source.requires) {
			const sourceRequires = normaliseRequires(schema.source.requires)
			allSchemaRequires.push(...sourceRequires);
		}

		if(schema.sources && Array.isArray(schema.sources)) {

			schema.sources.forEach(ss => {
				const sourceRequires = normaliseRequires(ss.requires)	
				allSchemaRequires.push(...sourceRequires);
			})
		}

		let requirementsMet = false;	

		requirementsMet =  (allSchemaRequires.length === 0) || allSchemaRequires.every(r => validateRequire(r, form.configuration));

		////console.log('allSchemaRequires : ', allSchemaRequires);
		////console.log('requirementsMet : ', requirementsMet);

		if(requirementsMet) {
			if(schema.schema) {
				if(Array.isArray(schema.schema)) {
					for(var s of schema.schema) {
						requirementsMet = validateSchemaRequires(s);
						if(!requirementsMet) {
							break;
						}
					}
				} else {
					requirementsMet = validateSchemaRequires(s);
				}
			}
		}

		////console.log('requirementsMet : ', requirementsMet);

		return requirementsMet;

	}

	return validateSchemaRequires(schemaStage);
}

function findDependentFields(configKey, integration) {

	function checkRequires(requires) {
		if(Array.isArray(requires)) {
			return requires.some(req => {
				return req === configKey || req.prop === configKey;
			})
		} else {
			return requires === configKey || requires.prop === configKey;
		}
	}

	function checkSchema(schema) {
		const keys:any[] = []
		let match = false
		if(schema.requires) {
			match = checkRequires(schema.requires);
		}
		if(!match && schema.source && schema.source.requires) {
			match = checkRequires(schema.source.requires);
		}
		if(!match && schema.sources) {
			match = schema.sources.some(s => {
				return s.requires && checkRequires(s.requires);
			})
		}

		if(match && !!schema.configKey) {
			keys.push(schema.configKey)
		}

		if(schema.schema && Array.isArray(schema.schema)) {
			const nestedKeys:any[] = []
			schema.schema.forEach(s => {
				nestedKeys.push(...checkSchema(s))
			})
			keys.push(...nestedKeys)
		}
		
		return keys
	}

	const dependentKeys = integration.configSchema ? integration.configSchema.reduce((keys, cs) => {
		const schemaKeys = checkSchema(cs);
		////console.log('schemaKeys :" ', schemaKeys);
		keys.push(...schemaKeys);
		return keys;
	}, []) : []

	const namespacedKeys = dependentKeys.map(ck => `configuration.${ck}`);

	////console.log('findDependentFields configKeys : ', namespacedKeys);

	return namespacedKeys;
}

function getAllConfigKeys(integration) {

	function getSchemaKeys(schema) {
		const keys:any[] = []
		if(schema.configKey) {
			keys.push(schema.configKey);
		}
		if(schema.schema && Array.isArray(schema.schema)) {
			schema.schema.forEach(s => {
				keys.push(...getSchemaKeys(s))
			})
		}

		return keys;
	}

	const configKeys = integration.configSchema ? integration.configSchema.reduce((keys, cs) => {
		keys.push(...(getSchemaKeys(cs)));
		return keys;
	}, []) : []

	const namespacedKeys = configKeys.map(ck => `configuration.${ck}`);

	////console.log('getAllConfigKeys configKeys : ', namespacedKeys);

	return namespacedKeys;
}

function getFlatConfigSchemasProperties(integration, properties) {

	function getSchemas(schema) {
		
		const schemas:any[] = []
		
		if(schema.configKey) {

			const schemaProps:any = {}
			
			schemaProps.configKey = schema.configKey;
			schemaProps.type = schema.type;

			properties.forEach(p => {
				if(schema[p]) {
					schemaProps[p] = schema[p];
				}
			})

			schemas.push(schemaProps);
		}

		if(schema.schema && Array.isArray(schema.schema)) {
			schema.schema.forEach(s => {
				schemas.push(...getSchemas(s))
			})
		}

		return schemas;
	}

	const schemas = integration.configSchema ? integration.configSchema.reduce((schemas, cs) => {
		schemas.push(...(getSchemas(cs)));
		return schemas;
	}, []) : []

	const namespacedSchemas = schemas.map(ck => ({...ck, configKey:`configuration.${ck.configKey}`}));

	////console.log('getFlatConfigSchemasProperties configKeys : ', namespacedSchemas);

	return namespacedSchemas;
}

function validateConfigSchemaValues(integration, values, errors) {


	//console.log('validateConfigSchemaValues values : ', values);
	//console.log('validateConfigSchemaValues');

	const validate = (configKey, value, validations) => {

		////console.log('validateConfigSchemaValues configKey, value : ', configKey, value)

		if(!value) {
			return 'This must be set'
		}

		if(validations) {
			for(var v of validations) {

				if(v.requiredField) {

					const subField = getFromData(v.requiredField[0], value);
					if(Array.isArray(v.requiredField)) {

						if(subField !== v.requiredField[1]) {
							return {_error: `This ${v.requiredField[0]} must be ${v.requiredField[1]}`}
							//return {[configKey]: `This ${v.requiredField[0]} must be ${v.requiredField[1]}`}
						}
					}

					if(typeof v.requiredField === 'string') {
						if(subField === undefined || subField === null) {
							return {_error: `This ${v.requiredField[0]} must be ${v.requiredField[1]}`}
							//return {[configKey]: `This ${v.requiredField[0]} must be ${v.requiredField[1]}`}
						}
					}
				}

				if(v.minimumSchedule) {

					if((isNaN(value[0]) || value[0] < 1) && (!value || isNaN(value[1]) || value[1] < v.minimumSchedule[1])) {
						return {_error: `The scheduled sync must be at least 15 minutes`}
					}
				}

				////console.log('validateConfigSchemaValues configKey, value : ', v, configKey, value, Object.keys(value).length);

				if(v.minimum && (!isNaN(value.length))) {

					if(Array.isArray(value)){
						if((value.length >= v.minimum) && (() => value.some(v => Object.keys(v).length < 1))()) {
							return {_error: `All rows must have a selection`}
						}
						if((value.length < v.minimum) || (() => value.some(v => Object.keys(v).length < 1))()) {
							return {_error: v.message || `You must select at least ${v.minimum}`}
						}

					}
				} 
			}	
		}
	}

	if(integration) {
		const schemas = getFlatConfigSchemasProperties(integration, ['validations']);

		schemas.forEach(s => {

			//console.log('validateConfigSchemaValues schema : ', s);
			const value = getFromData(s.configKey, values);
			const error = validate(s.configKey, value, s.validations);


			if(error) {
				s.configKey.split('.').reduce((e, s, i, a) => {
					e[s] = i < (a.length - 1) ? (e[s] || {}) : error;
					return e[s]
				}, errors)
			}

		})
	}

}

function getSchemaInitialValues(integration, initialSchemaValues) {

	if(integration) {
		const schemas = getFlatConfigSchemasProperties(integration, ['initialValue']);

		schemas.forEach(s => {
			if(s.initialValue) {
				set(initialSchemaValues, s.configKey, s.initialValue);
			}
		})
	}

	return initialSchemaValues;

}

const findProviderService = findProviderServiceFromIntegration; //Until code refcatored

export {
	parseIntegrationForPersistence,
	findProviderService,
	getServiceIdentifierFromServicePath,
	findIntegrationProviderServiceFromPath,
	loadDataFromProviderServiceFromPath,
	callProviderAuth,
	compareSchemaRequires,
	loadOptionsForIntegrationSchema,
	loadOptionsForIntegrationSchemaSource,
	findProviderAuthValues,
	validateSchemaStageRequires,
	getFromData,
	getAllConfigKeys,
	findDependentFields,
	validateConfigSchemaValues,
	getSchemaInitialValues
}
