import * as React from "react";
import classnames from "classnames";
import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core';

import { Route, Redirect, Switch } from "react-router-dom";

import {
  bindActionCreators,
  //Types
  Dispatch,
  AnyAction
} from "redux";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  fetchProjects,
  getProjectsJS,
  createProject,
  fetchIntegrations,
  fetchIntegrationAuth,
  getIntegrationsJS,
  createProjectIntegration,
  updateProjectIntegration
} from "../../redux";

import SceneContainer from "../SceneContainer";
import SceneHead from "../SceneHead";
import ProjectIntegrationUpsert from "../ProjectIntegrationUpsert";
import ProjectIntegrationReportScene from "../ProjectIntegrationReportScene";

const classes = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  root: {
    textAlign: 'left'
  },
  contentPaper: {
    width: "100%",
    padding: spacing(4)
  }
});

interface ProjectIntegrationSceneProps {
  projects: [any];
  fetchProjects: Function;
  createProject: Function;
  integrations: [any];
  fetchIntegrations: Function;
  fetchIntegrationAuth: Function;
  createProjectIntegration: Function;
  updateProjectIntegration: Function;
  match: {
    path: string;
  },
  history: {
    goBack: Function,
    push: Function
  },
  classes: any
}

class ProjectIntegrationScene extends React.PureComponent<ProjectIntegrationSceneProps> {

  state = {
    initiated: false
  }

  initiate() {
    if(!this.state.initiated) {
      this.props.fetchProjects();
      this.props.fetchIntegrations();
      //this.props.fetchIntegrationAuth();
      this.setState({initiated: true});
    }
  }

  renderIntegrationUpsert(routeProps, integrationId?) {

    //console.log('renderIntegrationUpsert integrationId : ', integrationId);

    const {integrations, fetchIntegrationAuth, projects, history, classes, createProjectIntegration, updateProjectIntegration, createProject} = this.props;

    //console.log('renderIntegrationUpsert integrations : ', integrations);
    //console.log('renderIntegrationUpsert projects : ', projects);

    return (
      <React.Fragment>
        <SceneHead label={integrationId ? "Edit Integration" : "Launch Integration"} onBackNavigation={() => history.push("/")}>
        </SceneHead>
        {<Paper className={classes.contentPaper}>
          <ProjectIntegrationUpsert
            integrationId={integrationId}
            integrations={integrations}
            projects={projects}
            onFetchIntegrationAuth={(id) => fetchIntegrationAuth({id})}
            onUpdateIntegration={async values => {
              console.log('sss onUpdateIntegration values : ', values);
              return await new Promise((resolve, reject) => {
                const meta = { // why meta? https://github.com/redux-utilities/flux-standard-action#meta
                  resolve,
                  reject
                }
                updateProjectIntegration(values, meta)
              })
            }}
            onCreateIntegration={async values => {
              return await new Promise((resolve, reject) => {
                const meta = { // why meta? https://github.com/redux-utilities/flux-standard-action#meta
                  resolve,
                  reject
                }
                createProjectIntegration(values, meta)
              })
            }}
            onCancel={() => history.push("/")}
            onCreateProject={(name:string) => createProject(name)}
            {...routeProps}
          />
        </Paper>}
      </React.Fragment>
    )
  }

  render() {
    return (
      <SceneContainer secure={true} showHead={true} onStartApplicationComplete={() => this.initiate()}>
        <Switch>
          <Route
            exact={true}
            path={`${this.props.match.path}`}
            render={routeProps => {
              return <div><h1>Integration</h1></div>
            }}>
          </Route>
          <Route
            exact={true}
            path={`${this.props.match.path}/new`}
            render={routeProps => this.renderIntegrationUpsert(routeProps)}
          />
          <Route
            exact={true}
            path={`${this.props.match.path}/:integrationId`}
            render={routeProps => {
              return this.renderIntegrationUpsert(routeProps, routeProps.match.params.integrationId)
            }}
          />
          <Route
            exact={false}
            path={`${this.props.match.path}/:integrationId/reports`}
            render={routeProps => <ProjectIntegrationReportScene />}
          />
        </Switch>
      </SceneContainer>
    );
  }
}

function mapStateToProps(state: object) {
  return {
    projects: getProjectsJS(state),
    integrations: getIntegrationsJS(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators(
    {
      fetchProjects,
      fetchIntegrations,
      fetchIntegrationAuth,
      createProjectIntegration,
      updateProjectIntegration,
      createProject
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(classes)(ProjectIntegrationScene))
);
