import React, { useState, useEffect } from "react";
import classnames from "classnames";
import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import RefreshIcon from '@material-ui/icons/Refresh';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core';

import { Route, Redirect, Switch } from "react-router-dom";

//import reportData from './reportTestDa.json';
//import reportData from './yondrReportTestData0105-1.json';
//import reportData from './bbReportTestData.json';

import {
  bindActionCreators,
  //Types
  Dispatch,
  AnyAction
} from "redux";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { request } from "../../remote"

import SceneContainer from "../SceneContainer";
import SceneHead from "../SceneHead";

const classes = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  root: {
    textAlign: 'left'
  },
  contentPaper: {
    width: "100%",
    padding: spacing(4)
  },
  reportBlock: {
    borderWidth: 2,
    borderColor: 'transparent',
    borderTopColor: '#DED',
    marginTop: spacing(3),
    padding: spacing(1)
  },
  reportNotification: {
    borderWidth: 1,
    borderColor: 'transparent',
    borderBottomColor: 'grey',
    padding: spacing(1),
    fontSize: '0.8rem'
  },
  reportDirGroup: {
    borderWidth: 0.25,
    borderColor: 'transparent',
    borderTopColor: '#DED',
    borderTopStyle: 'dashed',
    marginBottom: spacing(1),
  },
  reportDirGroupOverview: {
    marginBottom: spacing(1),
  },

  reportLinksContainer: {
    marginTop: spacing(5)
  },

  reportLink: {
    borderWidth: 0.25,
    //borderStyle: 'solid',
    borderColor: 'transparent',
    borderBottomColor: '#DDD',
    borderBottomStyle: 'solid',
    padding: spacing(1),
    marginBottom: spacing(2),
    cursor: 'pointer',
    width: '100%'
  },

  reportPageActions: {
    width: "100%",
    textAlign: 'right'
  }

});

interface RouteProps {
  match: {
      path: string;
    },
    history: {
      goBack: Function,
      push: Function
    },
    location: {
      pathname: string
    }
}

interface ProjectIntegrationReportSceneProps extends RouteProps {
  integrationId: string,
  classes: any
}

interface ReportListItem {
  id: string,
    providers: { name: string } [],
    status: string,
    startTime: string
}

interface ReportsList {
  items: ReportListItem[]
}

interface ReportListProps {
  integrationId: string,
    routeProps: RouteProps
  classes: any
}


function ReportsList(props: ReportListProps) {

  const { routeProps, integrationId, classes } = props;

  const { location, history } = routeProps

  const [data, setData] = useState < ReportListItem[] > ([]);

  useEffect(() => {

    request(
      `/projects/integrations/${props.integrationId}/reports`,
    ).then(response => {
      setData(response.data && response.data.items || []);
    })

  }, []);

  //console.log('renderIntegrationUpsert integrations : ', integrations);
  //console.log('renderIntegrationUpsert projects : ', projects);

  function navigateToReport(reportId) {
    history.push(`${location.pathname}/${reportId}`)
  }

  return (
    <React.Fragment>
      {<Paper className={classes.contentPaper}>
        <div>
        <Typography style={{marginTop: 10}} variant="h5" gutterBottom>Reports for Integration</Typography>
        <Typography style={{marginTop: 0}} variant="body1" gutterBottom>{integrationId}</Typography>
          <div className={classes.reportLinksContainer}>
            {data.map(item => (
              <div key={item.id} className={classes.reportLink} onClick={() => navigateToReport(item.id)}>
                <Typography style={{marginTop: 0}} variant="subtitle1" gutterBottom>ID: {item.id}</Typography>
                <Typography style={{marginTop: 0}} variant="body1" gutterBottom>Start Time: {new Date(item.startTime).toISOString()}</Typography>
                <Typography style={{marginTop: 0}} variant="body1" gutterBottom>Status: {item.status}</Typography>
              </div>
            ))}
          </div>
        </div>
      </Paper>}
    </React.Fragment>
  )
}

interface ReportContextProps {
  context: {
    integrationId: string,
    reportId: string,
    direction: string,
    action: string,
    status: string
  },
  classes: any
}

function ReportContext(props: ReportContextProps) {

  const { integrationId, reportId, direction, action, status } = props.context;

  const [reportData, setData] = useState<any>({
    status: 'idle',
    items: [],
    page: 1,
  });


  /*function loadPrevReportContextData() {

    const page = reportData.page - 1;

    const prevKey = reportData.cursor;
    const cursor = reportData.page > 1 ? reportData.prevKey : 'start';

    loadReportContextData()
      .then(response => {

        //console.log('response ; ', response.data);
        console.log('response reportData.nextKey ; ', reportData.nextKey );
        console.log('response reportData.cursor ; ', reportData.cursor );
        setData({ status: 'loaded', items: response.data && response.data.items || [], cursor, nextKey: response.data && response.data.nextKey, prevKey: prev });
      })

  }
*/
  function loadNextReportContextData() {

    const page = reportData.page + 1;
    const prevKey = reportData.cursor;
    const cursor = reportData.nextKey;

    setData({ status: 'loading', items: [], cursor});

    loadReportContextData(cursor);
      
  }

  function loadReportContextData(cursor?:string) {

    const prev = (cursor && cursor !== 'start') && (reportData.cursor || "start") || undefined;

    let url = `/projects/integrations/${integrationId}/reports/${reportId}/${direction}/${action}/${status}`;

    if(cursor && cursor !== 'start') {
      url = `${url}?cursor=${cursor}`;
    }

    return request(
      url,
    )
    .then(response => {
        setData({ status: 'loaded', items: response.data && response.data.items || [], cursor, nextKey: response.data && response.data.nextKey });
      })
  }

  useEffect(() => {

    setData({ status: 'loading', items: [], cursor: undefined});

    loadReportContextData();

  }, [props.context]);

  function renderResourceResponseItem(item, i) {
    return (<div key={i} style={{borderBottom: "1pt dashed #eee"}}>
      <p><strong>Code: </strong>{item.code}</p>
      <p><strong>Message: </strong>{item.message}</p>
      {item.relatedErrorsString &&
        <p><strong>RelatedErrors: </strong>{item.relatedErrorsString}</p>
      }
    </div>)
  }

  function renderResourceResponse(response, header, type, plural) {

    return (
      <div style={{borderBottom: "1pt solid #ddd"}}>
        <h3>{header}</h3>
        {response[type] && renderResourceResponseItem(response[type], 0)}
        {response[plural] && response[plural].map(renderResourceResponseItem)}
      </div>
    )
  }


  function renderAllTypeContent(response) {

    //console.log('response : ', response);

    return (<div style={{padding: '0.2rem 1rem'}}>

      {response && (response.error || response.errors) && renderResourceResponse(response, 'Errors', 'error', 'errors')}
      {response && (response.warning || response.warnings) && renderResourceResponse(response, 'Warnings', 'warning', 'warnings')}
      {response && (response.ignore || response.ignores) && renderResourceResponse(response, 'Ignores', 'ignore', 'ignores')}
      {response && (response.success || response.successes) && renderResourceResponse(response, 'Successes', 'success', 'successes')}

     </div>)
  }

  function renderResponse(resp, i) {

    const { response, resourceInfo } = resp;

    return (
      <div key={i} className={props.classes.reportNotification} style={{borderTop: "1pt solid #ccc", marginBottom: '1rem'}}>
        <div style={{fontSize: '1.2rem'}}>
          <p><strong>Resource: </strong>{resourceInfo.resourceIdentifier}</p>
          <p><strong>Type: </strong>{resourceInfo.resourceType}</p>
        </div>
        {renderAllTypeContent(response)}
      </div>
    )
  }


  if(reportData.status === 'loading') {
    return <Paper className={props.classes.contentPaper}>
      <div style={{width: "100%", textAlign:"center"}}>
        <p>Loading...</p>
      </div>
    </Paper>
  }

  function renderNav() {

    return (
      <div style={{width: '100%', textAlign:'center'}}>
        {/*{reportData.prevKey && <Button onClick={() => loadPrevReportContextData()} color="primary">Prev</Button>}*/}
        {reportData.nextKey && <Button onClick={() => loadNextReportContextData()} color="primary">Next</Button>}
      </div>
    )
  }

  return (
    <>
      {renderNav()}
      {reportData.items.map(renderResponse)}
    </>
    
  )
}

interface ReportContentProps {
  integrationId: string
  reportData: any,
  classes: any
}

function directionNameFromContext(context) {
  const names = context.split('-');

  const replaced = names.map(n => n.replace(/(^a-z)|( a-z)/g, function(v) { return v.toUpperCase(); }));

  return `${replaced[1]} > ${replaced[0]}`;

}

function ReportContent(props: ReportContentProps) {

  const [reportData, setData] = useState < any > (props.reportData);

  const [directionEnabled, setDirectionEnabled] = useState < string | undefined > ();
  const [actionEnabled, setActionEnabled] = useState < any > ();
  const [actionStatusEnabled, setActionStatusEnabled] = useState < { name: string, count: number, id: string } > ();


  useEffect(() => {
    setData(props.reportData);
  });



  //console.log('props.reportData : ', props.reportData);
  //console.log('reportData : ', reportData);

  if (reportData) {
    /*    if(!reportData.name) {
          return (
            <Paper className={props.classes.contentPaper}>
              <h2>Invalid Report Data</h2>
            </Paper>
          )
        }*/

    return (
      <Paper className={props.classes.contentPaper}>
        <div>
          
          <div className={props.classes.reportBlock}>
            <h1>{reportData.name}</h1>
            <span><strong>ID: </strong>{props.integrationId}</span>
            <p><span><strong>Date: </strong>{reportData.date}</span><span> </span><span><strong>Duration: </strong>{reportData.duration}</span></p>
            <div>
              <div>
                <p><strong>Status: </strong>{reportData.status}</p>
              </div>
            </div>
          </div>

          {reportData.errors && reportData.errors.list && reportData.errors.list.length &&
            <div className={props.classes.reportBlock}>
              <h3>Errors</h3>
              {reportData.errors.list.map((e, i) => (
                <div key={i} className={props.classes.reportNotification}>
                  <p><strong>Error Code: </strong>{e.code}</p>
                  <p><strong>Error Message: </strong>{e.message}</p>
                </div>
              ))}
            </div>
          }

          <div className={props.classes.reportBlock}>
            {reportData.directions && reportData.directions.length && reportData.directions.map((d, i) => (
              <div key={`dir{i}`}>
                <div>
                  <h2>{ directionNameFromContext(d.context) } ({d.count})</h2>
                </div>

                {d.actions && d.actions.length && 
                  <div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    { d.actions.map((a, i) => (
                      <div key={`dirAction{i}`} onClick={() => setActionEnabled(a)} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flex: "1", height: '60px', border: '0.5pt solid #444', cursor: 'pointer'}}>
                        <span>{a.type} ({a.counts.total})</span>
                      </div>
                    )) }
                  </div>
                  {actionEnabled && (

                    <div className={props.classes.reportDirGroup}>
                      <div className={props.classes.reportDirGroupOverview}>
                        <>
                          {actionEnabled.types && actionEnabled.types.map((at, i) => <p key={`itemType${i}`}>{at.name} {at.counts.success} / {at.counts.total}</p> )}
                        </>
                      </div>
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        {[
                          {name: 'Errors', count: actionEnabled.counts.error, id: 'error'},
                          {name: 'Warnings', count: actionEnabled.counts.warning, id: 'warning'},
                          {name: 'Ignores', count: actionEnabled.counts.ignore, id: 'ignore'},
                          {name: 'Success', count: actionEnabled.counts.success, id: 'success'},
                          ].map((s, i) => (
                            <div key={`actionStatus${i}`} onClick={() => setActionStatusEnabled(s)} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flex: "1", height: '60px', border: '0.5pt solid #444', cursor: 'pointer',
                            background: (actionStatusEnabled && actionStatusEnabled.name === s.name) ? '#eee' : 'none'}}>
                              <span>{s.name} ({s.count})</span>
                            </div>
                        ))}
                      </div>

                      {actionStatusEnabled && actionStatusEnabled.count &&
                        <div className={props.classes.reportBlock} style={{borderTop: "2pt solid grey"}}>
                          <ReportContext context={{
                            integrationId: props.integrationId,
                            reportId: reportData.processKey,
                            direction: d.context,
                            action: actionEnabled.type,
                            status: actionStatusEnabled.id
                          }} classes={props.classes} />
                        </div>
                      }
                    </div>

                  )}

                </div>}
              </div>
            ))}
          </div>
         </div>
      </Paper>
    )
  }
  return (
    <Paper className={props.classes.contentPaper}>
      <div style={{width: "100%", textAlign:"center"}}>
        <p>Loading...</p>
      </div>
    </Paper>
  )
}

interface ReportPageProps {
  integrationId: string,
    reportId: string
  routeProps: RouteProps
  classes: any
}

function ReportPage(props: ReportPageProps) {

  const { routeProps, integrationId, reportId, classes } = props;

  const { location, history } = routeProps

  const [data, setData] = useState < any > ({ report: undefined });

  function loadReportData() {

    //setData({report: reportData});

    request(
      `/projects/integrations/${props.integrationId}/reports/${reportId}`,
    ).then(response => {
      //console.log('response.data : ', response.data)
      setData({ report: response.data && response.data.item || {} });
    })
  }

  useEffect(() => {
    loadReportData()
  }, []);

  return (
    <React.Fragment>
      <SceneHead label='' onBackNavigation={() => routeProps.history.goBack()}>
       </SceneHead>
       <div className={classes.reportPageActions}>
         <Button onClick={() => loadReportData()} color="primary"><RefreshIcon /></Button>
       </div>
      <ReportContent integrationId={integrationId} reportData={data.report} classes={classes}/>
    </React.Fragment>
  )

}


class ProjectIntegrationReportScene extends React.PureComponent < ProjectIntegrationReportSceneProps > {

  state = {
    initiated: false
  }

  initiate() {
    if (!this.state.initiated) {
      //this.props.fetchIntegrationAuth();
      this.setState({ initiated: true });
    }
  }

  renderIntegrationReportsList(routeProps: RouteProps, integrationId: string) {

    console.log('renderIntegrationReportsList routeProps : ', routeProps);

    return (
      <React.Fragment>
        <SceneHead label='' onBackNavigation={() => routeProps.history.goBack()}>
          </SceneHead>
        <ReportsList integrationId={integrationId} routeProps={routeProps} classes={this.props.classes}  />
      </React.Fragment>
    )
  }

  renderIntegrationReport(routeProps, integrationId, reportId) {

    console.log('renderIntegrationReport integrationId, reportId : ', integrationId, reportId);

    const { history, classes } = this.props;

    return (
      <ReportPage integrationId={integrationId} reportId={reportId} routeProps={routeProps} classes={this.props.classes}  />
    )
  }

  render() {
    return (
      <SceneContainer secure={true} showHead={false} onStartApplicationComplete={() => this.initiate()}>
        <Switch>
          <Route
            exact={true}
            path={`${this.props.match.path}`}
            render={routeProps => this.renderIntegrationReportsList(routeProps, routeProps.match.params.integrationId)}
          />
          <Route
            exact={true}
            path={`${this.props.match.path}/:reportId`}
            render={routeProps => {
              return this.renderIntegrationReport(routeProps, routeProps.match.params.integrationId, routeProps.match.params.reportId)
            }}
          />
        </Switch>
      </SceneContainer>
    );
  }
}

function mapStateToProps(state: object, ownProps: object) {
  return ownProps;
}

export default withRouter(
  connect(
    mapStateToProps //,
    //mapDispatchToProps
  )(withStyles(classes)(ProjectIntegrationReportScene))
);