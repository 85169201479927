import * as React from "react";
import { Theme } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ChevronUpIcon from '@material-ui/icons/ExpandLess';
import ChevronDownIcon from '@material-ui/icons/ExpandMore';
import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from "@material-ui/core/Button";
import config from '../../../config';
import { request } from '../../../remote';
import { Field, FieldArray, reduxForm, getFormValues, formValueSelector } from 'redux-form/immutable';
import { renderField, renderSchema } from "../../Form";

import {
  getFromData,
  loadOptionsForIntegrationSchemaSource
} from '../../../utils';

const mapperStyles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  actionContainer: {
    marginTop: spacing(2)
  }
})

const mapperFieldStyles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  rowContainer: {
    marginBottom: spacing(),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  rowNumber: {
    marginRight: spacing()
  },
  rowSelect: {
    display: 'flex',
    flex: '1'
  },
  rowSelectInner: {
    width: '100%'
  },
  fieldOrderActions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fieldOrderAction: {
    padding: spacing() / 2,
    minimumWidth: 0
  },
  mapIconContainer: {
    marginLeft: spacing(),
    marginRight: spacing()
  }
})

interface FieldMapperFieldSelectProps {
  field: object;
  sources: {
    loading: boolean;
    fieldOptions: any[];
    sourceError ? : string;
  } [];
  classes: any;
  orderNumber: number;
  index: number;
  items: any[];
  disabled ? : boolean;
  onItemMove ? : (from: number, to: number) => void;
  onItemDelete: (index: number) => void;
}

class FieldMapperFieldSelectBase extends React.Component < FieldMapperFieldSelectProps, {} > {

  moveItem = (from, to) => {
    const { onItemMove } = this.props;
    //console.log('from, to : ', from, to);
    if(onItemMove) {
      onItemMove(from, to);  
    }
  }

  deleteItem = (index) => {
    const { onItemDelete } = this.props;
    //console.log('from, to : ', from, to);
    onItemDelete(index);
  }

  renderSourceSelect = (source, name, field, classes, disabled) => {
    return (
      <Field
				containerClassName={classes.rowSelect}
				className={classes.rowSelectInner}
				name={`${field}.${name}`}
				type="select"
				component={renderField}
				options={source.fieldOptions}
				maxMenuHeight={150}
				placeholder={
					source.loading
						? "Loading"
						: (source.error ? "Error" : "Select...")
				}
				create={false}
				isLoading={source.loading}
				//validate={(value) => this.validate(value)}
				//disabled={integrationsLoading || integrationOptions.length < 2}
				disabled={disabled || (source.loading || source.error)}
				format = {value => {
					return (value && value !== '' && (value.id && value.name)) ?
					{
						value: value.id,
						label: value.name,
						orig: value
					} : null;
				}}
				//format={value => this.formatIntegration(value, rawIntegrations)}
				parse={value => {
					////console.log('pasre : ', value, value === '');
					//For some reason thais has to return undefined if empty string
					return (value === null || value === undefined || value === '') ? undefined : value.orig;
				}}
			/>
    )
  }

  render() {

    const { field, sources, classes, orderNumber, onItemMove, index, items, disabled } = this.props;

    //console.log('FieldMapperFieldSelectBase render index : ', index);
    //console.log('FieldMapperFieldSelectBase render items.length : ', items.length);

    const orderable = onItemMove !== undefined;

    const anyLoading = sources.some(s => s.loading === true);

    return (
      <div className={classes.rowContainer}>
				<Typography className={classes.rowNumber} variant="body1" gutterBottom>{orderNumber}</Typography>
				{this.renderSourceSelect(sources[0], 'from', field, classes, disabled)}
				<div className={classes.mapIconContainer}><ArrowRightIcon /></div>
				{this.renderSourceSelect(sources[1], 'to', field, classes, disabled)}
				<div className={classes.fieldOrderActions}>
					{items.length > 1 && !anyLoading && <Button onClick={() => this.deleteItem(index)} className={classes.fieldOrderAction} ><DeleteIcon /></Button>}
				</div>
				{orderable && <div className={classes.fieldOrderActions}>
					{index !== 0 && !anyLoading && <Button onClick={() => this.moveItem(index, index-1)} className={classes.fieldOrderAction} ><ChevronUpIcon /></Button>}
					{index < items.length-1 && !anyLoading && <Button onClick={() => this.moveItem(index, index+1)} className={classes.fieldOrderAction}><ChevronDownIcon /></Button>}
				</div>}
			</div>
    )
  }
}

const FieldMapperFieldSelect = withStyles(mapperFieldStyles)(FieldMapperFieldSelectBase);

interface FieldMapperProps {
  classes: any;
  name: string;
  integration: any;
  schema: any;
  formValues: any;
  isEditing: boolean;
  onDataError: Function;
}

interface FieldMapperPropsState {
  sources: {
    loading: boolean;
    fieldOptions: any[];
    sourceError ? : string;
  } []
}

class FieldMapper extends React.Component < FieldMapperProps, FieldMapperPropsState > {

  state: Readonly < FieldMapperPropsState > = {
    sources: [{
        loading: false,
        fieldOptions: [],
        sourceError: undefined
      },
      {
        loading: false,
        fieldOptions: [],
        sourceError: undefined
      }
    ]

  };

  componentDidMount() {
    //console.log('componentDidMount componentDidMount');
    this.loadOptions();
  }

  loadOptions = () => {

    const { integration, schema, formValues, onDataError } = this.props;

    return Promise.all(schema.sources.map((source, i) => {

      const sourcesState = [...this.state.sources];
      sourcesState[i] = {
        loading: true,
        fieldOptions: [],
        sourceError: undefined
      }

      this.setState({ sources: sourcesState });

      //const intId = formValues._id || formValues.tid;
      const intId = formValues.tid;
      return loadOptionsForIntegrationSchemaSource(intId, integration, source, formValues.configuration)

        .then(response => {

          const sourcesState = [...this.state.sources];
          sourcesState[i] = {
            loading: false,
            fieldOptions: response.map(ro => ({value: ro.id, label: ro.name, orig: ro})),
            sourceError: undefined
          }
          this.setState({ sources: sourcesState });
        })
        .catch(e => {

          const sourcesState = [...this.state.sources];
          sourcesState[i] = {
            loading: false,
            fieldOptions: [],
            sourceError: ((typeof e.message === 'string') && e.message) || "Unknown Error"
          }
          this.setState({ sources: sourcesState });
          onDataError && onDataError(e);
        })

    }))
  }

  handleItemMove = fields => (from, to) => {
    //console.log('handleItemMove from, to : ', from, to);
    fields.move(from, to);
  }

  handleItemDelete = fields => (index) => {
    //console.log('handleItemMove from, to : ', from, to);
    fields.remove(index);
  }

  renderFieldSelections = ({ fields, meta: { error, submitFailed } }) => {

    ////console.log('renderFieldSelections fields : ', fields);

    if (fields.length < 1) {
      fields.push({})
      //fields.push('')
    }

    const { formValues, schema, classes, name, isEditing } = this.props;
    const { sources } = this.state;

    const disabled = (isEditing && !schema.enableEdit)

    const fieldFormData = getFromData(name, formValues);

    //fd is each row of two values.
    //{from: [selection], to: [selection]}
    const fieldValues = fieldFormData ? fieldFormData.reduce((agg, fd, i) => {

      //console.log('fieldFormData : ', fieldFormData);

      const pushValue = (field, values) => {
        values[i] = field && field !== '' && field.id !== undefined ? field.id : undefined;
      }

      if (fd) {
        pushValue(fd.from, agg[0])
        pushValue(fd.to, agg[1])
      }

      return agg;

    }, [
      [],
      []
    ]) : [];

    //console.log('fieldValues : ', fieldValues);
    //console.log('fields : ', fields);
    //console.log('sources : ', sources);

    const anyLoading = sources.some(s => s.loading === true);

    const canAdd = !disabled && !anyLoading && fieldFormData;

    return (

      <div>
				{fields.map((field, index) => {

					const mode = (schema.options && ['onetoone', 'manytomany', 'onetomany', 'manytoone'].includes(schema.options.mode)) ?
							schema.options.mode : 'onetoone';

          //console.log('mode : ', mode);

					const filteredFieldSources = sources.map((s, i) => {

						if(mode === 'manytomany' || (mode === 'onetomany' && i === 0) || (mode === 'manytoone' && i === 1)) {

              //console.log('filteredFieldSources nofilter index %n, ', i);

							return s;
						}

            /*console.log('filteredFieldSources filter row index %n, ', index)
            console.log('filteredFieldSources filter i %n, ', i);*/

						const thisValue = fieldValues && fieldValues[i] && fieldValues[i][index];

            /*console.log('fieldValues " ', fieldValues);
            console.log('fieldOptions " ', s.fieldOptions);
            console.log('thisValue " ', thisValue);*/

						return {
							...s,
							fieldOptions: s.fieldOptions.filter(fo => {
								return !fieldValues[i].includes(fo.value) || (thisValue !== undefined && fo.value !== thisValue);
							})
						}

					})

					return <FieldMapperFieldSelect
										orderNumber={index + 1}
										key={index}
										index={index}
										items={fieldFormData}
										field={field}
										sources={filteredFieldSources}
										disabled={disabled}
										onItemMove={schema.options && schema.options.orderable ? (from, to) => this.handleItemMove(fields)(from, to) : undefined}
										onItemDelete={(index) => this.handleItemDelete(fields)(index)}/>

				})}
				{canAdd && <div className={classes.actionContainer}><Button onClick={() => fields.push({})} variant="contained" color="primary" >Add Field</Button></div>}
			</div>
    )
  }

  render() {

    const { sources } = this.state;

    const anyLoading = sources.some(s => s.loading === true);
    const errors = sources.reduce((errors: any[], s) => {
      s.sourceError && errors.push(s.sourceError);
      return errors
    }, []);

    const { name } = this.props;

    return (
      <div>
				<FieldArray name={name} component={this.renderFieldSelections}/>
				<div>
					{errors && errors.map((e, i) => (<Typography key={i} style={{color:'#f00', marginTop: 10}} variant="body1" gutterBottom>{e}</Typography>))}
				</div>
				
			</div>
    )


  }
}

export default withStyles(mapperStyles)(FieldMapper);