import React from 'react';
import classnames from "classnames";
import Select from '../Select'
import Typography from '@material-ui/core/Typography';

const FormSelect = ({
  input,
  label,
  type,
  className,
  containerClassName,
  defaultValue,
  meta: { touched, error, warning },
  inputClassName,
  errorClassName,
  placeholder,
  helpText,
  disabled,
  isClearable,
  isLoading,
  options,
  create,
  onCreateOption,
  maxMenuHeight
}) => {

  //console.log('Select input : ', input);

  const inputProps = {
    ...input
  }

  delete inputProps.onBlur

	return (

    <div className={containerClassName}>

      <Select
        {...input}
        className={classnames(inputClassName, {
          error: !!error
        }, className)}
        isDisabled={disabled}
        create={create}
        options={options}
        isLoading={isLoading}
        placeholder={placeholder}
        label={label}
        onCreateOption={onCreateOption}
        isClearable={isClearable === undefined ? false : isClearable}
        //value={selected}
        maxMenuHeight={maxMenuHeight || 500}
        />

      { touched && error && typeof error === 'string' &&
        <label className={errorClassName} htmlFor={input.name}><Typography style={{color:'#f00', marginTop: 10}} variant="body1" gutterBottom>{error}</Typography></label>
      }

      { helpText &&
        <span className="help-block">{helpText}</span>
      }
    </div>
  )
};

export default FormSelect;