import React from 'react';
import classnames from "classnames";
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const FormCheckbox = ({
  input,
  label,
  type,
  className,
  containerClassName,
  defaultValue,
  meta: { touched, error, warning },
  inputClassName,
  errorClassName,
  helpText,
  disabled,
}) => {

  //console.log('input : ', input);

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
          	{...input}
            color="primary"
            disabled={disabled}
          />
        }
        label={label}
      />
    </FormGroup>
  );
}

export default FormCheckbox;