import { combineEpics, ofType } from 'redux-observable';
import { from as from$, of as of$ } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import {
  INTEGRATIONS_FETCH_REQUEST,
  INTEGRATION_AUTH_FETCH_REQUEST,
  //INTEGRATION_CREATE_REQUEST,
  INTEGRATION_PROVIDER_SERVICE_REQUEST
} from './const';

import {
  fetchIntegrationsSuccess,
  fetchIntegrationsFailed,
  fetchIntegrationAuthSuccess,
  fetchIntegrationAuthFail,
  //createIntegrationSuccess,
  //createIntegrationFailed
} from './actions';

import {
  normaliseAPIErrorAction
} from '../utils';

//import comnponentIntegrationsData from './data/aconex-procore-componet-test.json';
//import tokenIntegrationsData from './data/aconex-procore-token-test.json';
//import aconexzutec from './data/Aconex-Zutec_id.json';
//import aconexprocore from './data/aconex-procore-probuild_base.json';
//
//import aconexplangrid from '../../mockdata/aconex-plangrid-new_partial.json'
//import aconexprocore from '../../mockdata/aconex-procore-partial.json'
//import aconexprocore from '../../mockdata/aconex-procore-full.json'

const

  fetchIntegrations = (request) => {

    //return Promise.resolve([aconexprocore]);
    //return Promise.resolve([comnponentIntegrationsData]);

    return request.get('/integrations/base')
      .then(response => response.data && response.data.items)
  },

  /*fetchIntegrationsAuth = (request) => {
    return request.get(`/integrations/provider/auths`)
      .then(response => response.data)
  },*/

  fetchIntegrationsAuthForIntegration = (request, integrationTid) => {
    return request.get(`/auth/token-maps/${integrationTid}/tokens`)
      .then(response => response.data && response.data.items)
  },

  callIntegrationProviderService = (request, payload) => {
    return request.post('/integrations/provider/service', payload)
      .then(response => {
        //console.log('callIntegrationProviderService response : ', response )
        return response
      })
  },

  fetchIntegrationsEpic = (action$, state$, { request }) => {
    return action$.pipe(
      ofType(INTEGRATIONS_FETCH_REQUEST),
      switchMap(action => {
        return from$(fetchIntegrations(request))
          .pipe(
            map(response => {
              return fetchIntegrationsSuccess({integrations: response})
            }),
            catchError(error => {
              return of$(...normaliseAPIErrorAction(error, [action], [fetchIntegrationsFailed({error})]));
              //return of$(fetchIntegrationsFailed({error}));
            })
          )
        /*return from$(login(action.loginRequest, fetch(state$.value))).pipe(
          map(loginResponse => loginComplete(normaliseAccount(loginResponse)))
        );*/
      })
    );
  },

  fetchIntegrationAuthEpic = (action$, state$, { request }) => {
    return action$.pipe(
      ofType(INTEGRATION_AUTH_FETCH_REQUEST),
      switchMap(action => {
         //console.log('fetchIntegrationAuthEpic action.payload ', action.payload);
         //return from$(fetchIntegrationsAuth(request, action.payload))
         return from$(fetchIntegrationsAuthForIntegration(request, action.payload.id))
          .pipe(
            map(response => fetchIntegrationAuthSuccess({integrationsAuths: response})),
            catchError(error => {
              //console.log('fetchIntegrationAuthEpic error : ', error)
              return of$(...normaliseAPIErrorAction(error, [action], [fetchIntegrationAuthFail({error})]));
              //return of$(fetchIntegrationAuthSuccess({error}));
            })
          )
      })
    )
  },

  executeIntegrationProviderServiceEpic = (action$, state$, { request }) => {
    return action$.pipe(
      ofType(INTEGRATION_PROVIDER_SERVICE_REQUEST),
      switchMap(action => {
        return from$(callIntegrationProviderService(request, action.payload))
          .pipe(
            map(response => ({type: "INTEGRATION_PROVIDER_SERVICE_SUCESS"})),
            catchError(error => {
              //console.log('executeIntegrationProviderService: ', error)
              return of$({type: "INTEGRATION_PROVIDER_SERVICE_SUCESS", payload: {error}});
            })
          )
      })
    )
  }


  

export default combineEpics(fetchIntegrationsEpic, fetchIntegrationAuthEpic, executeIntegrationProviderServiceEpic);