import * as React from "react";
import { Theme } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import Button from "@material-ui/core/Button";
import { Field, FieldArray, reduxForm, getFormValues, formValueSelector } from 'redux-form/immutable';
import { renderField, renderSchema } from "../../Form";
import config from '../../../config';
import {request} from '../../../remote';

import {
	getFromData,
	findProviderService,
	getServiceIdentifierFromServicePath,
	findIntegrationProviderServiceFromPath,
	loadDataFromProviderServiceFromPath,
	findProviderAuthValues,
	callProviderAuth
} from '../../../utils';

const styles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
	statusError: {
    color: '#f00'
  },
  statusOk: {
    color: '#0f0'
  },
	formStageMessage: {
  	marginBottom: spacing(2)
  }
})

//These need proper interfaces
interface BasicAuthProps {
	classes: any;
	schema: any;
	integration: any;
	initialValues: any;
	integrationId: string;
	formValues: any;
	onFetchIntegrationAuth: any;
	isEditing?: boolean;
}

interface BasicAuthState {
	submitting: boolean;
}

class BasicAuth extends React.Component<BasicAuthProps, BasicAuthState> {

	state: Readonly<BasicAuthState> = {
		submitting: false
  };

	submitAuthRequest(integration, providerName, service, schema, formValues, onFetchIntegrationAuth) {
		callProviderAuth(integration, providerName, service, schema, formValues)
			.then(response => {
					console.log('submitAuthRequest callProviderAuth response : ', response)
					return onFetchIntegrationAuth();
				}
			)
			.catch(error => {
				console.log('submitAuthRequest callProviderAuth response : ', error)
				return onFetchIntegrationAuth();
			})
  	//console.log('submitOAuthRequest');
  	this.setState({submitting:true})
  }

	renderForm(integration, providerName, service, classes, schema, formValues, onFetchIntegrationAuth, disabled) {

		return (
			<div>
				<Typography className={classes.formStageMessage} variant="body1" gutterBottom>Enter your API Key</Typography>	
				<Field
					//name={`configuration.${schema.configKey}`}
					name={`configuration.${schema.configKey}.apiKey`}
					type='text'
					component={renderField}
					placeholder=""
					disabled={disabled}
				/>
				<Typography className={classes.formStageMessage} variant="body1" gutterBottom>Enter your Email</Typography>	
				<Field
					//name={`configuration.${schema.configKey}`}
					name={`configuration.${schema.configKey}.email`}
					type='email'
					component={renderField}
					placeholder=""
					disabled={disabled}
				/>
				<Typography className={classes.formStageMessage} variant="body1" gutterBottom>Enter your Password</Typography>	
				<Field
					//name={`configuration.${schema.configKey}`}
					name={`configuration.${schema.configKey}.password`}
					type='password'
					component={renderField}
					placeholder=""
					disabled={disabled}
				/>
				<Button onClick={() => this.submitAuthRequest(integration, providerName, service, schema, formValues, onFetchIntegrationAuth)} variant="contained" color="primary" className={classes.button}>
	      	Authorize
	    	</Button>
			</div>
		)
	}

	renderStatus(integration, providerName, service, classes, schema, serviceInitialValues, formValues, onFetchIntegrationAuth, disabled) {

		if(!service) {
			return (
				<p>Sorry this service is not available.</p>
			)	
		}

		if(serviceInitialValues && serviceInitialValues.status && serviceInitialValues.status === 'authorized') {
			return (
				<div className={classes.statusAuthorisedBlock}>
				<CheckCircleOutlinedIcon style={{ fontSize: 30 }} className={classes.statusDoneIcon} />
				<Typography variant="body1" gutterBottom>This service is has been succesfully authorised.</Typography>
				</div>
			)
		}			
		
		return this.renderForm(integration, providerName, service, classes, schema, formValues, onFetchIntegrationAuth, disabled);
	}

	renderError(error) {

		const {classes} = this.props;

		return (
			<Typography className={classes.statusError} variant="body1" gutterBottom>{error.message}</Typography>
		)
	}

	renderStateError(serviceInitialValues) {

		const {classes} = this.props;

		if(!serviceInitialValues) {
			return
		}

		//console.log('renderError serviceInitialValues.status : ', serviceInitialValues.status);
		//console.log('renderError serviceInitialValues.error : ', serviceInitialValues.error);

		if(serviceInitialValues.status && serviceInitialValues.status === 'error') {
			return (
				serviceInitialValues.error ?
				this.renderError({message: `Error : ${serviceInitialValues.error.error}`}):
				this.renderError({message: `Sorry an unknown error occured`})
			)
		}
		return null;
	}

	render() {

		const {classes, schema, integration, initialValues, integrationId, formValues, onFetchIntegrationAuth, isEditing} = this.props;
		const disabled = (isEditing && !schema.enableEdit)

		if(!schema.service) {
			return this.renderError({message: 'Auth service is not defined in schema.'})
		}

		const { providerName, serviceName } = getServiceIdentifierFromServicePath(schema.service);

		const serviceInitialValues = findProviderAuthValues(initialValues, providerName, serviceName);

		const service = findIntegrationProviderServiceFromPath(schema.service, integration);

		if(this.state.submitting) {
			return (
				<div>
					<CircularProgress size={30} className={classes.progressSmall} />
				</div>
			)
		}

		if(serviceInitialValues && (serviceInitialValues.status && serviceInitialValues.status === 'loading')) {
			return (
				<div>
					<CircularProgress size={30} className={classes.progressSmall} />
				</div>
			)
		}

		return (
			<div >
				<Typography variant="subtitle2" gutterBottom>{schema.label}</Typography>	
				{this.renderStatus(integration, providerName, service, classes, schema, serviceInitialValues, formValues, onFetchIntegrationAuth, disabled)}
				{this.renderStateError(serviceInitialValues)}
			</div>
		)	

		
	}
}


export default withStyles(styles)(BasicAuth);
