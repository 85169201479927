import * as React from "react";
import classnames from "classnames";

import {
  bindActionCreators,
  //Types
  Dispatch,
  AnyAction
} from "redux";

import { connect, Provider } from "react-redux";
import { withRouter } from "react-router";
import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import SceneContainer from "../SceneContainer";
import SceneHead from "../SceneHead";
import ProjectList from "../ProjectList";

import { fetchProjects, getProjectsJS } from "../../redux";

const styles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  icon: {
    fontSize: 24
  },
  button: {
    margin: spacing(1),
  },
  container: {
    width: "100%",
    flex: 1,
    height: `calc(100vh - 200px)`,
    overflowY: 'scroll'
  },
  projectList: {
    //height: '100%'
  }
})


interface HomeSceneProps {
  projects: [any];
  fetchProjects: Function;
  history: {
    push: Function
  };
  classes: any
}

class HomeScene extends React.PureComponent<HomeSceneProps> {
  render() {

    const {
      classes,
      history,
      fetchProjects,
      projects
    } = this.props;

    //console.log('HomeScene projects : ', this.props.projects);
    return (
      <SceneContainer secure={true} showHead={true}>
        <SceneHead label="Dashboard" >
          <Button
            onClick={() => history.push("/integration/new")}
            color="primary"
            className={classes.button}
          >
            <AddIcon />
            New Integration
          </Button>
        </SceneHead>
        <div className={classes.container}>
          <ProjectList
            className={classes.projectList}
            history={history}
            projects={projects}
            fetchProjects={fetchProjects}
          />
        </div>
      </SceneContainer>
    );
  }
}

function mapStateToProps(state: object) {
  return {
    projects: getProjectsJS(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators(
    {
      fetchProjects
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(HomeScene))
);
