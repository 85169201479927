import { combineEpics, ofType } from 'redux-observable'
import { from as from$, of as of$, defer } from 'rxjs'
import { map, switchMap, flatMap, catchError } from 'rxjs/operators'

import Cookies from 'js-cookie'

import { Auth } from 'aws-amplify'

import {
  USER_COOKIE_RETRIEVE,
  USER_COOKIE_SUCCESS,
  USER_LOGIN_REQUEST,
  USER_TOKEN_REFRESH_REQUEST,
  USER_LOGOUT_REQUEST,
  // USER_LOGIN_SUCCESS,
  // USER_SIGNUP_SUCCESS,
  USER_AUTH_UPDATE
} from './const'

import {
  logoutUser,
  userCookieSuccess,
  userLoginSuccess,
  userLoginFailed,
  userRefreshTokenSuccess,
  userLogoutSuccess,
  userLogoutFailed,
  userAuthUpdate
} from './actions'

const

  /* signup = async (registration) => {
      try {
        const { user } = await Auth.signUp({
          email: auth.email,
          password: auth.password,
          attributes: {
            email, // optional
            phone_number, // optional - E.164 number convention
            // other custom attributes
          }
        });
        console.log(user);
      } catch (error) {

        console.log('error signing up:', error);
        throw new Error(`Login Failed : ${error.message}`);
      }
    }, */

  login = async (auth) => {
    try {
      const amplifyResponse = await Auth.signIn(auth.email, auth.password)

      return amplifyResponse
    } catch (error) {
      console.log('login error signing up:', error)
      throw new Error(`Login Failed : ${error.message}`)
    }
  }

const logout = () => {
  return Promise.resolve()
}

const refreshToken = (() => {
  let promise
  let actions = []
  let timeStamp

  return (request, payload) => {
    if (payload.successes) {
      actions = [...actions.filter(a => !payload.successes.map(sa => sa.type).includes(a)), ...payload.successes]
    }

    if (!promise) {
      if (timeStamp && timeStamp < (Date.now() + 60000)) {
        return Promise.reject({ message: 'Refresh too quick' })
      }

      timeStamp = Date.now()

      promise = request.post(`/token/refresh?refreshToken=${payload.refreshToken}`)
        .then(tokens => {
          // console.log('tokens : ', tokens);
          // console.log('actions : ', actions);
          promise = undefined
          return [...actions.splice(0), userAuthUpdate(userRefreshTokenSuccess({ auth: tokens.data }))]
        })
        .catch(error => {
          promise = undefined
          Promise.reject(error)
        })
      return promise
    }

    return promise
  }
})()

const loginRequest = (action$, state$, { request }) => {
  return action$.pipe(
    ofType(USER_LOGIN_REQUEST),
    switchMap(action => {
      return from$(login(action.payload, request))
        .pipe(
          map(amplifyResponse => {
            // console.log('amplifyResponse : ', amplifyResponse);

            if (amplifyResponse &&
                  (amplifyResponse.attributes && amplifyResponse.signInUserSession)) {
              const { attributes, signInUserSession } = amplifyResponse

              const { sub } = attributes

              console.log('sub : ', sub)

              const { idToken, refreshToken } = signInUserSession

              // The userAuthUpdate expects a simple object.
              // Whatever is sent here must match what is stored in the cookie
              return userAuthUpdate(userLoginSuccess({
                user: attributes,
                auth: {
                  token: idToken.jwtToken,
                  refreshToken
                }
              }))
            } else {
              // TODO notify of challenges of SRP
              // Why not notifying UI
              return userLoginFailed({
                error: 'User Login Failed.'
              })
            }
          }),
          catchError(error => {
            return of$(userLoginFailed({ error: error.response ? error.response.data : error }))
          })
        )
    })

  )
}

const refreshTokenRequest = (action$, state$, { request }) => {
  return action$.pipe(
    ofType(USER_TOKEN_REFRESH_REQUEST),
    switchMap(action => {
      const userState = state$.value.toJS().user
      if (userState.user && userState.user.auth && userState.user.auth.refreshToken) {
        return from$(refreshToken(request, { ...action.payload, refreshToken: state$.value.toJS().user.user.auth.refreshToken }))
          .pipe(
            flatMap(successActions => {
              return successActions ? [...successActions] : [{ type: 'null' }]
            }),
            catchError(error => {
              return of$(logoutUser())
              // return of$({type:'null'})
            })
          )
      } else {
        return of$(logoutUser())
      }
    })

  )
}

const cookieRequest = (action$, state$, { request }) => {
  return action$.pipe(
    ofType(USER_COOKIE_RETRIEVE),
    // action.payload.delegate here is the action to emit once cookie has been checked
    // At this time this will only ever be the APPLICATION STARTED.
    flatMap(action => {
      const cookie = Cookies.get('bl_session')

      return (cookie && cookie !== 'undefined') ? [userAuthUpdate(userCookieSuccess(JSON.parse(cookie))), action.payload.delegate] : [action.payload.delegate]
    })
  )
}

const authTokenApply = (action$, state$, { request }) => {
  return action$.pipe(
    ofType(USER_AUTH_UPDATE),
    map(action => {
      const
        actionDelegate = action.payload
      const auth = actionDelegate.payload && actionDelegate.payload.auth
      const authToken = auth && auth.token

      if (actionDelegate && actionDelegate.type === USER_LOGOUT_REQUEST) {
        Cookies.remove('bl_session')
        return action.payload // Action delegate
      }

      Object.assign(request.defaults.headers, { common: { ...request.defaults.headers.common, Authorization: authToken } })

      // This epic is also called when
      if (actionDelegate.type !== USER_COOKIE_SUCCESS) {
        const cookieValue = JSON.stringify(actionDelegate.payload)
        Cookies.set('bl_session', cookieValue, { expires: 7 })
      }
      // console.log('authTokenApply request.defaults.headers : ', request.defaults.headers.common);
      return actionDelegate
    }),
    flatMap(action => [action, { type: 'REQUEST_TOKEN_UPDATED' }])
  )
}

const completeLogout = (action$, state$, { request }) => {
  return action$.pipe(
    ofType(USER_LOGOUT_REQUEST),
    switchMap(action => {
      return defer(() => logout())
        .pipe(
          map(() => {
            return userAuthUpdate(userLogoutSuccess())
          })
        )
    }),
    catchError(error => {
      return of$(userLogoutFailed({ error }))
    })
  )
}

export default combineEpics(cookieRequest, loginRequest, refreshTokenRequest, completeLogout, authTokenApply)
