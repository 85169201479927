import * as React from "react";
import { Theme } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import Button from "@material-ui/core/Button";
import config from '../../../config';
import { request } from '../../../remote';
import { Field } from 'redux-form/immutable';

import {
  findProviderService,
  getServiceIdentifierFromServicePath,
  findIntegrationProviderServiceFromPath,
  loadDataFromProviderServiceFromPath,
  findProviderAuthValues
} from '../../../utils';

const styles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  statusError: {
    color: '#f00'
  },
  statusOk: {
    color: '#0f0'
  },
  statusAuthorisedBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  statusDoneIcon: {
    color: '#0f0',
    marginTop: spacing(),
    marginBottom: spacing(),
    marginRight: spacing(),
    display: 'inline'
  },
  progressSmall: {
    marginTop: spacing(),
    marginBottom: spacing()
  }
})

//These need proper interfaces
interface OauthProps {
  classes: any;
  schema: any;
  integration: any;
  initialValues: any;
  integrationId: string;
  formValues: any;
  disabled ? : boolean;
}

interface OauthState {
  submitting: boolean;
}

class Oauth extends React.Component < OauthProps, OauthState > {

  state: Readonly < OauthState > = {
    submitting: false
  };

  submitOAuthRequest(url, token) {
    this.setState({ submitting: true })
  }

  renderStatus(integrationId, integration, providerName, serviceName, service, serviceInitialValues, formValues) {

    const { classes } = this.props;

    if (!service) {
      return (
        <p>Sorry this service is not available.</p>
      )
    }

    //console.log('oauth serviceInitialValues : ', serviceInitialValues);

    if (serviceInitialValues && serviceInitialValues.status && serviceInitialValues.status === 'authorized') {
      return (
        <div className={classes.statusAuthorisedBlock}>
        <CheckCircleOutlinedIcon style={{ fontSize: 30 }} className={classes.statusDoneIcon} />
        <Typography variant="body1" gutterBottom>This service is has been succesfully authorised with OAuth.</Typography>
        </div>
      )
    }

    const token = request.defaults.headers.common.Authorization;

    const appCallbackURL = integrationId ?
      `${window.location.href}`.replace(/\?.*/, '') + `?tid=${formValues.tid}` :
      `${window.location.href}`.replace(/\?.*/, '') + `?tid=${formValues.tid}&project=${formValues.projectId}&integration=${formValues.integrationId}`;

    const outhConfig = service.config;

    //const tokenLiveIntegrationId = formValues._id || formValues.tid;
    const tokenLiveIntegrationId = formValues.tid;

    //console.log('renderStatus formValues : ', formValues);
    //console.log('renderStatus tokenLiveIntegrationId : ', tokenLiveIntegrationId);

    //const url = `${config.API_URL}/integrations/provider/service?` +
    const url = `${config.API_URL}/providers/${providerName}/oauth?` +
      `ut=${token}` +
      `&tokenMapId=${tokenLiveIntegrationId}` +
      `&successURL=${encodeURIComponent(appCallbackURL)}` +
      `&failureURL=${encodeURIComponent(appCallbackURL)}` +
      `&serviceConfig=${encodeURIComponent(outhConfig)}`

    //console.log('renderStartus url : ', url);

    return (
      <Button href={url} onClick={() => this.submitOAuthRequest(url, token)} variant="contained" color="primary" className={classes.button}>
        Authorize
      </Button>
    )
  }

  renderError(serviceInitialValues) {

    const { classes } = this.props;

    if (!serviceInitialValues) {
      return
    }

    console.log('renderError serviceInitialValues.status : ', serviceInitialValues.error);
    //console.log('renderError serviceInitialValues.error : ', serviceInitialValues.error);

    if (serviceInitialValues.status && serviceInitialValues.status === 'error') {
      return (
        serviceInitialValues.error ?
        <Typography className={classes.statusError} variant="body1" gutterBottom>{`Error : ${serviceInitialValues.error.message}`}</Typography> :
        <Typography className={classes.statusError} variant="body1" gutterBottom>Sorry an unknown error occured</Typography>
      )
    }
    return null;
  }

  renderField(field) {

    const { classes, schema, integration, initialValues, integrationId, formValues } = this.props;

    const { providerName, serviceName } = getServiceIdentifierFromServicePath(schema.service);

    const serviceInitialValues = findProviderAuthValues(initialValues, providerName, serviceName);

    const service = findIntegrationProviderServiceFromPath(schema.service, integration);

    if (this.state.submitting) {
      return (
        <div>
          <CircularProgress size={30} className={classes.progressSmall} />
        </div>
      )
    }

    if (serviceInitialValues && (serviceInitialValues.status && serviceInitialValues.status === 'loading')) {
      return (
        <div>
          <CircularProgress size={30} className={classes.progressSmall} />
        </div>
      )
    }

    return (
      <div >
        <Typography variant="subtitle2" gutterBottom>{schema.label}</Typography>  
        {this.renderStatus(integrationId, integration, providerName, serviceName, service, serviceInitialValues, formValues)}
        {this.renderError(serviceInitialValues)}
      </div>
    )
  }

  render() {
    const { schema } = this.props;
    return <Field name={`configuration.${schema.configKey}`} component={(field) => this.renderField(field)}/>
  }

  // inside your render() method
}

export default withStyles(styles)(Oauth);
