import {
  fromJS
}
from 'immutable'

import {
  APPLICATION_PREPARING,
  APPLICATION_PREPARING_ERROR,
  APPLICATION_PREPARED,
  APPLICATION_STARTING,
  APPLICATION_STARTING_ERROR,
  APPLICATION_STARTED
} from './const';

const

  initialApplicationState = fromJS({
    applicationPreparing: false,
    applicationPrepared: false,
    applicationPreparationError: null,
    applicationStarting: false,
    applicationStarted: false,
    applicationStartingError: null
  }),

  applicationReducer = (state = initialApplicationState, action) => {
    switch (action.type) {
      case APPLICATION_PREPARING:
        return state.merge({
          applicationPreparing: true,
          applicationPrepared: false
        })
      case APPLICATION_PREPARING_ERROR:
        return state.merge({
          applicationPreparing: false,
          applicationPrepared: false,
          applicationPreparationError: action.applicationPreparationError
        })
      case APPLICATION_PREPARED:
        return state.merge({
          applicationPreparing: false,
          applicationPrepared: true
        })
      case APPLICATION_STARTING:
        return state.merge({
          applicationStarting: false,
          applicationStarted: true
        })
      case APPLICATION_STARTING_ERROR:
        return state.merge({
          applicationStarting: false,
          applicationStarted: false,
          applicationPreparingError: action.applicationStartingError
        })
      case APPLICATION_STARTED:
        return state.merge({
          applicationStarting: false,
          applicationStarted: true
        })
      default:
        return state
    }
  };

export default applicationReducer;