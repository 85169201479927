import { combineEpics, ofType } from 'redux-observable';
import { from as from$, of as of$, timer } from 'rxjs';
import { map, switchMap, concatMap, flatMap, catchError, takeWhile } from 'rxjs/operators';

import {
  PROJECTS_FETCH_REQUEST,
  PROJECT_CREATE_REQUEST,
  //INTEGRATION_FETCH_REQUEST,
  PROJECT_INTEGRATION_CREATE_REQUEST,
  PROJECT_INTEGRATION_UPDATE_REQUEST,
  PROJECT_INTEGRATION_POLL_REQUEST,
  PROJECT_INTEGRATION_ACTION_REQUEST,
  PROJECT_INTEGRATION_STATUS_REQUEST,
  PROJECT_INTEGRATION_DELETE_REQUEST
} from './const';

import {
  fetchProjectsSuccess,
  fetchProjectsFailed,

  createProjectSuccess,
  //createProjectFailed,

  createProjectIntegrationSuccess,
  createProjectIntegrationFailed,

  deleteProjectIntegrationSuccess,
  deleteProjectIntegrationFailed,

  applyProjectIntregrationActionSuccess,
  applyProjectIntregrationActionFailed,

  updateProjectIntegrationSuccess,
  updateProjectIntegrationFailed,

  pollProjectIntegration,

  //pollProjectIntegrationSuccess,
  pollProjectIntegrationFailed
} from './actions';
 
import {
  normaliseAPIErrorAction
} from '../utils';

/*import mockProjectsData from './mockProjectsData';*/

/*const testProjectData = [{
  _id: "KyQfcMxrzvIatzH5woFp",
  name: "GLUI",
  integrations: [] //Need this for existing test
}]*/

const 

  fetchProjects = (request) => {

    /*return new Promise((resolve, reject) => {
      setTimeout(() => resolve(mockProjectsData), 2000);
    })*/

    //return Promise.resolve(testProjectData);

    return request.get('/projects?compose=true')
      .then(response => response.data.items)
  },

  createProject = (request, payload) => {

    /*return new Promise((resolve, reject) => {
      setTimeout(() => resolve({
        ...payload,
        _id: 'dw8dy783hdb'
      }), 2000);
    })*/
    return request.post('/projects', payload)
      .then(response => response.data.item)
  },

  /*fetchProjectIntegration = (request, payload, count) => {
    
    return request.get(`/projects/${payload.projectId}/integrations/${payload.integrationId}`)
      .then(response => response.data.item);
  },*/

  fetchProjectIntegrationStatus = (request, payload, count) => {

    //console.log('fetchProjectIntegration payload: ', payload.integrationId);
    //console.log('fetchProjectIntegration count: ', count);

    /*return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          _id: payload.integrationId,
          status: count > 2 ? 'started' : 'starting',
          project: payload.projectId
        })
      }, 2000);

    })*/
    
    return request.get(`/projects/${payload.projectId}/integrations/${payload.integrationId}/status`)
      .then(response => response.data.item);
  },

  createProjectIntegration = (request, payload) => {

    return request.post(`/projects/${payload.projectId}/integrations?action=start`, payload)
      .then(response => response.data.item)
  },

  updateProjectIntegration = (request, payload) => {

    console.log('epic updateProjectIntegration');

    return request.put(`/projects/${payload.projectId}/integrations/${payload._id}?action=update`, payload)
      .then(response => response.data.item)
  },


  deleteProjectIntegration = (request, payload) => {

    //console.log('deleteProjectIntegration payload : ', payload);

    return request.delete(`/projects/${payload.projectId}/integrations/${payload.integrationId}`, payload)
      .then(response => response.data.item)
  },

  applyProjectIntegrationAction = (request, payload) => {
    return request.put(`/projects/${payload.projectId}/integrations/${payload.integrationId}?action=${payload.action}`)
      .then(response => response.data.item)
  },


  /*
  Here we use a closure to contain a cache of polling observables

  See
  https://makeitnew.io/polling-using-rxjs-8347d05e9104
  */
  
  pollProjectIntegrationByContext = (() => {
    //const polls = []
    return (context, request) => {

      //Implement singleton for polls for any one integration

      //console.log('pollProjectIntegrationByContext context : ', context);

      /*const
        poll = polls.find(p => p.projectId === context.projectId && p.integrationId === context.integrationId);

      console.log('pollProjectIntegrationByContext context : ', context);*/

      /*if(poll) {

        return poll.obs

      } else {*/

        const obs = timer(0, 20000)

          .pipe(
            //tap(count => console.log('timerr : ', count)),
            //Use concat map not swicth map to ensure all responses recieved
            concatMap(count => {
              return from$(fetchProjectIntegrationStatus(request, {
                projectId: context.projectId,
                integrationId: context.integrationId
              }, count))
            })
          )

         //console.log('pollProjectIntegrationByContext obs : ', obs)
         //
         /*polls.push({
           ...context,
           obs
         })*/

         return obs
      //}
    }

  })(),

  fetchProjectsEpic = (action$, state$, { request }) => {
    return action$.pipe(
      ofType(PROJECTS_FETCH_REQUEST),
      switchMap(action => {
        //return from$(request.action(action, fetchProjects))
        return from$(fetchProjects(request))
          .pipe(
            map((response => fetchProjectsSuccess({projects: response}))),
            catchError(error => {
              console.log('fetchProjectsEpic error: ', error.response);
              return of$(...normaliseAPIErrorAction(error, [action], [fetchProjectsFailed({error})]));
              //console.log('error: ', error)
              //return of$(fetchProjectsFailed({error}));
            })
          )
      }),
    );
  },

  createProjectEpic = (action$, state$, { request }) => {
    return action$.pipe(
      ofType(PROJECT_CREATE_REQUEST),
      switchMap(action => {
        return from$(createProject(request, action.payload))
          .pipe(
            map((response => createProjectSuccess({project: response}))),
            catchError(error => {
              console.log('action$ : ')
              console.log('error: ', error)
              return of$(...normaliseAPIErrorAction(error, [action]));
            })
          )
      })
    );
  },

  createProjectIntegrationEpic = (action$, state$, { request }) => {
    return action$.pipe(
      ofType(PROJECT_INTEGRATION_CREATE_REQUEST),
      switchMap(action => {

        return from$(createProjectIntegration(request, {
            ...action.payload
          }))
          .pipe(
            map(response => ({...response, tid: action.payload.tid})),
            flatMap(response => {

              let { resolve } = action.meta || {};

              if (resolve) resolve(response);

              //console.log('createProjectIntegrationEpic repsonse : ', response);

              return [

                //Two actions,
                //1 Copnfirm the integratipon was created
                //2 Start po9ll of integration

                createProjectIntegrationSuccess({projectId: response.projectId, integration: response}),
                pollProjectIntegration({
                  context: {
                    projectId: response.projectId,
                    integrationId: response._id,
                  },
                  queries: [{
                    status: 'starting'
                  }]
                })
               ]
              }
            ),
            catchError(error => {
              let { reject } = action.meta || {};
              console.log('error: ', error)
              if (reject) reject(error);
              return of$(...normaliseAPIErrorAction(error, [action], [createProjectIntegrationFailed({error})]));
            })
          )
      })
    );
  },


  updateProjectIntegrationEpic = (action$, state$, { request }) => {
    return action$.pipe(
      ofType(PROJECT_INTEGRATION_UPDATE_REQUEST),
      switchMap(action => {

        console.log('epic updateProjectIntegrationEpic action : ', action);

        return from$(updateProjectIntegration(request, {
            ...action.payload,
          }))
          .pipe(
            map(response => ({...response, tid: action.payload.tid})),
            flatMap(response => {

              let { resolve } = action.meta || {};

              if (resolve) resolve(response);

              //console.log('createProjectIntegrationEpic repsonse : ', response);

              return [

                //Two actions,
                //1 Copnfirm the integratipon was created
                //2 Start po9ll of integration

                updateProjectIntegrationSuccess({projectId: response.projectId, integration: response}),
                pollProjectIntegration({
                  context: {
                    projectId: response.projectId,
                    integrationId: response._id,
                  },
                  queries: [{
                    status: 'starting'
                  }, {
                    status: 'stopping'
                  }, {
                    status: 'stopped'
                  }]
                })
               ]
              }
            ),
            catchError(error => {
              let { reject } = action.meta || {};
              console.log('error: ', error)
              if (reject) reject(error);
              return of$(...normaliseAPIErrorAction(error, [action], [updateProjectIntegrationFailed({error})]));
            })
          )
      })
    );
  },

  deleteProjectIntegrationEpic = (action$, state$, { request }) => {
    return action$.pipe(
      ofType(PROJECT_INTEGRATION_DELETE_REQUEST),
      switchMap(action => {

        const { integrationId, projectId } = action.payload;
        const ids = { integrationId, projectId }

        //console.log('deleteProjectIntegrationEpic action.payload : ', action.payload);        

        return from$(deleteProjectIntegration(request, {
            ...action.payload,
            tid: undefined
          }))
          .pipe(
            map(response => ({...response, ...ids})),
            flatMap(response => {

              let { resolve } = action.meta || {};

              if (resolve) resolve(response);

              //console.log('deleteProjectIntegrationEpic repsonse : ', response);

              return [

                //Two actions,
                //1 Copnfirm the integratipon was created
                //2 Start po9ll of integration

                deleteProjectIntegrationSuccess(response),
                /*pollProjectIntegration({
                  context: {
                    projectId: response.projectId,
                    integrationId: response._id,
                  },
                  queries: [{
                    status: 'starting'
                  }]
                })*/

               ]
              }
            ),
            catchError(error => {
              let { reject } = action.meta || {};
              //console.log('error: ', serializeError(error))
              if (reject) reject(error);
              return of$(...normaliseAPIErrorAction(error, [action], [deleteProjectIntegrationFailed({error: error && error.response && error.response.data, ...ids})]));
            })
          )
      })
    );
  },

  //https://www.learnrxjs.io/recipes/http-polling.html
  //https://makeitnew.io/polling-using-rxjs-8347d05e9104
  pollProjectIntegrationStatusEpic = (action$, state$, { request }) => {
    return action$.pipe(
      ofType(PROJECT_INTEGRATION_POLL_REQUEST),
      switchMap(action => {
        const queries = action.payload.queries;
        console.log('Starting poll of project integration with context : ', action.payload.context);
        return pollProjectIntegrationByContext(action.payload.context, request)
          .pipe(
            //tap(response => console.log('response : ', response)),
            map(response => {
              console.log('pollProjectIntegrationStatusEpic response : ', response);
              return updateProjectIntegrationSuccess({projectId:response.projectId, integration: response});
            })
          )
          //Only take from observer while the integratipon is starting
          //This is hardcoded here but the action payload will have a queries object so each 
          //poliing can define is own queries. See pollProjectIntegration execution in #createProjectIntegrationEpic above.
          /*.pipe(
            filter(({payload}) => payload.status !== 'starting')
          )*/
          .pipe(takeWhile(({payload}) => {
            return queries ? queries.map(q => q.status).includes(payload.integration.status) : false;
          }, true))
      }),
      map(action => {
        return action;
      }),
      catchError(error => {
        return of$(pollProjectIntegrationFailed({error}));
      })
    )
  },

  applyProjectIntegrationActionEpic = (action$, state$, { request }) => {
    return action$.pipe(
      ofType(PROJECT_INTEGRATION_ACTION_REQUEST),
      switchMap(action => {
        return from$(applyProjectIntegrationAction(request, action.payload))
          .pipe(
            flatMap(response => {
              return [
                applyProjectIntregrationActionSuccess({projectId: action.payload.projectId, integration: response}),
                pollProjectIntegration({
                  context: {
                    projectId: response.projectId,
                    integrationId: response._id,
                  },
                  queries: [{
                    status: (() => {
                      switch(action.payload.action) {
                        case 'start':
                          return 'starting'
                        case 'stop':
                        case 'cancel':
                          return 'stopping'
                        default:
                          return 'stopping'
                      }
                    })()
                  }]
                })
              ]
            }),
            catchError(error => {
               return of$(...normaliseAPIErrorAction(error, [action], [applyProjectIntregrationActionFailed({error})]));
               //return of$(applyProjectIntregrationActionFailed({error}));
            })
          )
      }),
    )
   },

   fetchProjectIntegrationStatusEpic =(action$, state$, { request }) => {
    return action$.pipe(
      ofType(PROJECT_INTEGRATION_STATUS_REQUEST),
      switchMap(action => {
        return from$(fetchProjectIntegrationStatus(request, action.payload))
          .pipe(
            flatMap(response => {
              console.log('fetchProjectIntegrationStatusEpic response ', response);
              return [
                updateProjectIntegrationSuccess({projectId: action.payload.projectId, integration: response}),
                /*pollProjectIntegration({
                  context: {
                    projectId: action.payload.projectId,
                    integrationId: response._id,
                  },
                  queries: [{
                    status: (() => {
                      switch(action.payload.action) {
                        case 'start':
                          return 'starting'
                        case 'stop':
                        case 'cancel':
                          return 'stopping'
                      }
                    })()
                  }]
                })*/
              ]
            }),
            catchError(error => {
              console.log('fetchProjectIntegrationStatusEpic error : ', error);
                return of$({type: "NULL_TYPE"})
               //return of$(...normaliseAPIErrorAction(error, [action], [applyProjectIntregrationActionFailed({error})]));
               //return of$(applyProjectIntregrationActionFailed({error}));
            })
          )
      }),
    )
   }

export default combineEpics(fetchProjectsEpic, createProjectEpic, deleteProjectIntegrationEpic, updateProjectIntegrationEpic, createProjectIntegrationEpic, pollProjectIntegrationStatusEpic, applyProjectIntegrationActionEpic, fetchProjectIntegrationStatusEpic);