import {
  
  INTEGRATIONS_FETCH_REQUEST,
  INTEGRATIONS_FETCH_SUCCESS,
  INTEGRATIONS_FETCH_FAILED,

  INTEGRATION_CREATE_REQUEST,
  INTEGRATION_CREATE_SUCCESS,
  INTEGRATION_CREATE_FAILED,

  INTEGRATION_FETCH_REQUEST,
  INTEGRATION_FETCH_SUCCESS,
  INTEGRATION_FETCH_FAILED,

  INTEGRATION_AUTH_FETCH_REQUEST,
  INTEGRATION_AUTH_FETCH_SUCCESS,
  INTEGRATION_AUTH_FETCH_FAILED,

  INTEGRATION_PROVIDER_SERVICE_REQUEST,

} from './const';

export const

  fetchIntegrations = (payload) => ({type: INTEGRATIONS_FETCH_REQUEST, payload}),

  fetchIntegrationsSuccess = (payload) => ({type: INTEGRATIONS_FETCH_SUCCESS, payload}),

  fetchIntegrationsFailed = (payload) => ({type: INTEGRATIONS_FETCH_FAILED, payload}),

  createIntegration = (payload) => ({type: INTEGRATION_CREATE_REQUEST, payload}),

  createIntegrationSuccess = (payload) => ({type: INTEGRATION_CREATE_SUCCESS, payload}),

  createIntegrationFailed = (payload) => ({type: INTEGRATION_CREATE_FAILED, payload}),

  fetchIntegration = (payload) => ({type: INTEGRATION_FETCH_REQUEST, payload}),

  fetchIntegrationSuccess = (payload) => ({type: INTEGRATION_FETCH_SUCCESS, payload}),

  fetchIntegrationFailed = (payload) => ({type: INTEGRATION_FETCH_FAILED, payload}),

  fetchIntegrationAuth = (payload) => ({type: INTEGRATION_AUTH_FETCH_REQUEST, payload}),
  fetchIntegrationAuthSuccess = (payload) => ({type: INTEGRATION_AUTH_FETCH_SUCCESS, payload}),
  fetchIntegrationAuthFail = (payload) => ({type: INTEGRATION_AUTH_FETCH_FAILED, payload}),

  executeIntegrationProviderService = (payload) => ({type: INTEGRATION_PROVIDER_SERVICE_REQUEST, payload})