
import {
  refreshToken,
  logoutUser
} from '../user';

function normaliseAPIErrorAction(error, successes, faults) {

	const continueFaults = (faults && faults.length > 0) ? faults : [{type: 'null'}]

	/*console.log('normaliseAPIErrorAction error : ', (() => {
		for(var i in error) {
			console.log(error[i])
		}
	})());*/

	if(error && error.message === 'Network Error') {
		return [logoutUser()]
	}

	if(error && error.response && error.response.data) {
		if(/^TOKEN_EXPIRED|INVALID_TOKEN$/.test(error.response.data.code)) {
			return [refreshToken({successes, faults})]
		}
		return continueFaults
	}
	return continueFaults
}

export {
	normaliseAPIErrorAction
}