import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import './style/index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './components/App';
import { configureStore } from './redux';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

Amplify.configure(awsconfig);
const store = configureStore();
const createTheme = (theme: any) => createMuiTheme(theme)
const theme = createTheme({
  overrides: {
    MUIDataTable: {
      paper: {
        height: '100%',
      },
      responsiveScroll: {
        height: 'calc(100% - 40px)',
        maxHeight: '100%'
      }
    }
  }
});
const renderApp = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <HashRouter>
           <Component />
        </HashRouter>
      </ThemeProvider>,
    </Provider>,
    document.getElementById('app')
  );
};

renderApp(App);

if ((module as any).hot) {
  (module as any).hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default
    renderApp(NextApp);
  });
}
