export const
	USER_COOKIE_RETRIEVE = 'USER/COOKIE_RETRIEVE',
	USER_COOKIE_SUCCESS = 'USER/COOKIE_SUCCESS',
  USER_LOGIN_REQUEST = 'USER/LOGIN_REQUEST',
	USER_LOGIN_SUCCESS = 'USER/LOGIN_SUCESSS',
	USER_LOGIN_FAILED = 'USER/LOGIN_FAILED',
	USER_TOKEN_REFRESH_REQUEST = 'USER/TOKEN_REFRESH_REQUEST',
	USER_TOKEN_REFRESH_SUCCESS = 'USER/TOKEN_REFRESH_SUCCESS',
	USER_TOKEN_REFRESH_FAIL = 'USER/TOKEN_REFRESH_FAIL',
	USER_SIGNUP_REQUEST = 'USER/SIGNUP_REQUEST',
	USER_SIGNUP_SUCCESS = 'USER/SIGNUP_SUCCESS',
	USER_SIGNUP_FAILED = 'USER/SIGNUP_FAILED',
	USER_LOGOUT_REQUEST = 'USER/LOGOUT_REQUEST',
	USER_LOGOUT_SUCCESS = 'USER/LOGOUT_SUCCESS',
	USER_LOGOUT_FAILED = 'USER/LOGOUT_FAILED',
	USER_UPDATE_REQUEST = 'USER/UPDATE_REQUEST',
	USER_UPDATE_SUCCESS = 'USER/UPDATE_SUCCESS',
	USER_UPDATE_FAILED = 'USER/UPDATE_FAILED',
	USER_AUTH_UPDATE = 'USER/AUTH_UPDATE';
