import * as React from "react";
import { Theme } from '@material-ui/core';
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import { Field } from 'redux-form/immutable';
import { renderField, renderSchema } from "../../Form";


interface OptionsSelectProps {
	schema: any;
	//formValues: any;
	name: string;
	isEditing?: boolean;
	//disabled: boolean;
}

class OptionsSelect extends React.Component<OptionsSelectProps> {
	render() {
		const {name, schema, isEditing} = this.props;

		const disabled = (isEditing && !schema.enableEdit)

		return (

				<div>
					<Field
						//name={`configuration.${schema.configKey}`}
						name={name}
						type="select"
						component={renderField}
						options={schema.options || []}
						maxMenuHeight={150}
						placeholder={"Select..."}
						create={false}
						isLoading={false}
						disabled={disabled}
						format = {value => {
							//console.log('format value : ', value);
							if(value === '') {
								return undefined
							}
							//return schema.options.find(o => o.value === value);
							//return value;
						}}
						//format={value => this.formatIntegration(value, rawIntegrations)}
						parse={value => {
							//.console.log('parse value : ', value);
							//console.log('Data select parse value : ', value);
							return value ? value : undefined
						}}
					/>
			</div>
		)
	}
}

export default OptionsSelect;