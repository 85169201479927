export const

  PROJECTS_FETCH_REQUEST = 'PROJECTS/FETCH_REQUEST',
  PROJECTS_FETCH_SUCCESS = 'PROJECTS/FETCH_SUCCESS',
  PROJECTS_FETCH_FAILED = 'PROJECTS/FETCH_FAILED',

  PROJECT_CREATE_REQUEST = 'PROJECT/CREATE_REQUEST',
  PROJECT_CREATE_SUCCESS = 'PROJECT/CREATE_SUCCESS',
  PROJECT_CREATE_FAILED = 'PROJECT/CREATE_FAILED',

  PROJECT_INTEGRATION_DELETE_REQUEST = 'PROJECT/INTEGRATION_DELETE_REQUEST',
  PROJECT_INTEGRATION_DELETE_SUCCESS = 'PROJECT/INTEGRATION_DELETE_SUCCESS',
  PROJECT_INTEGRATION_DELETE_FAILED = 'PROJECT/INTEGRATION_DELETE_FAILED',

  PROJECT_INTEGRATION_CREATE_REQUEST = 'PROJECT/INTEGRATION_CREATE_REQUEST',
  PROJECT_INTEGRATION_CREATE_SUCCESS = 'PROJECT/INTEGRATION_CREATE_SUCCESS',
  PROJECT_INTEGRATION_CREATE_FAILED = 'PROJECT/INTEGRATION_CREATE_FAILED',

  PROJECT_INTEGRATION_UPDATE_REQUEST = 'PROJECT/INTEGRATION_UPDATE_REQUEST',
  PROJECT_INTEGRATION_UPDATE_SUCCESS = 'PROJECT/INTEGRATION_UPDATE_SUCCESS',
  PROJECT_INTEGRATION_UPDATE_FAILED = 'PROJECT/INTEGRATION_UPDATE_FAILED',

  PROJECT_INTEGRATION_POLL_REQUEST = 'PROJECT/INTEGRATION_POLL_REQUEST',
  PROJECT_INTEGRATION_POLL_SUCCESS = 'PROJECT/INTEGRATION_POLL_SUCCESS',
  PROJECT_INTEGRATION_POLL_FAILED = 'PROJECT/INTEGRATION_POLL_FAILED',

  PROJECT_INTEGRATION_ACTION_REQUEST = 'PROJECT/INTEGRATION_ACTION_REQUEST',
  PROJECT_INTEGRATION_ACTION_SUCCESS = 'PROJECT/INTEGRATION_ACTION_SUCCESS',
  PROJECT_INTEGRATION_ACTION_FAILED = 'PROJECT/INTEGRATION_ACTION_FAILED',

  PROJECT_INTEGRATION_STATUS_REQUEST = 'PROJECT/INTEGRATION_STATUS_REQUEST'
