import React, { useState } from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';


const styles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  textField: {
    //padding: 10,
    display: 'flex',
    width: '100%',
    marginLeft: spacing(1),
    marginRight: spacing(2),
  },
  scheduleFormContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    maxWidth: 400
  },
  scheduleFormBlock: {
    flex: 1,
    paddingRight: spacing(1)
  }
});

const ScheduleInput = ({
  input,
  label,
  type,
  inputProps,
  className,
  defaultValue,
  meta: { touched, error, warning, active },
  inputClassName,
  placeholder,
  helpText,
  disabled,
  classes
}) => {


  const minimum = 15 * 60000; // 15 minutes;

  function onMinutesChange(event) {
    const hours = getHours(input.value);
    const value = event.currentTarget.value;
    const minutes = value === '' ? value : parseInt(value);
    //console.log('onMinutesChange hours, minutes : ', hours, minutes);
    input.onChange([hours == '' ? 0 : hours, minutes]);
  }

  function onHoursChange(event) {
    const value = event.currentTarget.value;
    const hours = value === '' ? value : parseInt(value);
    const minutes = getMinutes(input.value);
    //console.log('onHoursChange hours, minutes : ', hours, minutes);
    input.onChange([hours, minutes === '' ? 15 : minutes]);
  }

  function onMinutesBlur(event) {
    const hours = getHours(input.value);
    const mins = event.currentTarget.value;

    if(mins === '') {

      input.onBlur([hours === '' ? 0 : hours, 0]);

    } else {

      const pMins = parseInt(mins);
      const aMins = hours > 0 ? pMins : (pMins < 15 ? 15 : pMins);
      input.onBlur([hours, aMins]);

    }
  }

  function onHoursBlur(event) {
    const hours = event.currentTarget.value;
    const mins = getMinutes(input.value);

    if(hours === '') {
      input.onBlur([0, 15]);
    } else {
      const pHours = parseInt(hours);
      const aMins = pHours > 0 ? mins : (mins < 15 ? 15 : mins);
      input.onBlur([pHours, aMins]);
    }
  }

  function onFocus(event) {
    input.onFocus(event);
  }

  function getHours(value) {
    const nValue = normaliseValue(value);
    //console.log('getHours value : ', value);
    //console.log('getHours active : ', active);
    //console.log('getHours nValue : ', nValue);
    if(nValue[1] === '' && !active) {
      return 0;
    }
    return nValue[0];
  }

  function getMinutes(value) { 
    const nValue = normaliseValue(value);
    //console.log('getMinutes value : ', value);
    //console.log('getMinutes active : ', active);
    //console.log('getMinutes nValue : ', nValue);
    if(nValue[1] === '' && !active) {
      return 15;
    }
    return nValue[1];
  }

  function normaliseValue(value) {
    return !value || value === '' ? ['', ''] :
      (typeof value.toArray === 'function') ? value.toArray() : value;
  }

  function getMinMinutes(value) {

    const hours = getHours(value);
    return (isNaN(hours) || hours < 1) ? 15 : 0;

  }

  return (
    <div>
      <div className={classes.scheduleFormContainer}>
        <div className={classes.scheduleFormBlock}>
          <Typography variant="body1" gutterBottom>Hours</Typography>
          <TextField
            fullWidth
            name='hours'
            type='number'
            inputProps = {inputProps}
            disabled={disabled}
            onFocus={onFocus}
            onChange={onHoursChange}
            onBlur={onHoursBlur}
            value={getHours(input.value)}
            margin="normal"
            variant="outlined"
          />
        </div>
        <div className={classes.scheduleFormBlock}>
          <Typography variant="body1" gutterBottom>Minutes</Typography>
          <TextField
            fullWidth
            name='minutes'
            type='number'
            inputProps = {{...inputProps, min: getMinMinutes(input.value)}}
            disabled={disabled}
            onFocus={onFocus}
            onChange={onMinutesChange}
            onBlur={onMinutesBlur}
            value={getMinutes(input.value)}
            margin="normal"
            variant="outlined"
          />
        </div>
      </div>

      { touched && error && typeof error === 'string' &&
        /*<label className="error" htmlFor={input.name}>{error}</label>*/
        <Typography style={{color:'#f00', marginTop: 10}} variant="body1" gutterBottom>{error}</Typography>
      }
    </div>
  )
};    

export default withStyles(styles)(ScheduleInput);