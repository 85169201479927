
import {
  APPLICATION_PREPARE,
  APPLICATION_START
} from './const';

export const

  prepareApplication = () => ({ type: APPLICATION_PREPARE }),

  startApplication = () => ({ type: APPLICATION_START }),

  generalApplicationError = () => ({}) ///Do full reset after prompt