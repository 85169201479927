import {
  fromJS
}
from 'immutable'

import {
  PROJECTS_FETCH_REQUEST,
  PROJECTS_FETCH_SUCCESS,
  PROJECTS_FETCH_FAILED,

  PROJECT_CREATE_REQUEST,
  PROJECT_CREATE_SUCCESS,
  PROJECT_CREATE_FAILED,

  PROJECT_INTEGRATION_CREATE_REQUEST,
  PROJECT_INTEGRATION_CREATE_SUCCESS,
  PROJECT_INTEGRATION_CREATE_FAILED,

  PROJECT_INTEGRATION_DELETE_REQUEST,
  PROJECT_INTEGRATION_DELETE_SUCCESS,
  PROJECT_INTEGRATION_DELETE_FAILED,

  PROJECT_INTEGRATION_UPDATE_SUCCESS,

  PROJECT_INTEGRATION_ACTION_REQUEST,
  PROJECT_INTEGRATION_ACTION_SUCCESS,
  PROJECT_INTEGRATION_ACTION_FAILED,

  PROJECT_INTEGRATION_STATUS_REQUEST
} from './const';


const  

  mapAsyncItem = (at, working = false, error= undefined) => ({
    ...at,
    ast: () => ({
      working,
      error
    })
  }),

  mapAsyncList = (al, working = false, error = undefined) => {

    const data = fromJS(al.map(ao => mapAsyncItem(ao)))

    data.ast = () => ({
      working,
      error
    })

    return data
  },

  mapAsyncProjects = (aps, working = false, error = undefined) => mapAsyncList(aps, working, error),

  initialState = fromJS({
    projects: mapAsyncProjects([], false, undefined),
    projectsUpdated: Date.now()
  }),
  

  projectIntegrationModifier = (state, action) => {

    const
      integrationIndex = state.findIndex(i => {
        //Here we need to make sure the tid one side is not undefined othetrwise it will match with both
        //being undefined
        return (i.get('_id') === (action.payload.integrationId || action.payload.integration._id)) || 
          (i.get('tid') !== undefined && i.get('tid') === (action.payload.integrationId || action.payload.integration._id))
      });


    switch (action.type) {

      case PROJECT_INTEGRATION_CREATE_REQUEST:
        return state.push(mapAsyncItem(fromJS(action.payload.integration), true));
      case PROJECT_INTEGRATION_DELETE_REQUEST:
        return state.updateIn([integrationIndex], fromJS({}), val => val.merge(mapAsyncItem(val, true, null)));
      case PROJECT_INTEGRATION_CREATE_SUCCESS:
      case PROJECT_INTEGRATION_UPDATE_SUCCESS:
      case PROJECT_INTEGRATION_ACTION_SUCCESS:
        return state.updateIn([integrationIndex], fromJS({}), val => val.merge(mapAsyncItem(action.payload.integration, false, null)));
      case PROJECT_INTEGRATION_DELETE_SUCCESS:
        return state.delete(integrationIndex);
      case PROJECT_INTEGRATION_ACTION_REQUEST:
      case PROJECT_INTEGRATION_STATUS_REQUEST:
        return state.updateIn([integrationIndex], fromJS({}), val => val.merge(mapAsyncItem(val, true, null)));
      case PROJECT_INTEGRATION_CREATE_FAILED:
      case PROJECT_INTEGRATION_DELETE_FAILED:
      case PROJECT_INTEGRATION_ACTION_FAILED:
        return state.updateIn([integrationIndex], fromJS({}), val => val.merge(mapAsyncItem(val, false, action.payload.error)));
      default:
        return state;

    }
  },

  projectModifier = (state, action) => {

    const
      projectIndex = state.findIndex(p => {
        //console.log('finds pid : ', p.get('_id'));
        return p.get('_id') === action.payload.projectId
      });

    if(!state || state.size < 1 || projectIndex < 0) {
      return state;
    }

    switch (action.type) {
      case PROJECT_INTEGRATION_CREATE_FAILED:
      case PROJECT_INTEGRATION_CREATE_REQUEST:
      case PROJECT_INTEGRATION_CREATE_SUCCESS:
      case PROJECT_INTEGRATION_DELETE_REQUEST:
      case PROJECT_INTEGRATION_DELETE_SUCCESS:
      case PROJECT_INTEGRATION_DELETE_FAILED:
      case PROJECT_INTEGRATION_UPDATE_SUCCESS:
      case PROJECT_INTEGRATION_ACTION_REQUEST:
      case PROJECT_INTEGRATION_STATUS_REQUEST:
      case PROJECT_INTEGRATION_ACTION_SUCCESS:
      case PROJECT_INTEGRATION_ACTION_FAILED:
        return state.updateIn([projectIndex, 'integrations'], fromJS([]), val => projectIntegrationModifier(val, action))
      default:
        return state;

    }
  }
  

export default (state = initialState, action) => {

  switch (action.type) {
    case PROJECTS_FETCH_REQUEST:
      return state.merge({
        projects: mapAsyncProjects([], true, undefined)
      })
    case PROJECTS_FETCH_SUCCESS:
      return state.merge({
        projectsUpdated: Date.now(),
        projects: mapAsyncProjects(action.payload.projects, false, undefined)
      })
    case PROJECTS_FETCH_FAILED:
      return state.merge({
        projects: mapAsyncProjects([], false, action.payload.error)
      })

    case PROJECT_CREATE_REQUEST:
      return state.merge({
        projects: mapAsyncProjects(state.get('projects').toJS(), true, undefined)
      })
    case PROJECT_CREATE_SUCCESS:
      return state.merge({
        projects: mapAsyncProjects([...state.get('projects').toJS(), action.payload.project], false, undefined)
      })
    case PROJECT_CREATE_FAILED:
      return state

    //case PROJECT_INTEGRATION_CREATE_REQUEST:
    case PROJECT_INTEGRATION_CREATE_SUCCESS:
    case PROJECT_INTEGRATION_CREATE_FAILED:
    case PROJECT_INTEGRATION_DELETE_REQUEST:
    case PROJECT_INTEGRATION_DELETE_SUCCESS:
    case PROJECT_INTEGRATION_DELETE_FAILED:
    case PROJECT_INTEGRATION_UPDATE_SUCCESS:
    case PROJECT_INTEGRATION_ACTION_REQUEST:
    case PROJECT_INTEGRATION_STATUS_REQUEST:
    case PROJECT_INTEGRATION_ACTION_SUCCESS:
    case PROJECT_INTEGRATION_ACTION_FAILED:
      return state.merge({
        projects: projectModifier(state.get('projects'), action)
      })
    default:
      return state;
  }

}

