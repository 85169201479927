import React, {PureComponent} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft'


const styles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
  icon: {
    fontSize: 24
  },
  button: {
    margin: spacing(),
  }
})

interface SceneHeadProps {
	onBackNavigation?: Function,
  label: string
}

class SceneHead extends React.PureComponent<SceneHeadProps> {

	render() {

		//const { classes } = this.props;

		return (
      <AppBar position="static" color="default">
        <Toolbar>
        	{this.props.onBackNavigation && typeof this.props.onBackNavigation === 'function' &&
        		<Button onClick={() => this.props.onBackNavigation && this.props.onBackNavigation()} color="primary">
        			<BackIcon />
        		</Button>
        	}
          <Typography variant="h6" color="inherit">
            {this.props.label}
          </Typography>
          <div>
          	{this.props.children}
          </div>
        </Toolbar>
      </AppBar>
	  )
	}
}

export default SceneHead;