import {
  fromJS
}
from 'immutable'

import {

  INTEGRATIONS_FETCH_REQUEST,
  INTEGRATIONS_FETCH_SUCCESS,
  INTEGRATIONS_FETCH_FAILED,

  //INTEGRATION_CREATE_REQUEST,
  //INTEGRATION_CREATE_SUCCESS,
  //INTEGRATION_CREATE_FAILED,

  //INTEGRATION_FETCH_REQUEST,
  //INTEGRATION_FETCH_SUCCESS,
  //INTEGRATION_FETCH_FAILED,

  INTEGRATION_AUTH_FETCH_REQUEST,
  INTEGRATION_AUTH_FETCH_SUCCESS,
  INTEGRATION_AUTH_FETCH_FAILED

} from './const';


const

  mapAsyncItem = (at, working = false, error = undefined) => ({
    ...at,
    ast: () => ({
      working,
      error
    })
  }),

  mapAsyncList = (al, working = false, error = undefined) => {

    const data = fromJS(al.map(ao => mapAsyncItem(ao)))

    data.ast = () => ({
      working,
      error
    })

    return data
  },

  mapAsyncIntegrations = (ais, working = false, error = undefined) => ais ? mapAsyncList(ais, working, error) : mapAsyncList([], working, error),

  initialState = fromJS({
    integrations: mapAsyncIntegrations([], true, undefined),
    integrationAuths: mapAsyncIntegrations([], true, undefined)
  })

export default (state = initialState, action) => {

  switch (action.type) {
    case INTEGRATIONS_FETCH_REQUEST:
      return state.merge({
        integrations: mapAsyncIntegrations([], true, undefined)
      })
    case INTEGRATIONS_FETCH_SUCCESS:
      return state.merge({
        integrations: mapAsyncIntegrations(action.payload.integrations, false, undefined)
      })
    case INTEGRATIONS_FETCH_FAILED:
      return state.merge({
        integrations: mapAsyncIntegrations([], false, action.payload.error)
      })
    case INTEGRATION_AUTH_FETCH_REQUEST:
      return state.merge({
        integrationAuths: mapAsyncIntegrations([], true, undefined)
      })
    case INTEGRATION_AUTH_FETCH_SUCCESS:
      //console.log('action.payload.integrationsAuths : ', action.payload.integrationsAuths);
      const newState = state.merge({
        integrationAuths: mapAsyncIntegrations(action.payload.integrationsAuths, false, undefined)
      })
      //console.log('state : ', newState.toJS());
      return newState

    case INTEGRATION_AUTH_FETCH_FAILED:
      return state.merge({
        integrationAuths: mapAsyncIntegrations([], false, action.payload.error)
      })

      //case INTEGRATION_FETCH_REQUEST:
      //case INTEGRATION_FETCH_SUCCESS:
      //case INTEGRATION_FETCH_FAILED:
    default:
      return state;
  }

}